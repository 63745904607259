import React, { useState } from 'react';
import {
  Box, Button, Container, FormControl, FormLabel, Input, Textarea, Stack, Text, Flex, Heading, Image,
} from '@chakra-ui/react';
import { MdPhone, MdEmail, MdLocationOn } from 'react-icons/md'; // Import icons
import logo from '../assets/images/logo.png';

const styles = {
  container: {
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'hsl(218, 41%, 15%)',
    backgroundImage: `
      radial-gradient(650px circle at 0% 0%, hsl(218, 41%, 35%) 15%, hsl(218, 41%, 30%) 35%, hsl(218, 41%, 20%) 75%, hsl(218, 41%, 19%) 80%, transparent 100%),
      radial-gradient(1250px circle at 100% 100%, hsl(218, 41%, 45%) 15%, hsl(218, 41%, 30%) 35%, hsl(218, 41%, 20%) 75%, hsl(218, 41%, 19%) 80%, transparent 100%)`,
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    position: 'relative',
    overflow: 'hidden',
  },
  radiusShape1: {
    position: 'absolute',
    height: '220px',
    width: '220px',
    top: '10%',
    left: '48%',
    background: 'radial-gradient(#44006b, #ad1fff)',
    overflow: 'hidden',
    borderRadius: '50%',
    transform: 'translateY(-40%)',
  },
  radiusShape2: {
    position: 'absolute',
    borderRadius: '38% 62% 63% 37% / 70% 33% 67% 30%',
    bottom: '5%',
    right: '5%',
    width: '300px',
    height: '300px',
    background: 'radial-gradient(#44006b, #ad1fff)',
    overflow: 'hidden',
  },
  heading: {
    color: 'hsl(218, 81%, 95%)',
  },
  subheading: {
    color: 'hsl(218, 81%, 75%)',
  },
  paragraph: {
    color: 'hsl(218, 81%, 85%)',
  },
  contactItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    color: 'hsl(218, 81%, 85%)',
  },
};

function Contact() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // Simulate form submission
      await new Promise((resolve) => setTimeout(resolve, 1000));

      setLoading(false);
      alert('Message sent successfully!');
      // Reset the form
      setName('');
      setEmail('');
      setMessage('');
    } catch (error) {
      setLoading(false);
      alert(`Failed to send message: ${error.message || error}`);
    }
  };

  return (
    <Container maxW="100%" p={0} m={0} sx={styles.container}>
      <Box sx={styles.radiusShape1} />
      <Box sx={styles.radiusShape2} />

      <Flex
        direction={{ base: 'column', md: 'row' }}
        align="center"
        justify="center"
        maxW="container.xl"
        p={8}
        gap={8}
        position="relative" // Ensure Flex is above the background shapes
      >
        <Box flex="1" color="white" textAlign="left" px={4}>
          <Text as="h1" fontSize="6xl" fontWeight="bold" color="hsl(218, 81%, 95%)">
            Get in Touch
          </Text>
          <Text mt={4} fontSize="lg" color="hsl(218, 81%, 85%)">
            We would love to hear from you. Fill out the form below to send us a message.
          </Text>

          {/* Contact Information Section */}
          <Box mt={8} color="white">
            <Heading as="h2" fontSize="2xl" mb={4}>Contact Information</Heading>
            <Stack spacing={4}>
              <Box sx={styles.contactItem}>
                <MdPhone size={24} />
                <Text fontSize="lg">+917708233529</Text>
              </Box>
              <Box sx={styles.contactItem}>
                <MdEmail size={24} />
                <Text fontSize="lg">info@pentity.com</Text>
              </Box>
              <Box sx={styles.contactItem}>
                <MdLocationOn size={24} />
                <Text fontSize="lg">GOWRIVAKKAM,CHENNAI</Text>
              </Box>
            </Stack>
          </Box>
        </Box>

        <Box
          flex="1"
          bg="rgba(255, 255, 255, 0.8)" // Transparent white background
          p={8}
          borderRadius="lg" // Rounded corners
          boxShadow="xl" // Large shadow for emphasis
          border="1px solid rgba(0, 0, 0, 0.5)" // Dark border color
          backdropFilter="saturate(150%) blur(15px)" // Apply a backdrop filter
          maxW="md"
        >
          <Image src={logo} alt="Logo" boxSize='50px' mx="auto" mb="6" />
          <Heading as="h1" mb="6" textAlign="center">Contact Us</Heading>
          <form onSubmit={handleSubmit}>
            <Stack spacing={4}>
              <FormControl id="name" isRequired>
                <FormLabel color="black">Name</FormLabel>
                <Input
                  type="text"
                  size="lg"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Your Name"
                  bg="transparent"
                  borderColor="rgba(0, 0, 0, 0.3)"
                  _placeholder={{ color: 'black' }}
                  _focus={{ borderColor: 'hsl(218, 41%, 25%)', boxShadow: '0 0 0 1px hsl(218, 41%, 25%)' }}
                  color="black"
                />
              </FormControl>
              <FormControl id="email" isRequired>
                <FormLabel color="black">Email address</FormLabel>
                <Input
                  type="email"
                  size="lg"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                  bg="transparent"
                  borderColor="rgba(0, 0, 0, 0.3)"
                  _placeholder={{ color: 'black' }}
                  _focus={{ borderColor: 'hsl(218, 41%, 25%)', boxShadow: '0 0 0 1px hsl(218, 41%, 25%)' }}
                  color="black"
                />
              </FormControl>
              <FormControl id="message" isRequired>
                <FormLabel color="black">Message</FormLabel>
                <Textarea
                  size="lg"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Your Message"
                  bg="transparent"
                  borderColor="rgba(0, 0, 0, 0.3)"
                  _placeholder={{ color: 'black' }}
                  _focus={{ borderColor: 'hsl(218, 41%, 25%)', boxShadow: '0 0 0 1px hsl(218, 41%, 25%)' }}
                  color="black"
                />
              </FormControl>
              <Button
                colorScheme="teal"
                size="lg"
                width="full"
                bg="hsl(218, 41%, 30%)"
                color="white"
                _hover={{ bg: 'hsl(218, 41%, 40%)' }}
                _active={{ bg: 'hsl(218, 41%, 25%)' }}
                type="submit"
                isLoading={loading}
              >
                {loading ? 'Sending...' : 'Send Message'}
              </Button>
            </Stack>
          </form>
        </Box>
      </Flex>
    </Container>
  );
}

export default Contact;
