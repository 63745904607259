import React, { useEffect } from 'react';
import {
  Box,
  Heading,
  Text,
  SimpleGrid,
  VStack,
  HStack,
  Button,
  useColorModeValue,
  Image,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import UserNavbar from './auth/UserNavbar';
import MainpageFooter from './MainpageFooter';
import {
  FaHandHoldingHeart,
  FaLightbulb,
  FaUsers,
  FaClipboardList,
  FaShieldAlt,
  FaBell,
  FaEye,
} from 'react-icons/fa';
import A1Image from '../assets/images/tools.png'
import A2Image from '../assets/images/about us image.jpg'

const AboutUs = ({ isAuthenticated }) => {
  const navigate = useNavigate();

  useEffect(() => {}, [isAuthenticated]);

  const cardBgColor = useColorModeValue('white', 'gray.800');
  const headingTextShadow = '2px 2px 4px rgba(0, 0, 0, 0.6)'; // Text shadow for contrast

  return (
    <div>
      {/* Background color gradient */}
      <Box bgGradient="linear(to-r, blue.900, blue.700)" minHeight="100vh" p={8}>
        <Box position="fixed" top={0} left={0} width="100%" zIndex={1000}>
          {isAuthenticated ? <UserNavbar /> : <Navbar />}
        </Box>
        <br /><br />

        <Box mb={12}>
          {/* Page Heading */}
          <Heading
            as="h1"
            fontSize={{ base: '3xl', md: '4xl' }}
            fontFamily="Poppins, sans-serif"
            textAlign="center"
            mb={6}
            textShadow={headingTextShadow}
            color='white'
          >
            About Us
          </Heading>
          <Text fontSize="lg" fontFamily="Arial, sans-serif" textAlign="center" mb={8} color='white'>
            At <strong>Pentity</strong>, we are dedicated to uncovering hidden threats and safeguarding your digital presence. Our mission is to provide comprehensive domain and data security solutions, helping businesses of all sizes protect their most valuable assets.
            With our advanced security platform, you can confidently navigate the digital landscape, knowing that you have the most robust defenses against potential cyber threats.
          </Text>
        </Box>

        {/* Mission and Vision */}
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} mb={12}>
          {/* Mission */}
          <Box
            bg={cardBgColor}
            p={8}
            rounded="lg"
            shadow="md"
            _hover={{ transform: 'scale(1.02)', transition: 'all 0.3s ease-in-out' }}
          >
            <Heading
              as="h3"
              fontSize="2xl"
              mb={4}
              fontFamily="Poppins, sans-serif"
              color="pink.400"
            >
              Our Mission
            </Heading>
            <Image src={A1Image} alt="Our Mission" borderRadius="lg" mb={4} />

            <Text fontSize="md" fontFamily="Arial, sans-serif" lineHeight="1.75">
              At <strong>Pentity</strong>, our mission is simple: to help businesses stay secure in an increasingly digital world.
              We are committed to providing cutting-edge, data-driven solutions that protect our clients from cyber threats before they become a problem.
              Through innovation, expertise, and integrity, we aim to empower organizations with the tools they need to safeguard their data and ensure operational continuity.
            </Text>
          </Box>

          {/* Vision */}
          <Box
            bg={cardBgColor}
            p={8}
            rounded="lg"
            shadow="md"
            _hover={{ transform: 'scale(1.02)', transition: 'all 0.3s ease-in-out' }}
          >
            <Heading
              as="h3"
              fontSize="2xl"
              mb={4}
              fontFamily="Poppins, sans-serif"
              color="blue.400"
            >
              Our Vision
            </Heading>
            <Image src={A2Image} alt="Our Mission" borderRadius="lg" mb={4} />
            <Text fontSize="md" fontFamily="Arial, sans-serif" lineHeight="1.75">
              We envision a world where every business can confidently leverage digital technologies without fear of cyber threats.
              Our goal is to become the global leader in security solutions, preemptively identifying risks before they can cause harm, and making the internet a safer place for all businesses.
            </Text>
          </Box>
        </SimpleGrid>

        {/* Core Values */}
        <Box bg={cardBgColor} p={6} rounded="lg" shadow="md" mb={8}>
          <Heading
            as="h3"
            fontSize="2xl"
            fontFamily="Arial, sans-serif"
            mb={6}
            textAlign="center"
            color="teal.500"
          >
            Our Core Values
          </Heading>
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={6}>
             
            <Box textAlign="center">
            <Box
        bg="pink.100"
        p={4}
        rounded="full"
        display="flex"
        justifyContent="center"
        alignItems="center"
        boxShadow="md"
      >
        <FaHandHoldingHeart size={40} color="red" />
      </Box>
              
              
              <Heading fontSize="lg" fontFamily="Arial, sans-serif" color="pink.500" mt={4}>
                Integrity
              </Heading>
            
              <Text fontSize="md" fontFamily="Arial, sans-serif">
                We operate with transparency, honesty, and respect in all our interactions.
              </Text>
            </Box>
            <Box textAlign="center">
            <Box
        bg="yellow.100"
        p={4}
        rounded="full"
        display="flex"
        justifyContent="center"
        alignItems="center"
        boxShadow="md"
      >
        <FaLightbulb size={40} color="gold" />
      </Box>
              
              <Heading fontSize="lg" fontFamily="Arial, sans-serif" color="yellow.500" mt={4}>
                Innovation
              </Heading>
             
              <Text fontSize="md" fontFamily="Arial, sans-serif">
                We embrace the power of technology to solve complex challenges and constantly evolve our solutions.
              </Text>
            </Box>
            <Box textAlign="center">
            <Box
        bg="teal.100"
        p={4}
        rounded="full"
        display="flex"
        justifyContent="center"
        alignItems="center"
        boxShadow="md"
      >
        <FaUsers size={40} color="teal" />
      </Box>
             
              <Heading fontSize="lg" fontFamily="Arial, sans-serif" color="teal.500" mt={4}>
                Customer Success
              </Heading>
             
              <Text fontSize="md" fontFamily="Arial, sans-serif">
                Our success is defined by the success of our clients. We work diligently to protect their digital future.
              </Text>
            </Box>
          </SimpleGrid>
        </Box>

        {/* Summary Map with Icons */}
<Box bg={cardBgColor} p={8} rounded="lg" shadow="lg" mb={8}>
  <Heading
    as="h3"
    fontSize="2xl"
    fontFamily="Poppins, sans-serif"
    mb={8}
    textAlign="center"
    color="teal.500"
    textTransform="uppercase"
    letterSpacing="widest"
  >
    Our Process
  </Heading>

  {/* Horizontal Flow for Summary Map */}
  <HStack justify="center" spacing={12}>
    {/* Step 1: Assessment */}
    <VStack align="center" spacing={4}>
      <Box
        bg="blue.100"
        p={4}
        rounded="full"
        display="flex"
        justifyContent="center"
        alignItems="center"
        boxShadow="md"
      >
        <FaClipboardList size={40} color="blue" />
      </Box>
      <Text fontSize="lg" fontWeight="bold" color="blue.600">
        Assessment
      </Text>
      {/* <Text fontSize="sm" color="gray.500" textAlign="center" maxW="200px">
        We evaluate your current security and identify gaps.
      </Text> */}
    </VStack>

    {/* Step 2: Strategy Development */}
    <VStack align="center" spacing={4}>
      <Box
        bg="green.100"
        p={4}
        rounded="full"
        display="flex"
        justifyContent="center"
        alignItems="center"
        boxShadow="md"
      >
        <FaShieldAlt size={40} color="green" />
      </Box>
      <Text fontSize="lg" fontWeight="bold" color="green.600">
        Strategy
      </Text>
      {/* <Text fontSize="sm" color="gray.500" textAlign="center" maxW="200px">
        We develop a customized security strategy tailored to your needs.
      </Text> */}
    </VStack>

    {/* Step 3: Implementation */}
    <VStack align="center" spacing={4}>
      <Box
        bg="orange.100"
        p={4}
        rounded="full"
        display="flex"
        justifyContent="center"
        alignItems="center"
        boxShadow="md"
      >
        <FaBell size={40} color="orange" />
      </Box>
      <Text fontSize="lg" fontWeight="bold" color="orange.600">
        Implementation
      </Text>
      {/* <Text fontSize="sm" color="gray.500" textAlign="center" maxW="200px">
        We execute the plan to secure your environment.
      </Text> */}
    </VStack>

    {/* Step 4: Monitoring */}
    <VStack align="center" spacing={4}>
      <Box
        bg="red.100"
        p={4}
        rounded="full"
        display="flex"
        justifyContent="center"
        alignItems="center"
        boxShadow="md"
      >
        <FaEye size={40} color="red" />
      </Box>
      <Text fontSize="lg" fontWeight="bold" color="red.600">
        Monitoring
      </Text>
      {/* <Text fontSize="sm" color="gray.500" textAlign="center" maxW="200px">
        We continuously monitor and refine your security posture.
      </Text> */}
    </VStack>
  </HStack>
</Box>

      </Box>
       {/* Footer */}
       <MainpageFooter />
    </div>
  );
};

export default AboutUs;
