import React, { useState, useEffect } from 'react';
import { Box, Heading, Text, Button, VStack, useColorModeValue, useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { updateUserPlan } from '../authService'; // Import the updateUserPlan function

const UpdatePlan = () => {
    const [currentPlan, setCurrentPlan] = useState('');
    const [availablePlans, setAvailablePlans] = useState([]);
    const [email, setEmail] = useState('');
    const [updating, setUpdating] = useState(false);
    const navigate = useNavigate();
    const toast = useToast();

    useEffect(() => {
        const userPlan = sessionStorage.getItem('userPlan') || '';
        const userEmail = sessionStorage.getItem('userEmail') || '';
        setCurrentPlan(userPlan);
        setEmail(userEmail);

        setAvailablePlans([
            { title: 'Silver', price: '100 $/Month' },
            { title: 'Gold', price: '150 $/Month' },
            { title: 'Platinum', price: 'Contact Us' }
        ]);
    }, []);

    const handleUpgrade = async (planTitle) => {
        setUpdating(true);
        try {
            const success = await updateUserPlan(email, planTitle);

            if (success) {
                sessionStorage.setItem('userPlan', planTitle);
                toast({
                    title: "Plan Updated.",
                    description: `You have successfully upgraded to the ${planTitle} plan.`,
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });

                navigate('/confirmation');
            }
        } catch (error) {
            console.error('Error updating plan:', error);
            toast({
                title: "Update Failed.",
                description: "There was an error updating your plan. Please try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setUpdating(false);
        }
    };

    const bgColor = useColorModeValue('gray.100', 'gray.700');
    const cardBgColor = 'white';

    return (
        <Box bg={bgColor} py={8} px={4}>
            <VStack spacing={8} align="center">
                <Heading as="h2" size="lg" mb={4}>
                    Update Your Plan
                </Heading>
                <Text fontSize="lg" mb={8}>
                    Your current plan is: <strong>{currentPlan}</strong>
                </Text>
                <VStack spacing={4} align="stretch" maxW="600px" mx="auto">
                    {availablePlans
                        .filter(plan => plan.title !== currentPlan) // Filter out the current plan
                        .map(plan => (
                            <Box
                                key={plan.title}
                                borderWidth="1px"
                                borderRadius="md"
                                p={6}
                                bg={cardBgColor}
                                boxShadow="xl"
                                _hover={{ boxShadow: '2xl', transform: 'scale(1.02)', transition: 'all 0.3s ease' }}
                            >
                                <Heading as="h3" size="md" mb={4}>
                                    {plan.title}
                                </Heading>
                                <Text fontSize="2xl" fontWeight="bold" mb={4}>
                                    {plan.price}
                                </Text>
                                <Button
                                    colorScheme="blue"
                                    isLoading={updating}
                                    onClick={() => handleUpgrade(plan.title)}
                                >
                                    Upgrade to {plan.title}
                                </Button>
                            </Box>
                        ))}
                </VStack>
            </VStack>
        </Box>
    );
};

export default UpdatePlan;
