import React, { useEffect } from 'react';

import UserNavbar from '../auth/UserNavbar';
import Navbar from '../Navbar';
import KeyServices from './KeyServices';
import KeyBenefits from './KeyBenefits';
import SecureYourBrand from './SecureYourBrand';
import MainpageFooter from '../MainpageFooter'


const InformationContent = ({ isAuthenticated }) => {
    
    useEffect(() => {
    
    }, [isAuthenticated]);

    return (
        <div>
            {isAuthenticated ? <UserNavbar /> : <Navbar />}
            <KeyServices />
            <KeyBenefits />
            <SecureYourBrand />
            <MainpageFooter/>
        </div>
    );
};

export default InformationContent;
