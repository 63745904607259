import React from 'react';
import {
  Box, Button, Container, Flex, Heading, Icon, Stack, Text, useColorModeValue, VStack, Image
} from '@chakra-ui/react';
import feature1 from '../../assets/images/feature1.png';
import feature2 from '../../assets/images/feature2.png';
import feature3 from '../../assets/images/feature3.png';
import { FaUserSecret, FaEnvelopeOpenText, FaTrademark, FaSearchLocation, FaCode, FaShieldAlt, FaBug, FaCogs, FaNetworkWired } from 'react-icons/fa';
import { CheckIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';

const Card = ({ heading, description, icon, navigateTo }) => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate(navigateTo);
  };

  return (
    <Box
      maxW={{ base: 'full', md: '275px' }}
      w={'full'}
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p={6}
      bg={useColorModeValue('white', 'gray.800')}
      boxShadow={'lg'}
      _hover={{
        transform: 'translateY(-10px)',
        boxShadow: '2xl',
        transition: 'all 0.3s ease-in-out',
      }}>
      <Stack align={'start'} spacing={3}>
        <Flex
          w={16}
          h={16}
          align={'center'}
          justify={'center'}
          rounded={'full'}
          bg={useColorModeValue('gray.100', 'gray.700')}
          color={'white'}>
          {icon}
        </Flex>
        <Box mt={4}>
          <Heading size="md" fontWeight={'bold'} color={useColorModeValue('gray.700', 'white')}>
            {heading}
          </Heading>
          <Text mt={2} fontSize={'sm'} color={useColorModeValue('gray.600', 'gray.400')}>
            {description}
          </Text>
        </Box>
        <Button
          variant={'solid'}
          colorScheme={'blue'}
          size={'sm'}
          _hover={{ bg: 'blue.600' }}
          onClick={handleNavigation}>
          Learn more
        </Button>
      </Stack>
    </Box>
  );
};

export default function GridListWith() {
  return (
    <Box p={8} bg={useColorModeValue('gray.50', 'gray.900')}>
      <Stack spacing={6} as={Container} maxW={'3xl'} textAlign={'center'}>
        <Heading fontSize={{ base: '2xl', sm: '4xl' }} fontWeight={'extrabold'} color={useColorModeValue('gray.800', 'white')}>
          Discover Our Offerings
        </Heading>
        <Text color={useColorModeValue('gray.600', 'gray.400')} fontSize={{ base: 'md', sm: 'lg' }}>
          Deep and Dark analysis of your company data
        </Text>
      </Stack>

      <Container maxW={'6xl'} mt={10}>
        <Flex flexWrap="wrap" gridGap={8} justify="center">
          <Card
            heading={'Deep and Dark Scan'}
            icon={
              <Box
                bgGradient="linear(to-r, purple.500, pink.500)"
                p={4}
                borderRadius="lg"
                boxShadow="lg"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Icon as={FaUserSecret} w={10} h={10} color="white" />
              </Box>
            }
            description={'Uncover Hidden Threats, Protect Your Digital Fortress.'}
            navigateTo={'/informationcontent'}
          />

          <Card
            heading={'Email Security'}
            icon={
              <Box
                bgGradient="linear(to-r, blue.500, cyan.500)"
                p={4}
                borderRadius="lg"
                boxShadow="lg"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Icon as={FaEnvelopeOpenText} w={10} h={10} color="white" />
              </Box>
            }
            description={'Fortify Your Digital Fortress with comprehensive security services to protect your business.'}
            navigateTo={'/informationcontent'}
          />

          <Card
            heading={'Brand Protection'}
            icon={
              <Box
                bgGradient="linear(to-r, red.500, orange.500)"
                p={4}
                borderRadius="lg"
                boxShadow="lg"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Icon as={FaTrademark} w={10} h={10} color="white" />
              </Box>
            }
            description={'Advanced tools for homoglyph, squatting, DKIM, and technology stack analysis.'}
            navigateTo={'/informationcontent'}
          />

          <Card
            heading={'DNS and Whois Detect'}
            icon={
              <Box
                bgGradient="linear(to-r, green.500, teal.500)"
                p={4}
                borderRadius="lg"
                boxShadow="lg"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Icon as={FaSearchLocation} w={10} h={10} color="white" />
              </Box>
            }
            description={'URL analysis and speed optimization for a safer, faster online presence.'}
            navigateTo={'/informationcontent'}
          />

          <Card
            heading={'Code Review'}
            icon={
              <Box
                bgGradient="linear(to-r, teal.500, blue.500)"
                p={4}
                borderRadius="lg"
                boxShadow="lg"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Icon as={FaCode} w={10} h={10} color="white" />
              </Box>
            }
            description={'Ensure code quality and security from the ground up, protecting your software from attacks.'}
            navigateTo={'/informationcontent'}
          />
        </Flex>

        <br />

        <Stack spacing={6} as={Container} maxW={'3xl'} textAlign={'center'}>
          <Heading fontSize={{ base: '2xl', sm: '4xl' }} fontWeight={'extrabold'} color={useColorModeValue('gray.800', 'white')}>
            Other Services
          </Heading>
          
        </Stack> <br />

        <Flex flexWrap="wrap" gridGap={8} justify="center">
          <Card
            heading={'Phishing Simulation'}
            icon={
              <Box
                bgGradient="linear(to-r, purple.500, pink.500)"
                p={4}
                borderRadius="lg"
                boxShadow="lg"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Icon as={FaShieldAlt} w={10} h={10} color="white" />
              </Box>
            }
            description={'Uncover Hidden Threats, Protect Your Digital Fortress.'}
            navigateTo={'/otherServices'}
          />

          <Card
            heading={'Automated Penetration Testing and Vulnerability Analysis'}
            icon={
              <Box
                bgGradient="linear(to-r, blue.500, cyan.500)"
                p={4}
                borderRadius="lg"
                boxShadow="lg"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Icon as={FaBug} w={10} h={10} color="white" />
              </Box>
            }
            description={'Fortify Your Digital Fortress with comprehensive security services to protect your business.'}
            navigateTo={'/otherServices'}
          />

          <Card
            heading={'Manual Business Logic Testing'}
            icon={
              <Box
                bgGradient="linear(to-r, red.500, orange.500)"
                p={4}
                borderRadius="lg"
                boxShadow="lg"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Icon as={FaCogs} w={10} h={10} color="white" />
              </Box>
            }
            description={'Advanced tools for homoglyph, squatting, DKIM, and technology stack analysis.'}
            navigateTo={'/otherServices'}
          />

          <Card
            heading={'Cybersecurity Integration Services'}
            icon={
              <Box
                bgGradient="linear(to-r, green.500, teal.500)"
                p={4}
                borderRadius="lg"
                boxShadow="lg"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Icon as={FaNetworkWired} w={10} h={10} color="white" />
              </Box>
            }
            description={'URL analysis and speed optimization for a safer, faster online presence.'}
            navigateTo={'/otherServices'}
          />
        </Flex>

        <br />
        <br />

        {/* Features Section integrated */}
        <Box bg={useColorModeValue('gray.50', 'gray.800')} py={16} px={8}>
          <Flex
            direction={{ base: 'column', md: 'row' }}
            align="center"
            justify="space-between"
            maxW="7xl"
            mx="auto"
          >
            {/* Left Side */}
            <VStack spacing={4} align="flex-start" w="full" maxW="lg">
              <Heading
                as="h1"
                size="2xl"
                fontWeight="bold"
                color={useColorModeValue('gray.800', 'white')}
              >

                <Text
                  as="span"
                  color="blue.400"
                  bg="blue.100"
                  display="inline-block"
                  py={2}
                  px={3}
                  borderRadius="md"
                >
                  Pentity
                </Text>{' '}
                Features
              </Heading>
              <Text fontSize="lg" color={useColorModeValue('gray.600', 'gray.400')}>
                Explore our innovative features designed to enhance your workflow and streamline your operations effortlessly.
              </Text>
              <Stack spacing={3} pt={4}>
                <Flex align="center">
                  <Icon as={CheckIcon} color="blue.400" />
                  <Text ml={2}>Domain and DNS Protection</Text>
                </Flex>
                <Flex align="center">
                  <Icon as={CheckIcon} color="blue.400" />
                  <Text ml={2}>Deep and Dark Data Monitoring</Text>
                </Flex>
                <Flex align="center">
                  <Icon as={CheckIcon} color="blue.400" />
                  <Text ml={2}>Threat Detection and Alerting</Text>
                </Flex>
                <Flex align="center">
                  <Icon as={CheckIcon} color="blue.400" />
                  <Text ml={2}>Incident Response and Remediation</Text>
                </Flex>
              </Stack>
            </VStack>

            {/* Right Side */}
            <Box
              mt={{ base: 10, md: 0 }}
              w="full"
              maxW="md"
              ml={{ md: 10 }}
              position="relative"
              h={{ base: "250px", md: "300px" }} // Increased height for better spacing on mobile
            >
              <Box
                boxShadow="lg"
                borderRadius="md"
                overflow="hidden"
                position="absolute"
                w={{ base: "35%", md: "20%" }} // Adjusted width for better scaling on mobile
                h={{ base: "90px", md: "130px" }} // Adjusted height for better scaling on mobile
                transform={{ base: "scale(1.5)", md: "scale(2.5)" }} // Scaled down for mobile
                transformOrigin="center"
                top={{ base: "10px", md: "20px" }} // Consistent top position
                left={{ base: "20px", md: 0 }} // Adjusted left position for mobile
                right={0}
                bottom={0}
              >
                <Image src={feature1} alt="Image 1" objectFit="contain" w="100%" h="100%" />
              </Box>

              <Box
                boxShadow="lg"
                borderRadius="md"
                overflow="hidden"
                position="absolute"
                w={{ base: "34%", md: "19%" }} // Adjusted width for better scaling on mobile
                h={{ base: "100px", md: "130px" }} // Adjusted height for better scaling on mobile
                transform={{ base: "scale(1.5)", md: "scale(2.5)" }} // Scaled down for mobile
                transformOrigin="center"
                top={{ base: "70px", md: "80px" }} // Consistent top position
                left={{ base: "80px", md: "140px" }} // Adjusted left position for mobile
                right={0}
                bottom={0}
              >
                <Image src={feature3} alt="Image 2" objectFit="contain" w="100%" h="100%" />
              </Box>

              <Box
                boxShadow="lg"
                borderRadius="md"
                overflow="hidden"
                position="absolute"
                w={{ base: "30%", md: "17%" }} // Adjusted width for better scaling on mobile
                h={{ base: "100px", md: "130px" }} // Adjusted height for better scaling on mobile
                transform={{ base: "scale(1.5)", md: "scale(2.5)" }} // Scaled down for mobile
                transformOrigin="centerx"
                top={{ base: "110px", md: "160px" }} // Consistent top position
                left={{ base: "140px", md: "250px" }} // Adjusted left position for mobile
                right={0}
                bottom={0}
              >
                <Image src={feature2} alt="Image 3" objectFit="contain" w="100%" h="100%" />
              </Box>
            </Box>

          </Flex>
        </Box>
      </Container>
    </Box>
  );
}
