import React from 'react';
import { Box, Text, Divider, Table, Thead, Tbody, Tr, Th, Td, Button, useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { CheckCircleIcon, CloseIcon, InfoOutlineIcon ,WarningIcon} from '@chakra-ui/icons'; // Importing icons

// Function to get user plan from session storage
const getUserPlan = () => {
  return sessionStorage.getItem('userPlan') || 'Free'; // Default to 'Free' if not found
};

const SquattingCard = ({ data }) => {
  const typoSquatting = data.typo_squatting || [];
  const bitSquatting = data.bit_squatting || [];
  const homoglyphs = data.homoglyphs || [];
  const scores = data.scores || {};
  const toast = useToast();
  const userPlan = getUserPlan(); // Retrieve user plan here
  const navigate = useNavigate();

  const handleNavigation = (navigateTo, data) => {
    if (userPlan === 'Free') {
      toast({
        title: 'Upgrade Required',
        description: 'You need to upgrade your plan to access this feature.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
    } else {
      navigate(navigateTo, { state: data });
    }
  };

  return (
    <Box
      p={6}
      borderWidth="1px"
      borderRadius="lg"
      boxShadow="lg"
      backgroundColor="gray.50"
      width={['95%', '80%', '70%']} // Adjust width based on screen size
      mx="auto"
      mt={6}
      _hover={{ boxShadow: 'xl', transform: 'scale(1.02)', transition: '0.3s ease-in-out' }}
    >
      <Box display="flex" justifyContent="center" alignItems="center" mb={4}>
        <WarningIcon boxSize={8} color="blue.600" mr={2} />
        <Text fontWeight="bold" fontSize="2xl" textAlign="center" color="blue.800">
          Squatting Overview
        </Text>
      </Box>
      <Divider mb={4} />
      <Table variant="striped" colorScheme="teal">
        <Thead>
          <Tr>
            <Th fontWeight="bold" fontSize="lg" color="blue.900">Category</Th>
            <Th fontWeight="bold" fontSize="lg" color="blue.900">Score</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>Typo Squatting</Td>
            <Td color={scores.typo_squatting > 50 ? 'red.500' : 'green.500'}>
              {scores.typo_squatting != null ? (
                <>
                  {scores.typo_squatting}
                  {scores.typo_squatting > 50 ? (
                    <CloseIcon color="red.500" ml={2} />
                  ) : (
                    <CheckCircleIcon color="green.500" ml={2} />
                  )}
                </>
              ) : '-'}
            </Td>
          </Tr>
          <Tr>
            <Td>Bit Squatting</Td>
            <Td color={scores.bit_squatting > 50 ? 'red.500' : 'green.500'}>
              {scores.bit_squatting != null ? (
                <>
                  {scores.bit_squatting}
                  {scores.bit_squatting > 50 ? (
                    <CloseIcon color="red.500" ml={2} />
                  ) : (
                    <CheckCircleIcon color="green.500" ml={2} />
                  )}
                </>
              ) : '-'}
            </Td>
          </Tr>
          <Tr>
            <Td>Homoglyphs</Td>
            <Td color={scores.homoglyphs > 50 ? 'red.500' : 'green.500'}>
              {scores.homoglyphs != null ? (
                <>
                  {scores.homoglyphs}
                  {scores.homoglyphs > 50 ? (
                    <CloseIcon color="red.500" ml={2} />
                  ) : (
                    <CheckCircleIcon color="green.500" ml={2} />
                  )}
                </>
              ) : '-'}
            </Td>
          </Tr>
        </Tbody>
      </Table>
      <Box mt={6} textAlign="center">
        <Button
          colorScheme="blue"
          variant="solid"
          size="lg"
           _hover={{ bg: 'blue.600' }}
          transition="background 0.3s"
          mb={4}
          mr={4} // Margin-right for spacing
          onClick={() => handleNavigation('/typo-squatting', { typoSquatting })}
        >
          View Typo Squatting ({typoSquatting.length})
        </Button>
        <Button
          colorScheme="blue"
          variant="solid"
          size="lg"
           _hover={{ bg: 'blue.600' }}
          transition="background 0.3s"
          mb={4}
          mr={4} // Margin-right for spacing
          onClick={() => handleNavigation('/bit-squatting', { bitSquatting })}
        >
          View Bit Squatting ({bitSquatting.length})
        </Button>
        <Button
          colorScheme="blue"
          variant="solid"
          size="lg"
           _hover={{ bg: 'blue.600' }}
          transition="background 0.3s"
          mb={4}
          onClick={() => handleNavigation('/homoglyphs-details', { homoglyphs })}
        >
          View Homoglyphs ({homoglyphs.length})
        </Button>
      </Box>
    </Box>
  );
};

export default SquattingCard;
