// import { CognitoIdentityProviderClient, AdminUpdateUserAttributesCommand, InitiateAuthCommand, SignUpCommand, ConfirmSignUpCommand, ResendConfirmationCodeCommand } from "@aws-sdk/client-cognito-identity-provider";
// import config from "./config.json";

// // Initialize the CognitoIdentityProviderClient with the correct region
// export const cognitoClient = new CognitoIdentityProviderClient({
//   region: config.region,
//   credentials: {
//     accessKeyId: config.accessKeyId,
//     secretAccessKey: config.secretAccessKey, // Include this if you're using a secret key
//   },
// });

// // Sign-in function using AWS SDK v3
// export const sdkSignIn = async (email, password) => {
//   const params = {
//     AuthFlow: "USER_PASSWORD_AUTH",
//     ClientId: config.clientId,
//     AuthParameters: {
//       USERNAME: email,
//       PASSWORD: password,
//     },
//   };

//   try {
//     const command = new InitiateAuthCommand(params);
//     const { AuthenticationResult } = await cognitoClient.send(command);

//     if (AuthenticationResult) {
//       // Store tokens in sessionStorage
//       sessionStorage.setItem("idToken", AuthenticationResult.IdToken || '');
//       sessionStorage.setItem("accessToken", AuthenticationResult.AccessToken || '');
//       sessionStorage.setItem("refreshToken", AuthenticationResult.RefreshToken || '');

//       // Return the tokens for further use
//       return {
//         idToken: AuthenticationResult.IdToken || '',
//         accessToken: AuthenticationResult.AccessToken || '',
//         refreshToken: AuthenticationResult.RefreshToken || '',
//       };
//     }
//   } catch (error) {
//     console.error("Error signing in: ", error);
//     throw error;
//   }
// };

// // Sign-up function using AWS SDK v3
// export const signUp = async (username, email, password, customAttributes = {}) => {
//   const userAttributes = [
//     { Name: "email", Value: email },
//     ...Object.entries(customAttributes).map(([Name, Value]) => ({ Name, Value })),
//   ];

//   const params = {
//     ClientId: config.clientId,
//     Username: username,
//     Password: password,
//     UserAttributes: userAttributes,
//   };

//   try {
//     const command = new SignUpCommand(params);
//     const result = await cognitoClient.send(command);
//     return result;
//   } catch (error) {
//     console.error("Error signing up: ", error);
//     throw error;
//   }
// };

// // Confirm sign-up function using AWS SDK
// export const confirmSignUp = async (username, code) => {
//   const params = {
//     ClientId: config.clientId,
//     Username: username,
//     ConfirmationCode: code,
//   };

//   try {
//     const command = new ConfirmSignUpCommand(params);
//     await cognitoClient.send(command);
//     return true;
//   } catch (error) {
//     console.error("Error confirming sign up: ", error);
//     throw new Error(`Failed to confirm account: ${error.message}`);
//   }
// };

// // Resend confirmation code function using AWS SDK
// export const resendConfirmationCode = async (username) => {
//   const params = {
//     ClientId: config.clientId,
//     Username: username,
//   };

//   try {
//     const command = new ResendConfirmationCodeCommand(params);
//     await cognitoClient.send(command);
//     return true;
//   } catch (error) {
//     console.error("Error resending confirmation code: ", error);
//     throw error;
//   }
// };

// // Update user attributes in Cognito
// export const updateUserPlan = async (email, newPlan) => {
//   console.log("User Pool ID:", config.userPoolId);
//   console.log("Username (Email):", email);
//   console.log("New Plan:", newPlan);

//   const params = {
//     UserPoolId: config.userPoolId,
//     Username: email,
//     UserAttributes: [
//       { Name: 'custom:plan', Value: newPlan }, 
//     ],
//   };

//   try {
//     const command = new AdminUpdateUserAttributesCommand(params);
//     const result = await cognitoClient.send(command);
//     console.log("Update result:", result);
//     return result;
//   } catch (error) {
//     console.error("Error updating user plan: ", error);
//     throw error;
//   }
// };

// src/aws-cognito.js

import { CognitoIdentityProviderClient, AdminUpdateUserAttributesCommand, InitiateAuthCommand, SignUpCommand, ConfirmSignUpCommand, ResendConfirmationCodeCommand } from "@aws-sdk/client-cognito-identity-provider";
import { CognitoUserPool } from 'amazon-cognito-identity-js'; // Import the CognitoUserPool

// Load environment variables
const config = {
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY, 
  clientId: process.env.REACT_APP_AWS_CLIENT_ID,
  userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
};

// Create a user pool instance
export const userPool = new CognitoUserPool({
  UserPoolId: config.userPoolId,
  ClientId: config.clientId,
});

// Initialize the CognitoIdentityProviderClient with the correct region and credentials
export const cognitoClient = new CognitoIdentityProviderClient({
  region: config.region,
  credentials: {
    accessKeyId: config.accessKeyId,
    secretAccessKey: config.secretAccessKey, // Include this if you're using a secret key
  },
});

// Sign-in function using AWS SDK v3
export const sdkSignIn = async (email, password) => {
  const params = {
    AuthFlow: "USER_PASSWORD_AUTH",
    ClientId: config.clientId,
    AuthParameters: {
      USERNAME: email,
      PASSWORD: password,
    },
  };

  try {
    const command = new InitiateAuthCommand(params);
    const { AuthenticationResult } = await cognitoClient.send(command);

    if (AuthenticationResult) {
      // Store tokens in sessionStorage
      sessionStorage.setItem("idToken", AuthenticationResult.IdToken || '');
      sessionStorage.setItem("accessToken", AuthenticationResult.AccessToken || '');
      sessionStorage.setItem("refreshToken", AuthenticationResult.RefreshToken || '');

      // Return the tokens for further use
      return {
        idToken: AuthenticationResult.IdToken || '',
        accessToken: AuthenticationResult.AccessToken || '',
        refreshToken: AuthenticationResult.RefreshToken || '',
      };
    }
  } catch (error) {
    console.error("Error signing in: ", error);
    throw error;
  }
};

// Sign-up function using AWS SDK v3
export const signUp = async (username, email, password, customAttributes = {}) => {
  const userAttributes = [
    { Name: "email", Value: email },
    { Name: "given_name", Value: customAttributes.given_name || "" },
    { Name: "family_name", Value: customAttributes.family_name || "" },
    ...Object.entries(customAttributes)
      .filter(([key]) => key !== "given_name" && key !== "family_name")
      .map(([Name, Value]) => ({ Name, Value })),
  ];

  const params = {
    ClientId: config.clientId,
    Username: email, // Use email as the username
    Password: password,
    UserAttributes: userAttributes,
  };

  try {
    const command = new SignUpCommand(params);
    const result = await cognitoClient.send(command);
    return result;
  } catch (error) {
    console.error("Error signing up: ", error);
    throw error;
  }
};

// Confirm user registration
export const confirmSignUp = async (email, code) => {
  const params = {
    ClientId: config.clientId,
    Username: email, // Use email for confirmation
    ConfirmationCode: code,
  };

  try {
    const command = new ConfirmSignUpCommand(params);
    await cognitoClient.send(command);
    return true;
  } catch (error) {
    console.error('Error confirming sign up: ', error);
    throw error; // Pass the error to the caller for specific handling
  }
};

// Resend confirmation code
export const resendConfirmationCode = async (email) => {
  const params = {
    ClientId: config.clientId,
    Username: email, // Use email to resend the code
  };

  try {
    const command = new ResendConfirmationCodeCommand(params);
    await cognitoClient.send(command);
    return true;
  } catch (error) {
    console.error('Error resending confirmation code: ', error);
    throw error; // Pass the error to the caller for specific handling
  }
};

// Update user attributes in Cognito
export const updateUserPlan = async (email, newPlan) => {
  console.log("User Pool ID:", config.userPoolId);
  console.log("Username (Email):", email);
  console.log("New Plan:", newPlan);

  const params = {
    UserPoolId: config.userPoolId,
    Username: email,
    UserAttributes: [
      { Name: 'custom:plan', Value: newPlan },
    ],
  };

  try {
    const command = new AdminUpdateUserAttributesCommand(params);
    const result = await cognitoClient.send(command);
    console.log("Update result:", result);
    return result;
  } catch (error) {
    console.error("Error updating user plan: ", error);
    throw error;
  }
};
