import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Input,
  Spinner,
  VStack,
  Box,
  Text,
  Table,
  Tbody,
  Thead,
  Tr,
  Td,
  Th,
  Card,
  CardBody,
  Heading,
  Stack,
  HStack,
  Icon,
  MenuButton,
  MenuList,
  useToast,
  MenuItem, 
  Menu,
  useBreakpointValue,
  Center
} from '@chakra-ui/react';
import axios from 'axios';
import { FaBuilding, FaInfoCircle, FaQuestionCircle, FaLink, FaChevronDown } from 'react-icons/fa'; // Added ChevronDown for dropdown icon
import ImageOne from '../../assets/images/Global_network_generated.jpg';
import UserNavbar from '../auth/UserNavbar';
import MainpageFooter from '../MainpageFooter';
// import MainComponent from '../Maincomponent';
import {getToken } from '../auth/loginPage';


export default function Url() {
  const [url, setUrl] = useState('');
  const [data, setUrlData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const [, setError] = useState(null);
  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const [message, setMessage] = useState('');


    // Define responsive values for padding, font size, etc.
const paddingX = useBreakpointValue({ base: '4', md: '6', lg: '8' });
const buttonWidth = useBreakpointValue({ base: '80%', sm: '60%', md: '50%' });
const containerWidth = useBreakpointValue({ base: '90%', md: '70%', lg: '60%' });
const containerHeight = useBreakpointValue({ base: '70%', md: '60%', lg: '50%' });

  // Load results from sessionStorage if available
  useEffect(() => {
    const checkAuthentication = () => {
      const storedToken = getToken(); // Retrieve token
      console.log('Retrieved token:', storedToken);
      setToken(storedToken);
  
      const accessToken = sessionStorage.getItem('accessToken'); // Access token should be stored in session storage
  
      if (!accessToken) {
        navigate('/signin'); // Redirect to sign-in page if no access token
        return;
      }
    };
  
    checkAuthentication();
  }, [navigate]);
  

  const handleSearch = async () => {
    if (!url) return;

    setIsLoading(true); 
    setError(null);
    if (!token) {
      setMessage('Token is missing. Please sign in first.');
      return;
    }
    try {
      const response = await axios.get(`https://e7z0z8p9l7.execute-api.ap-south-1.amazonaws.com/v1/api/url/url_reputation/?url=${encodeURIComponent(url)}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }); 
      setUrlData(response.data);
    } catch (err) {
      setError(err);
      toast({
        title: "Error",
        description: err.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const renderTable = (title, data) => (
    <Card
      marginY={4}
      color="blue.50"
      borderWidth={2}
      borderColor="black"
      borderRadius="md"
      width="100%"
      maxWidth={{ base: "100%", md: "50%" }}
      mx="auto"
    >
      <CardBody>
        <Heading size="md" color="blue.900" textAlign="center">{title}</Heading>
        <Box overflowX="auto">
          <Table variant="simple" size="sm" marginTop={4} bg="gray.100" width="100%">
            <Thead>
              <Tr>
                <Th textAlign="center" fontSize={{ base: "sm", md: "lg" }} fontWeight="bold" bg="blue.200" color="black">Key</Th>
                <Th textAlign="center" fontSize={{ base: "sm", md: "lg" }} fontWeight="bold" bg="blue.200" color="black">Value</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data}
            </Tbody>
          </Table>
        </Box>
      </CardBody>
    </Card>
  );

  const securityChecksRows = data?.['security_checks'] ? Object.entries(data['security_checks']).map(([key, value]) => (
    <Tr key={key}>
      <Td textAlign="center" fontFamily="Arial" fontSize="md" fontWeight="normal" p={3} color="black">{key}</Td>
      <Td textAlign="center" fontFamily="Arial" fontSize="md" fontWeight="normal" p={3} color={data['google_safebrowsing']['status'] === 'safe' ? 'green.500' : 'red.500'}>
        {value.toString()}
      </Td>
    </Tr>
  )) : [];

  const siteCategoryRows = data?.['site_category'] ? Object.entries(data['site_category']).map(([key, value]) => (
    <Tr key={key}>
      <Td textAlign="center" fontFamily="Arial" fontSize="md" fontWeight="normal" p={3} color="black">{key}</Td>
      <Td textAlign="center" fontFamily="Arial" fontSize="md" fontWeight="normal" p={3} color={data['google_safebrowsing']['status'] === 'safe' ? 'green.500' : 'red.500'}>
        {value.toString()}
      </Td>
    </Tr>
  )) : [];

  const serverDetailsRows = data?.['server_details'] ? Object.entries(data['server_details']).map(([key, value]) => (
    <Tr key={key}>
      <Td textAlign="center" fontFamily="Arial" fontSize="md" fontWeight="normal" p={3} color="black">{key}</Td>
      <Td textAlign="center" fontFamily="Arial" fontSize="md" fontWeight="normal" p={3} color={data['google_safebrowsing']['status'] === 'safe' ? 'green.500' : 'red.500'}>
        {value.toString()}
      </Td>
    </Tr>
  )) : [];

  return (
    <div>
         <div
    style={{
      position: 'fixed',                         // Fix the position of the navbar
      top: 0,                                    // Stick it to the top
      left: 0,                                   // Align it to the left edge
      width: '100%',                             // Full width for the navbar
      zIndex: 1000,                              // Ensure it's above other content
    }}
  >
   <UserNavbar /> 
  </div>
        
     <div>
        <Box
        height={{ base: '60vh', md: '60vh', lg: '60vh' }}
        backgroundImage={`url(${ImageOne})`} 
        backgroundSize="cover"
        backgroundPosition="center"
        position="relative"
      >
        <Center height="100%">
          <Box
            width={containerWidth}
            height={containerHeight}
            backgroundColor="rgba(0, 0, 0, 0.6)"
            color="white"
            borderRadius="20px"
            paddingX={paddingX}
            paddingY={{ base: '4', md: '6', lg: '8' }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            <Stack
              direction={{ base: 'column', md: 'row' }}
              spacing={{ base: '4', md: '6' }}
              align="center"
              justify="center"
              mb={6}
              wrap="wrap" // Ensure that items wrap if needed
              gap={{ base: '4', md: '6' }} // Responsive gap
            >
    
              {/* Normal Button */}
              <Button
                bg="transparent"
                color="white"
                borderRadius="md"
                _hover={{ bg: 'rgba(0, 0, 0, 0.3)' }}
                onClick={() => navigate('/companyinfo')}
              >
                <HStack spacing={2}>
                <Icon as={FaBuilding} />
                  <Text>Domain Risk Analysis</Text>
                </HStack>
              </Button>
        
    
              {/* Normal Button */}
        <Button
                bg="transparent"
                color="white"
                borderRadius="md"
                _hover={{ bg: 'rgba(0, 0, 0, 0.3)' }}
                onClick={() => navigate('/companysearch')}
              >
                <HStack spacing={2}>
                <Icon as={FaInfoCircle} />
                  <Text>Deep and Dark Search</Text>
                </HStack>
              </Button>

              
              {/* Dropdown Button 3 */}
              <Menu>
                <MenuButton
                  as={Button}
                  bg="transparent"
                  color="white"
                  borderRadius="md"
                  _hover={{ bg: 'rgba(0, 0, 0, 0.3)' }}
                  rightIcon={<FaChevronDown />}
                >
                  <HStack spacing={2}>
                    <Icon as={FaQuestionCircle} />
                    <Text>URL Analysis</Text>
                  </HStack>
                </MenuButton>
                <MenuList bg="black" color="white">
                  <MenuItem
                    bg="black"
                    _hover={{ bg: 'rgba(255, 255, 255, 0.2)' }}
                    onClick={() => navigate('/dkim')}
                  >
                    DKIM
                  </MenuItem>
                  <MenuItem
                    bg="black"
                    _hover={{ bg: 'rgba(255, 255, 255, 0.2)' }}
                    onClick={() => navigate('/securityheaders')}
                  >
                    Security Headers
                  </MenuItem>
                  <MenuItem
                    bg="black"
                    _hover={{ bg: 'rgba(255, 255, 255, 0.2)' }}
                    onClick={() => navigate('/sitetechnology')}
                  >
                    Site Technology
                  </MenuItem>
                </MenuList>
              </Menu>
    
              {/* Normal Button */}
              {/* <Button
                bg="transparent"
                color="white"
                borderRadius="md"
                _hover={{ bg: 'rgba(0, 0, 0, 0.3)' }}
                onClick={() => navigate('/url')}
              >
                <HStack spacing={2}>
                  <Icon as={FaLink} />
                  <Text>URL</Text>
                </HStack>
              </Button> */}
            </Stack>
    
            {/* Input and Search Button */}
            <VStack spacing={4} width={containerWidth} align="center">
              <Input
                placeholder="Company Name: 'https://www.XYZ.com/'"
                value={url}
          onChange={(e) => setUrl(e.target.value)}
                size="md"
                color="white"
                backgroundColor="rgba(255, 255, 255, 0.2)"
                borderRadius="md"
                _placeholder={{ color: 'white' }}
                border="1px solid white"
              />
              <Button
                colorScheme="teal"
                width={buttonWidth}
                borderRadius="20px"
                backgroundColor="rgba(0, 0, 0, 0.5)"
                _hover={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}
                onClick={handleSearch}
              >
                Search
                </Button>
                {/* <MainComponent domain={domain} onSuccess={fetchDkimData} /> */}
                </VStack>
              </Box>
            </Center>
          </Box>
        <br></br>
        <center>
                    {isLoading && <Spinner size="xl" />}
            
                    </center>


    {/* <VStack spacing={6} padding={6} bg="gray.50" align="stretch">
      <Flex
        direction={{ base: "column", md: "row" }}
        mb={4}
        p={4}
        bg="white"
        borderRadius="md"
        boxShadow="md"
        justify="center"
        wrap="wrap"
      >
        <Input
          placeholder="Enter URL"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          size="lg"
          marginRight={{ base: 0, md: 2 }}
          marginBottom={{ base: 2, md: 0 }}
          borderColor="teal.300"
          _focus={{ borderColor: 'teal.500' }}
          width={{ base: "100%", md: "auto" }}
        />
        <Button
          colorScheme="teal"
          onClick={handleSearch}
          isLoading={loading}
          loadingText="Fetching..."
          spinnerPlacement="end"
          size="lg"
          width={{ base: "100%", md: "auto" }}
        >
          Check Reputation
        </Button>
      </Flex>

      {loading && (
        <Box display="flex" justifyContent="center" p={4}>
          <Spinner size="lg" color="teal.500" />
        </Box>
      )}

      {error && (
        <Text color="red.500" textAlign="center">Error: {error}</Text>
      )} */}

      {data && (
        <>
          {renderTable(
            'URL Details',
            <>
              <Tr>
                <Td fontWeight="bold" color="black" textAlign="center">URL</Td>
                <Td textAlign="center" color={data['risk_score'] === 0 ? 'green.500' : 'red.500'}>
                  {data['url']}
                </Td>
              </Tr>
              <Tr>
                <Td fontWeight="bold" color="black" textAlign="center">Risk Score</Td>
                <Td textAlign="center" color={data['risk_score'] === 0 ? 'green.500' : 'red.500'}>
                  {data['risk_score'].toString()}
                </Td>
              </Tr>
            </>
          )}

          {renderTable(
            'Google Safe Browsing',
            <>
              <Tr>
                <Td fontWeight="bold" color="black" textAlign="center">URL</Td>
                <Td textAlign="center" color={data['google_safebrowsing']['status'] === 'safe' ? 'green.500' : 'red.500'}>
                  {data['google_safebrowsing']['url']}
                </Td>
              </Tr>
              <Tr>
                <Td fontWeight="bold" color="black" textAlign="center">Status</Td>
                <Td textAlign="center" color={data['google_safebrowsing']['status'] === 'safe' ? 'green.500' : 'red.500'}>
                  {data['google_safebrowsing']['status']}
                </Td>
              </Tr>
            </>
          )}

          {renderTable(
            'Security Checks',
            securityChecksRows
          )}

          {renderTable(
            'Server Details',
            serverDetailsRows
          )}

          {renderTable(
            'Site Category',
            siteCategoryRows
          )}
        </>
      )}

      <Text textAlign="center" fontStyle="italic" color="red.500">
        Disclaimer: Give the correct Syntax of the URL eg:"https://xyz.com/","https://www.XYZ.com/"
      </Text>
      <br></br>
         <MainpageFooter/>
            </div> 

        </div>
  );
}
