import React from 'react';
import {
  Box,
  Button,
  Divider,
  Heading,
  Flex,
  useColorModeValue,
  Grid,
  Text,
  Icon,
} from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { FiDownload } from 'react-icons/fi'; // Importing an icon for download
import UserNavbar from '../../auth/UserNavbar';
import MainpageFooter from '../../MainpageFooter';

const SslCertificateDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const certificates = location.state?.certificates || []; // Access the data passed in the route

  // Call useColorModeValue hooks outside of the map method
  const boxBgColor = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const hoverShadow = "2xl"; // This can be customized if needed

  // const downloadPDF = () => {
  //   const input = document.getElementById('pdf-content');

  //   html2canvas(input, {
  //     useCORS: true,
  //     logging: true,
  //     scale: 2, // Increase scale for better quality
  //     backgroundColor: '#fff' // Ensure a white background
  //   }).then((canvas) => {
  //     try {
  //       const imgData = canvas.toDataURL('image/png');
  //       const pdf = new jsPDF('p', 'mm', 'a4');
  //       const imgWidth = 210; // A4 width in mm
  //       const pageHeight = 295; // A4 height in mm
  //       const imgHeight = (canvas.height * imgWidth) / canvas.width;
  //       let heightLeft = imgHeight;

  //       let position = 0;

  //       // Add the first image
  //       pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
  //       heightLeft -= pageHeight;

  //       // Add additional pages as needed
  //       while (heightLeft >= 0) {
  //         position = heightLeft - imgHeight; // Set the position for the next page
  //         pdf.addPage(); // Add a new page
  //         pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
  //         heightLeft -= pageHeight; // Decrease the height left for the next page
  //       }

  //       pdf.save('ssl_certificate_details.pdf');
  //     } catch (error) {
  //       console.error("Error generating PDF: ", error);
  //       alert('There was an issue generating the PDF. Please try again.');
  //     }
  //   }).catch((error) => {
  //     console.error("Error capturing the content: ", error);
  //   });
  // };

  return (
    <div>
      <UserNavbar />
      <Box p={6} bg={useColorModeValue('gray.50', 'gray.800')} minH="100vh">
        <Box maxW="7xl" mx="auto" borderWidth="1px" borderRadius="md" boxShadow="lg" id="pdf-content" p={6}>
          {/* Flex container for the header and button */}
          <Flex justify="space-between" align="center" mb={4}>
            <Heading as="h1" size="lg" color="blue.900">
              SSL Certificate Details
            </Heading>
            {/* <Button colorScheme="teal" size="sm" onClick={downloadPDF} leftIcon={<Icon as={FiDownload} />}>
              Download as PDF+
            </Button> */}
          </Flex>
          <Divider mb={4} />

          {certificates.length > 0 ? (
            <Grid templateColumns={{ base: '1fr', sm: '1fr 1fr', md: 'repeat(3, 1fr)' }} gap={6}>
              {certificates.map((certificate, index) => (
                <Box
                  key={index}
                  borderWidth="1px"
                  borderRadius="md"
                  boxShadow={hoverShadow}
                  p={4}
                  bg={boxBgColor}
                  _hover={{ boxShadow: "2xl", transform: "scale(1.02)", transition: "all 0.3s" }}
                  borderColor={borderColor}
                >
                  <Text fontWeight="bold" fontSize="lg" color="blue.700">Issuer CA ID:</Text>
                  <Text fontSize="md">{certificate.issuer_ca_id}</Text>
                  <Text fontWeight="bold" fontSize="lg" color="blue.700">Serial Number:</Text>
                  <Text fontSize="md">{certificate.serial_number}</Text>
                  <Text fontWeight="bold" fontSize="lg" color="blue.700">Issuer Name:</Text>
                  <Text fontSize="md">{certificate.issuer_name}</Text>
                  <Text fontWeight="bold" fontSize="lg" color="blue.700">Common Name:</Text>
                  <Text fontSize="md">{certificate.common_name}</Text>
                  <Text fontWeight="bold" fontSize="lg" color="blue.700">Expiration Date:</Text>
                  <Text fontSize="md" color="red.500" fontWeight="bold">
                    {format(new Date(certificate.not_after), 'yyyy-MM-dd')}
                  </Text>
                </Box>
              ))}
            </Grid>
          ) : (
            <Box textAlign="center" p={4}>
              <Heading as="h3" size="md" color="gray.500">
                No Certificates Available
              </Heading>
            </Box>
          )}
        </Box>
      </Box>
      <MainpageFooter />
    </div>
  );
};

export default SslCertificateDetails;
