import React from 'react';
import {
  Box,
  Heading,
  Divider,
  SimpleGrid,
  Text,
  Stack,
  useColorModeValue,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import UserNavbar from '../../auth/UserNavbar';
import MainpageFooter from '../../MainpageFooter';

const DomainQualityScoreDetails = () => {
  const location = useLocation(); // Get location using the hook
  const data = location.state?.data || {}; // Get data passed from previous page

  // Debugging log
  console.log('Received data in DomainQualityScoreDetails:', data);

  // Extract domain keys and skip the first one (0 index)
  const domainKeys = Object.keys(data).slice(1); // Skip the first domain

  // Color mode values
  const backgroundColor = useColorModeValue("gray.50", "gray.800");
  const cardBackgroundColor = useColorModeValue("white", "gray.700");
  const headingColor = useColorModeValue("blue.600", "blue.300");
  const textColor = useColorModeValue("gray.600", "gray.200");
  const stextColor = useColorModeValue("green.600", "green.200");
  
  return (
    <div>
    <UserNavbar/>

    <Box p={6} backgroundColor={backgroundColor} mx="auto" mt={6} borderRadius="lg">
      <Heading size="lg" mb={4} textAlign="center" color={headingColor}>
        Domain Quality Scores
      </Heading>
      <Divider mb={6} />

      {/* Grid layout for cards */}
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
        {domainKeys.map((domain) => {
          const domainData = data[domain]?.data || {};
          const ipScore = domainData?.ip_score || {};

          return (
            <Box
              key={domain}
              p={4}
              borderWidth="1px"
              borderRadius="lg"
              boxShadow="lg"
              backgroundColor={cardBackgroundColor}
              transition="0.3s"
              _hover={{ boxShadow: 'xl', transform: 'scale(1.02)' }}
            >
              <Stack spacing={4}>
                <Heading size="md" color={headingColor} textAlign="center">{domain}</Heading>
                <Divider />
                <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                  <GridItem>
                    <Text fontWeight="bold" color={textColor}>IP Address:</Text>
                    <Text color={stextColor}>{ipScore?.ip_address || 'N/A'}</Text>
                  </GridItem>
                  <GridItem>
                    <Text fontWeight="bold" color={textColor}>Normalized IP Score:</Text>
                    <Text color={stextColor}>{ipScore?.normalized_ip_score?.toString() || 'N/A'}</Text>
                  </GridItem>
                  <GridItem>
                    <Text fontWeight="bold" color={textColor}>Attack Surface Score:</Text>
                    <Text color={stextColor}>{ipScore?.detailed_scores?.attack_surface?.toString() || 'N/A'}</Text>
                  </GridItem>
                  <GridItem>
                    <Text fontWeight="bold" color={textColor}>Encryption Score:</Text>
                    <Text color={stextColor}>{ipScore?.detailed_scores?.encryption?.toString() || 'N/A'}</Text>
                  </GridItem>
                  <GridItem>
                    <Text fontWeight="bold" color={textColor}>Open Ports:</Text>
                    <Text color={stextColor}>{ipScore?.results_detailed?.ports?.open?.length > 0 ? ipScore.results_detailed.ports.open.join(', ') : 'None'}</Text>
                  </GridItem>
                  <GridItem>
                    <Text fontWeight="bold" color={textColor}>Ports Score:</Text>
                    <Text color={stextColor}>{ipScore?.results_detailed?.ports?.score?.toString() || 'N/A'}</Text>
                  </GridItem>
                  <GridItem>
                    <Text fontWeight="bold" color={textColor}>Torrents Score:</Text>
                    <Text color={stextColor}>{ipScore?.results_detailed?.torrents?.score?.toString() || 'N/A'}</Text>
                  </GridItem>
                  <GridItem>
                    <Text fontWeight="bold" color={textColor}>Load Balancer:</Text>
                    <Text color={stextColor}>{ipScore?.results_detailed?.is_load_balancer?.toString() || 'N/A'}</Text>
                  </GridItem>
                </Grid>
              </Stack>
            </Box>
          );
        })}
      </SimpleGrid>
    </Box>
    <br></br> 
         <MainpageFooter/>
            </div> 
  );
};

export default DomainQualityScoreDetails;
