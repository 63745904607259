// import React, { useState } from 'react';
// import { Box, Button, FormControl, FormLabel, Input, Heading, Text, Spinner } from '@chakra-ui/react';
// import { sdkSignIn } from '../../authService';
// import { useNavigate } from 'react-router-dom';

// export const getToken = () => {
//   return sessionStorage.getItem('accessToken');
// };

// const LoginPage = () => {
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const [loading, setLoading] = useState(false);
//   const navigate = useNavigate();

//   const handleSignIn = async (e) => {
//     e.preventDefault();
//     setLoading(true);
//     try {
//       // Sign in the user using AWS SDK and get the tokens
//       const { accessToken } = await sdkSignIn(email, password);
      
//       // Store the access token and email in sessionStorage
//       sessionStorage.setItem('accessToken', accessToken);
//       sessionStorage.setItem('userEmail', email);

//       setLoading(false);
//       navigate('/userhome'); // Redirect on successful sign-in
//     } catch (error) {
//       setLoading(false);
//       alert(`Sign in failed: ${error.message || error}`);
//     }
//   };

//   return (
//     <Box maxWidth="400px" mx="auto" mt="10" p="6" boxShadow="lg" borderRadius="md" bg="white">
//       <Heading as="h1" mb="6" textAlign="center">Login</Heading>
//       <form onSubmit={handleSignIn}>
//         <FormControl id="email" mb="4" isRequired>
//           <FormLabel>Email</FormLabel>
//           <Input
//             type="email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             placeholder="Email"
//           />
//         </FormControl>
//         <FormControl id="password" mb="4" isRequired>
//           <FormLabel>Password</FormLabel>
//           <Input
//             type="password"
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//             placeholder="Password"
//           />
//         </FormControl>
//         <Button colorScheme="blue" width="full" type="submit" isLoading={loading}>
//           {loading ? <Spinner size="sm" /> : 'Sign In'}
//         </Button>
//       </form>
//       <Text mt={4} textAlign="center">
//         Don't have an account? <a href="/signup">Sign up</a>
//       </Text>
//     </Box>
//   );
// };

// export default LoginPage;

import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Heading,
  Text,
  Spinner,
} from '@chakra-ui/react';
import { sdkSignIn } from '../../authService'; // AWS SDK sign-in function
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

// This function will return the AWS token from sessionStorage
export const getToken = () => {
  return sessionStorage.getItem('awsAccessToken');
};
export const getapiToken = () => {
  return sessionStorage.getItem('apiAccessToken');
};

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Default to false

  // Effect to set authentication status and save it to localStorage once on mount
  useEffect(() => {
    const authState = localStorage.getItem('isAuthenticated');
    if (authState === 'true') {
      setIsAuthenticated(true);
    }
  }, []); // Empty dependency array ensures this only runs once on mount

  // Function to handle both SDK sign-in and API sign-in
  const handleSignIn = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      // 1. Sign in using AWS SDK
      const { accessToken: awsAccessToken } = await sdkSignIn(email, password);
      
      // 2. Sign in using API
      const apiResponse = await axios.post(
        'https://acdkl3bou7rzkbnsblthlui4du0bkezr.lambda-url.ap-south-1.on.aws/auth/token',
        {
          username: email,
          password,
        }
      );
      const apiAccessToken = apiResponse.data.access_token;

      // Store both tokens separately in sessionStorage
      sessionStorage.setItem('awsAccessToken', awsAccessToken); // AWS SDK token
      sessionStorage.setItem('apiAccessToken', apiAccessToken); // API token
      sessionStorage.setItem('userEmail', email);

      // Update authentication state
      setIsAuthenticated(true);
      localStorage.setItem('isAuthenticated', 'true'); // Save to localStorage

      // Redirect on successful sign-in
      setLoading(false);
      navigate('/userhome');
      window.location.reload(); // This ensures immediate logout
    } catch (err) {
      setLoading(false);
      setError(`Sign in failed: ${err.response?.data?.message || err.message}`);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSignIn(e); // Trigger sign-in when Enter key is pressed
    }
  };

  return (
    <Box maxWidth="400px" mx="auto" mt="10" p="6" boxShadow="lg" borderRadius="md" bg="white">
      <Heading as="h1" mb="6" textAlign="center">
        Login
      </Heading>
      <form onSubmit={handleSignIn} onKeyDown={handleKeyDown}>
        <FormControl id="email" mb="4" isRequired>
          <FormLabel>Email</FormLabel>
          <Input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
          />
        </FormControl>
        <FormControl id="password" mb="4" isRequired>
          <FormLabel>Password</FormLabel>
          <Input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
        </FormControl>
        {error && (
          <Text color="red.500" textAlign="center" mb={4}>
            {error}
          </Text>
        )}
        <Button colorScheme="blue" width="full" type="submit" isLoading={loading}>
          {loading ? <Spinner size="sm" /> : 'Sign In'}
        </Button>
      </form>
      <Text mt={4} textAlign="center">
        Don't have an account? <a href="/signup">Sign up</a>
      </Text>
    </Box>
  );
};

export default LoginPage;
