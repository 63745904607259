import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Button, Container, Grid, Heading, Table, Tbody, Td, Th, Thead, Tr, Text } from '@chakra-ui/react';
import UserNavbar from '../../auth/UserNavbar';
import MainpageFooter from '../../MainpageFooter';

const SubdomainDetails = () => {
  const { state } = useLocation();
  const { subdomains = [], wildcardSubdomains = [], foundEmails = [], resolvedIps = {} } = state || {};

  const renderTable = (title, data, headingColor) => (
    <Box p={4} borderWidth="1px" borderRadius="md" boxShadow="md" backgroundColor="white">
      <Heading as="h2" size="lg" textAlign="center" color={headingColor} mb={4}>
        {title}
      </Heading>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Value</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.length > 0 ? (
            data.map((item, index) => (
              <Tr key={index}>
                <Td>{item.toString()}</Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td>No Data Available</Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Box>
  );

  const renderResolvedIpsTable = (title, data, headingColor) => (
    <Box p={4} borderWidth="1px" borderRadius="md" boxShadow="md" backgroundColor="white">
      <Heading as="h2" size="lg" textAlign="center" color={headingColor} mb={4}>
        {title}
      </Heading>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Domain</Th>
            <Th>Resolved IP</Th>
          </Tr>
        </Thead>
        <Tbody>
          {Object.keys(data).length > 0 ? (
            Object.entries(data).map(([key, value], index) => (
              <Tr key={index}>
                <Td>{key}</Td>
                <Td>{value.toString()}</Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={2}>No Data Available</Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Box>
  );

  return (
    <div>
    <UserNavbar/>
    
 <div>
    <Container maxW="container.xl" p={4}>
      

      <Grid templateColumns="repeat(auto-fit, minmax(300px, 1fr))" gap={6}>
        {subdomains.length > 0 && renderTable("Subdomains", subdomains, '#003366')}
        {wildcardSubdomains.length > 0 && renderTable("Wildcard Subdomains", wildcardSubdomains, '#003366')}
        {foundEmails.length > 0 && renderTable("Found Emails", foundEmails, '#003366')}
        {Object.keys(resolvedIps).length > 0 && renderResolvedIpsTable("Resolved IPs", resolvedIps, '#003366')}
      </Grid>
    </Container>
    <br></br> 
         <MainpageFooter/>
            </div> 

        </div>
  );
};

export default SubdomainDetails;
