import React, { useEffect } from 'react';
import { Box, Flex, Heading, Image, Text, Button, VStack } from '@chakra-ui/react';
import MainImage from '../assets/images/Image1.webp';
import CompanyImage from '../assets/images/company.PNG';
import additionalServices from '../assets/images/additionalServices2.jpg';
import url from '../assets/images/url.PNG';
import otherServices from '../assets/images/other_services.PNG';
import InformationContainer from './subcomponent/InformationContainer';
import { FaPlay } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom';

const styles = {
  heading: (fontSize, color, pl, fontWeight, mb, fontFamily) => ({
    fontSize,
    color,
    paddingLeft: pl,
    fontWeight,
    marginBottom: mb,
    fontFamily,
  }),
  text: (fontSize, lineHeight, color, mb, fontFamily) => ({
    fontSize,
    lineHeight,
    color,
    marginBottom: mb,
    fontFamily,
  }),
  button: (width, fontSize, fontWeight, color, bg, borderRadius, borderColor) => ({
    width: width || 'auto',
    padding: '12px 24px',
    fontSize: fontSize || '16px',
    fontWeight,
    color,
    background: bg,
    borderRadius: borderRadius || '8px',
    border: `2px solid ${borderColor}`,
    cursor: 'pointer',
    textAlign: 'center',
    transition: 'background-color 0.3s, color 0.3s, transform 0.3s',
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.3)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    _hover: { backgroundColor: borderColor, color: 'white' },
    _active: { transform: 'scale(0.98)' },
  }),
  container: (bg) => ({
    align: 'center',
    justify: 'space-between',
    mt: '50px',
    p: '20px',
    bg,
    borderRadius: '10px',
    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
    maxWidth: '1200px',
    mx: 'auto',
    flexDirection: { base: 'column', md: 'row' },
  }),
};

function MainpageSubContainer() {
  const navigate = useNavigate();
  const location = useLocation();

  const getStartClick = () => {
    navigate('/userhome');
  };

  useEffect(() => {
    if (location.state && location.state.sectionId) {
      const section = document.getElementById(location.state.sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location.state]);

  return (
    <Box css={{ overflow: 'auto' }}>
      {/* Main Image */}
      <Flex justify="center" align="center" width="100%">
        <Image src={MainImage} alt="Description" width="100%" height={{ base: '300px', md: '600px' }} objectFit="cover" />
      </Flex>

      {/* Intro Section */}
      <Flex justify="center" align="center" width="100%" height="30vh" color="black" py="40px">
        <Box textAlign="center">
          <Heading as="h2" fontWeight="bold" fontFamily="LibreBaskerville" fontSize={{ base: '24px', md: '30px' }} paddingTop="10px">
            Our Platform and service are designed to protect your digital presence.
          </Heading>
          <Text fontFamily="LibreBaskerville" fontSize={{ base: '16px', md: '20px' }} textAlign="center">
            Explore the product offerings below to see how we can help secure your information and safeguard against threats.
          </Text>
          <Button
            background="white"
            color="#6e8efb"
            padding="12px 30px"
            borderRadius="8px"
            fontSize="18px"
            fontWeight="bold"
            boxShadow="0px 10px 20px rgba(0, 0, 0, 0.2)"
            marginTop="20px"
            _hover={{
              transform: 'translateY(-5px)',
              boxShadow: '0px 15px 25px rgba(0, 0, 0, 0.3)',
            }}
            _active={{
              transform: 'translateY(0)',
              boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.2)',
            }}
            onClick={getStartClick}
          >
            Get Started
          </Button>
        </Box>
      </Flex>

      {/* INFORMATION CONTAINER */}
      <InformationContainer />

      {/* CONTAINER 1: Company */}
      <Flex id="company" {...styles.container('#f0f8ff')} align="center" p="20px">
        <Box flex="1" mr={{ base: '0', md: '20px' }} mb={{ base: '20px', md: '0' }}>
          <Box
            overflow="hidden"
            borderRadius="10px"
            transition="transform 0.3s ease"
            _hover={{ transform: 'scale(1.05)' }}
          >
            <Image src={CompanyImage} alt="Company Services" width="100%" height="auto" borderRadius="10px" />
          </Box>
        </Box>
        <Box flex="2" ml="20px">
          <Heading {...styles.heading('24px', '#1e3cd4', '30%', 'bold', '15px', 'serif')}>
            Company
          </Heading>
          <VStack align="start" spacing="10px" mt="20px">
            {[
              { title: 'Domain Security', description: 'Ensure the safety and integrity of your domain with our advanced security measures.' },
              { title: 'DNS Protection', description: 'Guard your Domain Name System against potential threats and vulnerabilities.' },
              { title: 'Email Security', description: 'Protect your company\'s email infrastructure from phishing, spam, and other malicious activities.' },
              { title: 'Deep and Dark Data Monitoring', description: 'Monitor company data across the deep and dark web to detect and mitigate data breaches.' },
              { title: 'Domain Leaks', description: 'Identify and address leaks of sensitive domain information.' },
              { title: 'Domain/IP Quality Score', description: 'Assess the quality and reputation of domains and IP addresses to prevent fraudulent activities.' },
            ].map((item, index) => (
              <Box key={index}>
                <Heading {...styles.heading('20px', '#66bb6a', null, 'bold', null, 'Forum')}>
                  {item.title}
                </Heading>
                <Text {...styles.text('18px', '1.5', '#333', '20px', 'Forum')}>
                  {item.description}
                </Text>
              </Box>
            ))}
          </VStack>
          <Flex justify="center" mt="20px">
            <Button {...styles.button('30%', '16px', 'bold', '#1C3D5A', 'white', '0px', '#1C3D5A')} onClick={() => navigate('/companyinfo')}>
              <FaPlay style={{ marginRight: '8px' }} />
              Go
            </Button>
          </Flex>
        </Box>
      </Flex>

      {/* CONTAINER 2: Additional Services */}
      <Flex id="additionalServices" {...styles.container('#f0f8ff')} flexDirection={{ base: 'column-reverse', md: 'row' }}>
        <Box flex="2" mr={{ base: '0', md: '20px' }} ml={{ base: '0', md: '20px' }} mt={{ base: '20px', md: '0' }}>
          <Heading {...styles.heading('24px', '#1e3cd4', '30%', 'bold', '15px', 'serif')}>
            Additional Services
          </Heading>
          <VStack align="start" spacing="10px">
            {[
              { title: 'Phishing Simulation', description: 'Conduct simulated phishing attacks to test and improve your organization\'s readiness.' },
              { title: 'Automated Penetration Testing', description: 'Automatically identify and exploit vulnerabilities in your system to strengthen defenses.' },
              { title: 'Code Review', description: 'Perform thorough code reviews to detect and fix security flaws in your software.' },
            ].map((item, index) => (
              <Box key={index}>
                <Heading {...styles.heading('20px', '#66bb6a', null, 'bold', null, 'Forum')}>
                  {item.title}
                </Heading>
                <Text {...styles.text('18px', '1.5', '#333', '20px', 'Forum')}>
                  {item.description}
                </Text>
              </Box>
            ))}
          </VStack>
          <Flex justify="center" mt="20px">
            <Button {...styles.button('30%', '16px', 'bold', '#1C3D5A', 'white', '0px', '#1C3D5A')} onClick={() => navigate('/otherServices')}>
              <FaPlay style={{ marginRight: '8px' }} />
              Go
            </Button>
          </Flex>
        </Box>
        <Box flex="1" mb={{ base: '20px', md: '0' }}>
          <Box overflow="hidden" borderRadius="10px" transition="transform 0.3s ease" _hover={{ transform: 'scale(1.05)' }}>
            <Image src={additionalServices} alt="Additional Services" width="100%" height="auto" borderRadius="10px" />
          </Box>
        </Box>
      </Flex>

      {/* CONTAINER 3: URL Analysis */}
      <Flex id="url" {...styles.container('#f0f8ff')} align="center" p="20px">
        <Box flex="1" mr={{ base: '0', md: '20px' }} mb={{ base: '20px', md: '0' }}>
          <Box overflow="hidden" borderRadius="10px" transition="transform 0.3s ease" _hover={{ transform: 'scale(1.05)' }}>
            <Image src={url} alt="URL Analysis" width="100%" height="auto" borderRadius="10px" />
          </Box>
        </Box>
        <Box flex="2" ml="20px">
          <Heading {...styles.heading('24px', '#1e3cd4', '30%', 'bold', '15px', 'serif')}>
            URL Analysis
          </Heading>
          <VStack align="start" spacing="10px" mt="20px">
            {[
              { title: 'URL Score', description: 'Instantly assess the safety and reliability of any URL.' },
              { title: 'External and Internal URL Analysis', description: 'Identify broken links and improve user experience.' },
              { title: 'Speed Analysis', description: 'Diagnose website performance bottlenecks.' },
            ].map((item, index) => (
              <Box key={index}>
                <Heading {...styles.heading('20px', '#66bb6a', null, 'bold', null, 'Forum')}>
                  {item.title}
                </Heading>
                <Text {...styles.text('18px', '1.5', '#333', '20px', 'Forum')}>
                  {item.description}
                </Text>
              </Box>
            ))}
          </VStack>
          <Flex justify="center" mt="20px">
            <Button {...styles.button('30%', '16px', 'bold', '#1C3D5A', 'white', '0px', '#1C3D5A')} onClick={() => navigate('/url')}>
              <FaPlay style={{ marginRight: '8px' }} />
              Go
            </Button>
          </Flex>
        </Box>
      </Flex>

      {/* CONTAINER 4: Other Services */}
      <Flex id="otherServices" {...styles.container('#f0f8ff')} flexDirection={{ base: 'column-reverse', md: 'row' }}>
        <Box flex="2" mr={{ base: '0', md: '20px' }} ml={{ base: '0', md: '20px' }} mt={{ base: '20px', md: '0' }}>
          <Heading {...styles.heading('24px', '#1e3cd4', '30%', 'bold', '15px', 'serif')}>
            Other Services
          </Heading>
          <VStack align="start" spacing="10px">
            {[
              { title: 'Web Application Security', description: 'Securing your web applications against common vulnerabilities.' },
              { title: 'Mobile App Security', description: 'Ensuring the security of mobile applications to protect user data.' },
              { title: 'Security Awareness Training', description: 'Training your team to recognize and respond to security threats.' },
            ].map((item, index) => (
              <Box key={index}>
                <Heading {...styles.heading('20px', '#66bb6a', null, 'bold', null, 'Forum')}>
                  {item.title}
                </Heading>
                <Text {...styles.text('18px', '1.5', '#333', '20px', 'Forum')}>
                  {item.description}
                </Text>
              </Box>
            ))}
          </VStack>
          <Flex justify="center" mt="20px">
            <Button {...styles.button('30%', '16px', 'bold', '#1C3D5A', 'white', '0px', '#1C3D5A')} onClick={() => navigate('/additionalServices')}>
              <FaPlay style={{ marginRight: '8px' }} />
              Go
            </Button>
          </Flex>
        </Box>
        <Box flex="1" mb={{ base: '20px', md: '0' }}>
          <Box overflow="hidden" borderRadius="10px" transition="transform 0.3s ease" _hover={{ transform: 'scale(1.05)' }}>
            <Image src={otherServices} alt="Other Services" width="100%" height="auto" borderRadius="10px" />
          </Box>
        </Box>
      </Flex>
    </Box>
  );
}

export default MainpageSubContainer;
