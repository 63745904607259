import React from 'react';
import { Box, SimpleGrid, Flex, Image, Heading, Text, Stack, Icon, Divider, Link as ChakraLink } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { FaLinkedin, FaGithub, FaTwitter } from 'react-icons/fa';
import logo from '../assets/images/logo.png';

function Footer() {
    return (
        <Box bg="gray.900" color="gray.300" py={10} px={{ base: 5, md: 20 }}>
            {/* Footer Links Section */}
            <SimpleGrid
                columns={{ base: 1, sm: 2, md: 3, lg: 5 }}
                spacing={10}
                textAlign={{ base: 'center', md: 'left' }}
                mb={10}
            >
                {/* COMPANY */}
                <Box>
                    <Heading size="md" mb={4} fontFamily="Playfair Display" color="white">COMPANY</Heading>
                    <Stack spacing={2} fontFamily="Lato">
                        <ChakraLink as={Link} to="/">Home</ChakraLink>
                        <ChakraLink as={Link} to="/about">About Us</ChakraLink>
                        <ChakraLink as={Link} to="/features">Features</ChakraLink>
                        <ChakraLink as={Link} to="/pricing">Pricing</ChakraLink>
                        <ChakraLink as={Link} to="/contact">Contact Us</ChakraLink>
                    </Stack>
                </Box>

                {/* Deep and Dark Scan */}
                <Box>
                    <Heading size="md" mb={4} fontFamily="Playfair Display" color="white">Deep and Dark Scan</Heading>
                    <Stack spacing={2} fontFamily="Lato">
                        <ChakraLink as={Link} to="/informationcontent">Domain Security</ChakraLink>
                        <ChakraLink as={Link} to="/informationcontent">Email Security</ChakraLink>
                        <ChakraLink as={Link} to="/informationcontent">Subdomain & SSL records Scan</ChakraLink>
                        <ChakraLink as={Link} to="/informationcontent">Company Deep & Dark Analysis</ChakraLink>
                        <ChakraLink as={Link} to="/informationcontent">URL Analysis</ChakraLink>
                    </Stack>
                </Box>

                {/* ADDITIONAL SERVICES */}
                <Box>
                    <Heading size="md" mb={4} fontFamily="Playfair Display" color="white">Additional Services</Heading>
                    <Stack spacing={2} fontFamily="Lato">
                        <ChakraLink as={Link} to="/additionalServices">Site Technology</ChakraLink>
                        <ChakraLink as={Link} to="/additionalServices">DKIM</ChakraLink>
                        <ChakraLink as={Link} to="/additionalServices">Security Headers</ChakraLink>
                        <ChakraLink as={Link} to="/additionalServices">Squatting & Homoglyphs</ChakraLink>
                    </Stack>
                </Box>

                {/* OTHER SERVICES */}
                <Box>
                    <Heading size="md" mb={4} fontFamily="Playfair Display" color="white">Other Services</Heading>
                    <Stack spacing={2} fontFamily="Lato">
                        <ChakraLink as={Link} to="/otherServices">Phishing Simulation</ChakraLink>
                        <ChakraLink as={Link} to="/otherServices">Automated VAPT</ChakraLink>
                        <ChakraLink as={Link} to="/otherServices">Business Logic Testing</ChakraLink>
                        <ChakraLink as={Link} to="/otherServices">API Integration</ChakraLink>
                    </Stack>
                </Box>

                {/* URL SEARCH */}
                <Box>
                    <Heading size="md" mb={4} fontFamily="Playfair Display" color="white">URL Search</Heading>
                    <Stack spacing={2} fontFamily="Lato">
                        <ChakraLink as={Link} to="/informationcontent">Find By URL</ChakraLink>
                    </Stack>
                </Box>
            </SimpleGrid>

            {/* Divider */}
            <Divider borderColor="gray.600" my={8} />

            {/* Footer Bottom Section */}
      
    <Flex
        width="100%"
        alignItems="center"
        flexDirection={{ base: "column", md: "row" }}
        justify="space-between"
        textAlign={{ base: "center", md: "left" }}
    >
        {/* Logo Section */}
        <Link to="/">
            <Image
                height={{ base: "80px", md: "80px" }}
                width={{ base: "100px", md: "150px" }}
                src={logo}
                alt="Logo"
                marginBottom={{ base: "20px", md: "0" }}
                paddingLeft={{ base: "0", md: "50px" }}
            />
        </Link>

        {/* Terms and Rights Section */}
        <Box
            flex="1"
            textAlign="center"
            paddingLeft={{ base: "0", md: "20px" }}
            paddingRight={{ base: "0", md: "20px" }}
        >
            <Text mt={6} fontSize="sm" color="gray.500">
                <ChakraLink href="/termsofServices" _hover={{ color: 'gray.400' }}>Terms of Services</ChakraLink> | 
                <ChakraLink href="/privacyPolicy" _hover={{ color: 'gray.400' }}>Privacy Policy</ChakraLink>
            </Text>
            <Text fontSize="xs" color="gray.500" mt={2}>
                © 2024 SG2 Technologies | All Rights Reserved
            </Text>
        </Box>

        {/* Social Media Icons */}
        <Stack direction="row" spacing={6} paddingRight={{ base: "0", md: "50px" }}>
            <Link href="https://www.twitter.com">
                <Icon as={FaTwitter}
                    color="white"
                    _hover={{ color: 'gray.600' }}
                    boxSize="40px" />
            </Link>
            <Link href="https://www.linkedin.com">
                <Icon as={FaLinkedin}
                    color="white"
                    _hover={{ color: 'gray.600' }}
                    boxSize="40px" />
            </Link>
            <Link href="https://www.github.com">
                <Icon as={FaGithub}
                    color="white"
                    _hover={{ color: 'gray.600' }}
                    boxSize="40px" />
            </Link>
        </Stack>
    </Flex>
</Box>

    );
}

export default Footer;
