import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Button, Container, FormControl, FormLabel, Input, Stack, Heading, Text } from '@chakra-ui/react';
import { confirmSignUp, resendConfirmationCode } from '../../authService'; // Ensure correct path to authService

const ConfirmUserPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [email] = useState(location.state?.email || ''); // Use email as username
  const [confirmationCode, setConfirmationCode] = useState('');
  const [error, setError] = useState('');

  // Handle submitting the confirmation code
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await confirmSignUp(email, confirmationCode); // Use email for confirmation
      alert('Account confirmed successfully! Sign in on the next page.');
      navigate('/userhome');
    } catch (err) {
      setError(`Failed to confirm account: ${err.message}`);
    }
  };

  // Handle resending the confirmation code
  const handleResendCode = async () => {
    try {
      await resendConfirmationCode(email); // Use email to resend the code
      alert('A new confirmation code has been sent to your email.');
    } catch (err) {
      setError(`Failed to resend confirmation code: ${err.message}`);
    }
  };

  return (
    <Container maxW="md" p={6}>
      <Box p={8} borderWidth={1} borderRadius="lg" boxShadow="lg">
        <Heading as="h1" mb={6}>Confirm Your Account</Heading>
        <form onSubmit={handleSubmit}>
          <Stack spacing={4}>
            <FormControl id="confirmationCode" isRequired>
              <FormLabel>Confirmation Code</FormLabel>
              <Input
                type="text"
                value={confirmationCode}
                onChange={(e) => setConfirmationCode(e.target.value)}
                placeholder="Confirmation Code"
              />
            </FormControl>
            {error && (
              <Text color="red.500">{error}</Text>
            )}
            <Button type="submit" colorScheme="teal">Confirm Account</Button>
            <Button colorScheme="gray" onClick={handleResendCode}>Resend Code</Button>
          </Stack>
        </form>
      </Box>
    </Container>
  );
};

export default ConfirmUserPage;
