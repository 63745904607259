import React, { useEffect } from 'react';
import {
    Box,
    Heading,
    Text,
    SimpleGrid,
    Container,
    Image,
    Flex,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { ChevronRightIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import keyBenefits from '../../assets/images/keyBenefit.avif';
import indepth from '../../assets/images/indepth.avif';

const MotionBox = motion(Box);

const KeyBenefits = () => {
    const animationVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
    };

    // Scroll to top when the component mounts
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <Box flex={1} textAlign={['center', 'center', 'left']}>

                <Container maxW="7xl" mt={[5, 10]}>
                    {/* Key Benefits Section */}
                    <Box flex={1} textAlign={['center', 'center', 'left']}>
                        <MotionBox
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.5 }}
                            variants={animationVariants}
                            mb={5}
                            bg="gray.50"  // Background color for Key Benefits section
                            p={8}
                            borderRadius="lg"
                            boxShadow="lg"
                        >
                            <Flex
                                direction={['column', 'column', 'row']}
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Box flex="0 0 25%" mt={[10, 0]}>
                                    <Image
                                        src={keyBenefits}
                                        alt="Cybersecurity illustration"
                                        width="100%"
                                        borderRadius="lg"
                                    />
                                </Box>
                                <Box flex="1" textAlign={['center', 'center', 'left']} pl={[0, 0, 10]}>
                                    <Heading as="h2" size="xl" mb={8}>
                                        Key Benefits of Using Our Platform
                                    </Heading>
                                    <SimpleGrid columns={[1, 1, 2]} spacing={10}>
                                        {[
                                            {
                                                icon: <ChevronRightIcon boxSize={5} color="teal.500" />,
                                                heading: 'Comprehensive Visibility',
                                                text: 'Gain full insight into your external attack surface, enabling proactive threat management.',
                                            },
                                            {
                                                icon: <ChevronRightIcon boxSize={5} color="teal.500" />,
                                                heading: 'Proactive Threat Detection',
                                                text: 'Identify vulnerabilities and potential attack vectors before they are exploited.',
                                            },
                                            {
                                                icon: <ChevronRightIcon boxSize={5} color="teal.500" />,
                                                heading: 'Enhanced Brand Protection',
                                                text: 'Continuous monitoring to prevent domain squatting, phishing, and other brand impersonation attacks.',
                                            },
                                            {
                                                icon: <ChevronRightIcon boxSize={5} color="teal.500" />,
                                                heading: 'Data Leak Prevention',
                                                text: 'Alerts on exposed emails, URLs, and domains reduce the risk of unauthorized access.',
                                            },
                                            {
                                                icon: <ChevronRightIcon boxSize={5} color="teal.500" />,
                                                heading: 'Deep & Dark Web Insights',
                                                text: 'Regular scanning of these hidden areas allows you to respond quickly to any data exposure.',
                                            },
                                            {
                                                icon: <ChevronRightIcon boxSize={5} color="teal.500" />,
                                                heading: 'Actionable Reports',
                                                text: 'Detailed analysis with clear recommendations helps you prioritize and respond to threats effectively.',
                                            },
                                        ].map((item, index) => (
                                            <Box key={index} mb={5} display="flex" alignItems="flex-start">
                                                {item.icon}
                                                <Box as="span" ml={3}>
                                                    <Heading as="h3" size="md" mb={2}>
                                                        {item.heading}
                                                    </Heading>
                                                    <Text>
                                                        {item.text}
                                                    </Text>
                                                </Box>
                                            </Box>
                                        ))}
                                    </SimpleGrid>
                                </Box>
                            </Flex>
                        </MotionBox>
                    </Box>

                    {/* In-Depth Analysis Section */}
                    <MotionBox
                        initial="hidden"
                        animate="visible"
                        variants={animationVariants}
                        py={[10, 20]}
                        mt={[10, 20]}
                        borderRadius="lg"
                        boxShadow="lg"
                        width="100%"
                        maxW="100%"
                        px={[5, 10]}
                        bg="gray.100"  // Background color for In-Depth Analysis section
                    >
                        <Flex
                            direction={['column', 'column', 'row']}
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Box flex="0 0 65%" textAlign={['center', 'center', 'left']} pr={[0, 0, 10]}>
                                <Heading as="h2" size="xl" mb={8}>
                                    In-Depth Analysis of Our Services
                                </Heading>
                                <SimpleGrid columns={[1, 1, 2]} spacing={10}>
                                    {[
                                        {
                                            icon: <InfoOutlineIcon boxSize={5} color="teal.500" />,
                                            heading: 'DNS & Whois History Analysis',
                                            text: 'Monitor historical changes in domain ownership and contact details to detect anomalies, potential takeovers, or hijacking attempts.',
                                        },
                                        {
                                            icon: <InfoOutlineIcon boxSize={5} color="teal.500" />,
                                            heading: 'Email Security',
                                            text: 'Audit your domain’s DNS records, including SPF, DKIM, and DMARC, ensuring they are correctly configured to prevent email-based attacks.',
                                        },
                                        {
                                            icon: <InfoOutlineIcon boxSize={5} color="teal.500" />,
                                            heading: 'Brand Protection',
                                            text: 'Identify and take action against domain squatting, homograph attacks, and similar tactics used to impersonate or hijack your brand.',
                                        },
                                        {
                                            icon: <InfoOutlineIcon boxSize={5} color="teal.500" />,
                                            heading: 'Subdomain & SSL Certificate Enumeration',
                                            text: 'Uncover potentially vulnerable subdomains and expired or misconfigured SSL certificates that could expose sensitive information.',
                                        },
                                        {
                                            icon: <InfoOutlineIcon boxSize={5} color="teal.500" />,
                                            heading: 'Domain, URL & IP Leak Detection',
                                            text: 'Scan blacklists and threat databases to find out if your digital assets are listed, which could indicate prior or ongoing compromise.',
                                        },
                                        {
                                            icon: <InfoOutlineIcon boxSize={5} color="teal.500" />,
                                            heading: 'Email Leaks',
                                            text: 'Monitor for leaked credentials associated with your domain to prevent unauthorized access and secure your communication channels.',
                                        },
                                        {
                                            icon: <InfoOutlineIcon boxSize={5} color="teal.500" />,
                                            heading: 'Deep & Dark Web Analysis',
                                            text: 'Regularly check forums, marketplaces, and other hidden corners of the internet where compromised data and intellectual property may be traded.',
                                        },
                                    ].map((item, index) => (
                                        <Box key={index} mb={5} display="flex" alignItems="flex-start">
                                            {item.icon}
                                            <Box as="span" ml={3}>
                                                <Heading as="h3" size="md" mb={2}>
                                                    {item.heading}
                                                </Heading>
                                                <Text>
                                                    {item.text}
                                                </Text>
                                            </Box>
                                        </Box>
                                    ))}
                                </SimpleGrid>
                            </Box>
                            <Box flex="0 0 25%" mt={[10, 0]}>
                                <Image
                                    src={indepth}
                                    alt="Cybersecurity illustration"
                                    width="100%"
                                    height="100%"
                                    borderRadius="lg"
                                />
                            </Box>
                        </Flex>
                    </MotionBox>
                </Container>
            </Box>
        </div>
    );
};

export default KeyBenefits;
