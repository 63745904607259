import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Image,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  useToast,
} from '@chakra-ui/react';
import UserNavbar from '../auth/UserNavbar';
import MainpageFooter from '../MainpageFooter';

const CompanySearchResult = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const results = location.state?.data || []; // Safely accessing results

    // Load results from sessionStorage if available
    useEffect(() => {
      const checkAuthentication = () => {
        const accessToken = sessionStorage.getItem('accessToken'); // Access token should be stored in session storage
  
        if (!accessToken) {
          navigate('/signin'); // Redirect to sign-in page if no access token
          return;
        }
      };
  
      checkAuthentication();
    }, [navigate]);

  const handleLogoClick = () => {
    navigate('/');
  };

  const launchURL = (url) => {
    if (url) {
      window.open(url.trim(), '_blank').focus();
    } else {
      toast({
        title: 'Error',
        description: `Failed to open URL: ${url}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <div>
     <div
    style={{
      position: 'fixed',                         // Fix the position of the navbar
      top: 0,                                    // Stick it to the top
      left: 0,                                   // Align it to the left edge
      width: '100%',                             // Full width for the navbar
      zIndex: 1000,                              // Ensure it's above other content
    }}
  >
   <UserNavbar /> 
  </div>
    
 <div>
    <Container maxW="container.xl">
     

      {results.length > 0 ? (
        results.map((result, index) => {
          const searchType = result.searchType || 'N/A';
          const resultsList = Array.isArray(result.originalJson?.Results)
            ? result.originalJson.Results
            : []; // Ensure resultsList is an array

          return (
            <Box key={index} my={8}>
              <Heading as="h3" size="lg" color="red.500">
                {searchType}
              </Heading>

              <Box mt={4}>
                {resultsList.length > 0 ? (
                  <Box overflowX="auto">
                    <Table variant="striped" colorScheme="blue">
                      <Thead>
                        <Tr>
                          <Th>Title</Th>
                          <Th>Url</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {resultsList.map((resultItem, idx) => {
                          let url = 'N/A';
                          let title = 'N/A';

                          if (typeof resultItem === 'string') {
                            url = resultItem;
                          } else if (Array.isArray(resultItem) && resultItem.length >= 2) {
                            const details = resultItem[1];
                            if (typeof details === 'string') {
                              url = details;
                            } else {
                              url = details.Url || 'N/A';
                              title = details.Title || 'N/A';
                            }
                          }

                          return (
                            <Tr key={idx}>
                              <Td>{title}</Td>
                              <Td>
                                <Button variant="link" colorScheme="blue" onClick={() => launchURL(url)}>
                                  {url}
                                </Button>
                              </Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                  </Box>
                ) : (
                  <Text>No results</Text>
                )}
              </Box>
            </Box>
          );
        })
      ) : (
        <Text>No search results available</Text>
      )}
    </Container>
    <br></br>
         <MainpageFooter/>
            </div> 

        </div>
  );
};

export default CompanySearchResult;
