import React, { useState } from 'react';
import { Box, Heading, Text, Button, Stack, Checkbox, useBreakpointValue, Link } from '@chakra-ui/react';

import MainpageFooter from './MainpageFooter';

export default function TermsAndConditions() {
    // State to track checkbox selection
    const [isAgreeChecked, setIsAgreeChecked] = useState(false);


    // Responsive container width
    const containerWidth = useBreakpointValue({ base: '90%', md: '80%', lg: '70%' });

    // Handle checkbox selection logic
    const handleAgreeChange = (event) => {
        const isChecked = event.target.checked;
        setIsAgreeChecked(isChecked);

    };

    return (
        <div>
            
            <Box
                p={8}
                bg="black"
                color="white"
                minHeight="100vh"
                textAlign="center"
            >
                <Box
                    maxW={containerWidth}
                    mx="auto"
                    p={8}
                    bg="gray.800"
                    borderRadius="lg"
                    shadow="xl"
                >
                    <Heading as="h1" size="2xl" mb={8} color="gray.100">
                        Terms of Service
                    </Heading>

                    <Stack spacing={10} textAlign="left">
                        <Box>
                            <Heading as="h2" size="lg" mb={4} color="gray.300">
                                1. Introduction
                            </Heading>
                            <Text fontSize="md">
                                Welcome to Pentity ("Service"). These Terms of Service ("Terms") govern your access to and use of our Service. By accessing or using the Service, you agree to comply with and be bound by these Terms. If you do not agree to these Terms, do not use the Service.
                            </Text>
                        </Box>

                        <Box>
                            <Heading as="h2" size="lg" mb={4} color="gray.300">
                                2. Pricing and Payment Terms
                            </Heading>
                            <Box mb={6}>
                                <Heading as="h3" size="md" mb={2} color="gray.400">
                                    2.1 Pricing
                                </Heading>
                                <Text fontSize="md">
                                    The prices for our Service are listed on our website and may vary depending on the selected plan (e.g., Free, Silver, Gold). All prices are subject to change at our discretion. We will notify you of any price changes in advance.
                                </Text>
                            </Box>
                            <Box mb={6}>
                                <Heading as="h3" size="md" mb={2} color="gray.400">
                                    2.2 Payment Terms
                                </Heading>
                                <Text fontSize="md">
                                    Payment is due at the time of purchase or subscription activation. You authorize us to charge your selected payment method (credit card, Debit Card, etc.) for any fees associated with your use of the Service. All payments must be made in USD and are non-refundable, except as otherwise expressly provided in these Terms.
                                </Text>
                            </Box>
                            <Box mb={6}>
                                <Heading as="h3" size="md" mb={2} color="gray.400">
                                    2.3 Billing Cycle
                                </Heading>
                                <Text fontSize="md">
                                    Subscriptions will be billed in advance on a [monthly/annual] basis as indicated at the time of purchase. If a payment is not processed successfully, we reserve the right to suspend or terminate your access to the Service until payment is received.
                                </Text>
                            </Box>
                            <Box mb={6}>
                                <Heading as="h3" size="md" mb={2} color="gray.400">
                                    2.4 Taxes
                                </Heading>
                                <Text fontSize="md">
                                    Prices do not include applicable taxes. You are responsible for paying any taxes (including VAT or other sales taxes) imposed by applicable laws.
                                </Text>
                            </Box>
                        </Box>

                        <Box>
                            <Heading as="h2" size="lg" mb={4} color="gray.300">
                                3. Refund Policy
                            </Heading>
                            <Box mb={6}>
                                <Heading as="h3" size="md" mb={2} color="gray.400">
                                    3.1 General Refund Policy
                                </Heading>
                                <Text fontSize="md">
                                    We offer refunds only in limited circumstances as described in this section. Refund requests must be made within 5 of the original purchase date.
                                </Text>
                            </Box>
                            <Box mb={6}>
                                <Heading as="h3" size="md" mb={2} color="gray.400">
                                    3.2 Eligible Refunds
                                </Heading>
                                <Text fontSize="md">
                                    <Text as="b">Technical Issues:</Text> If the Service has technical problems that prevent you from using the Service as described, and we are unable to resolve the issue within a reasonable time, you may be eligible for a refund.
                                </Text>
                                <Text fontSize="md">
                                    <Text as="b">Subscription Cancellations:</Text> Refunds are not available for partially used subscription periods. If you cancel your subscription, you will retain access to the Service until the end of the current billing cycle.
                                </Text>
                            </Box>
                            <Box mb={6}>
                                <Heading as="h3" size="md" mb={2} color="gray.400">
                                    3.3 Non-Eligible Refunds
                                </Heading>
                                <Text fontSize="md">
                                    Refunds are not available for changes in personal circumstances, including accidental purchases, mistaken subscriptions, or lack of use.
                                </Text>
                            </Box>
                        </Box>

                        <Box>
                            <Heading as="h2" size="lg" mb={4} color="gray.300">
                                4. Subscription Cancellations and Downgrades
                            </Heading>
                            <Box mb={6}>
                                <Heading as="h3" size="md" mb={2} color="gray.400">
                                    4.1 Cancellation by User
                                </Heading>
                                <Text fontSize="md">
                                    You can cancel your subscription at any time through your account settings. Cancellations will take effect at the end of your current billing period. If you cancel your subscription, you will not receive a refund for any fees paid.
                                </Text>
                            </Box>
                            <Box mb={6}>
                                <Heading as="h3" size="md" mb={2} color="gray.400">
                                    4.2 Downgrading Your Plan
                                </Heading>
                                <Text fontSize="md">
                                    You may downgrade your subscription plan at any time. Changes will take effect at the start of the next billing period. Downgrading may result in the loss of features or access to certain parts of the Service.
                                </Text>
                            </Box>
                        </Box>

                        <Box>
                            <Heading as="h2" size="lg" mb={4} color="gray.300">
                                5. Account Suspension and Termination
                            </Heading>
                            <Box mb={6}>
                                <Heading as="h3" size="md" mb={2} color="gray.400">
                                    5.1 Termination by Us
                                </Heading>
                                <Text fontSize="md">
                                    We may suspend or terminate your account without notice if we suspect fraudulent, abusive, or illegal activity or if you violate these Terms. If your account is terminated for violating these Terms, you will not be eligible for a refund.
                                </Text>
                            </Box>
                            <Box mb={6}>
                                <Heading as="h3" size="md" mb={2} color="gray.400">
                                    5.2 Termination by You
                                </Heading>
                                <Text fontSize="md">
                                    You may terminate your account at any time, but you will remain responsible for any outstanding charges and will not receive a refund for any unused portion of your subscription.
                                </Text>
                            </Box>
                        </Box>

                        <Box>
                            <Heading as="h2" size="lg" mb={4} color="gray.300">
                                6. Changes to the Service and Terms
                            </Heading>
                            <Text fontSize="md">
                                We reserve the right to modify or discontinue the Service at any time. We will provide notice of significant changes affecting the terms of your subscription. We may update these Terms from time to time. Continued use of the Service after any changes constitutes acceptance of the new Terms.
                            </Text>
                        </Box>

                        <Box>
                            <Heading as="h2" size="lg" mb={4} color="gray.300">
                                7. Contact Information
                            </Heading>
                            <Text fontSize="md">
                                If you have any questions or concerns about these Terms, please contact us at{' '}
                                <Link href="mailto:info@pentity.com" color="teal.300" isExternal _hover={{ textDecoration: 'underline' }}>
                                    info@pentity.com
                                </Link>.
                            </Text>
                        </Box>

                    </Stack>

                    {/* Checkbox Options */}
                    <Stack spacing={4} mt={8} direction="row" justify="center">
                        <Checkbox
                            colorScheme="green"
                            isChecked={isAgreeChecked}
                            onChange={handleAgreeChange}
                        >
                            I have read and agree to the Terms of Service
                        </Checkbox>

                    </Stack>

                    {/* Buttons */}
                    <Stack direction="row" spacing={4} mt={12} justify="center">
                        <Button
                            colorScheme="green"
                            variant="solid"
                            size="lg"
                            _hover={{ bg: 'green.600' }}
                            borderRadius="full"
                            isDisabled={!isAgreeChecked}  // Disable button if not agreed
                        >
                            Accept
                        </Button>
                        <Button
                            colorScheme="red"
                            variant="solid"
                            size="lg"
                            _hover={{ bg: 'red.600' }}
                            borderRadius="full"
                        >
                            Decline
                        </Button>
                    </Stack>
                </Box>
            </Box>
            <MainpageFooter />
        </div>
    );
}
