import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { 
  Box, 
  Button, 
  Text, 
  Table, 
  Thead, 
  Tbody, 
  Tr, 
  Th, 
  Td, 
  VStack, 
  Divider, 
  Card, 
  Flex, 
  Grid, 
  Icon 
} from '@chakra-ui/react';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import UserNavbar from '../../auth/UserNavbar';
import MainpageFooter from '../../MainpageFooter';
import { MdDownload, MdArrowBack } from 'react-icons/md'; // Import icons
import logo from '../../../assets/images/logo.png';

const DomainInfoDetailPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state?.data; // Access the data passed in the route

  if (!data || !data.domain_info) { 
    return <Text textAlign="center" fontSize="lg" mt={8}>Data is null or missing required fields</Text>;
  }

  const domainInfo = data.domain_info;

  const generatePdf = () => {
    const doc = new jsPDF();
    let yOffset = 10; // Initialize vertical offset for content
    const pageHeight = doc.internal.pageSize.height; // Get the height of the page
    const marginBottom = 10; // Set bottom margin

    // Function to add a new page and reset the yOffset
    const addPage = () => {
        doc.addPage();
        yOffset = 10; // Reset yOffset for new page
    };

    // Function to check if the next element will overflow the page
    const checkPageOverflow = (heightToAdd) => {
        if (yOffset + heightToAdd > pageHeight - marginBottom) {
            addPage(); // Add a new page if overflow
        }
    };

    // Add Logo at the top of the PDF if it exists
    const logoWidth = 50; // Set logo width
    const logoHeight = 20; // Set logo height

    if (logo) {
        doc.addImage(logo, 'PNG', 10, yOffset, logoWidth, logoHeight);
        yOffset += logoHeight + 10; // Move yOffset down after logo
    }

    // Add Title after the Logo
    doc.text('Company Information Report', 10, yOffset);
    yOffset += 20; // Move yOffset down after title

    // Initialize pdfData array
    const pdfData = [];

    // Populate the pdfData array with domain information
    domainInfo.forEach(info => {
        pdfData.push(
            { key: 'Create Date', value: info.create_date ?? 'N/A' },
            { key: 'Update Date', value: info.update_date ?? 'N/A' },
            { key: 'Expiry Date', value: info.expiry_date ?? 'N/A' },
            { key: 'Registrar Name', value: info.domain_registrar?.registrar_name ?? 'N/A' },
            { key: 'Full Name', value: info.registrant_contact?.full_name ?? 'N/A' },
            { key: 'Company Name', value: info.registrant_contact?.company_name ?? 'N/A' },
            { key: 'Email Address', value: info.registrant_contact?.email_address ?? 'N/A' },
            { key: 'Phone Number', value: info.registrant_contact?.phone_number ?? 'N/A' }
        );
    });

    // Calculate the total height needed for the table
    const rowHeight = 10; // Adjust this based on your design (e.g., based on font size)
    const totalHeight = pdfData.length * rowHeight + 30; // 30 for header and margins

    // Check for overflow and add pages if needed
    checkPageOverflow(totalHeight); // Check if the total height overflows the page

    // Set up the autoTable with appropriate headers and body
    doc.autoTable({
        head: [['Key', 'Value']],
        body: pdfData.map(item => [item.key, item.value]),
        startY: yOffset, // Set the starting y position for the table
        margin: { top: yOffset }, // Ensure it starts at the correct yOffset
    });

    // Save the generated PDF
    doc.save('domain-history.pdf');
  };

  return (
    <Box>
      <UserNavbar />
      <Box p={6} bgGradient="linear(to-r, teal.500, blue.600)" color="white" mb={4}>
        <VStack spacing={4} align="stretch">
          <Text fontWeight="bold" fontSize="4xl" textAlign="center">Domain History</Text>
          <Divider />
          <Flex justify="space-between" align="center">
            <Button
              colorScheme="gray"
              onClick={() => navigate(-1)} // Navigate back to the previous page
              size="lg"
              leftIcon={<Icon as={MdArrowBack} />}
              borderRadius="full"
            >
              Back
            </Button>
            <Button
              colorScheme="teal"
              onClick={generatePdf}
              size="lg"
              leftIcon={<Icon as={MdDownload} />}
              borderRadius="full"
            >
              Download PDF
            </Button>
          </Flex>
        </VStack>
      </Box>

      <Box p={4}>
        {domainInfo.length > 0 ? (
          <Grid templateColumns={{ base: '1fr', md: '1fr 1fr' }} gap={6}>
            {domainInfo.map((info, index) => (
              <Card 
                key={index} 
                p={5} 
                borderWidth="1px" 
                borderRadius="lg" 
                boxShadow="lg" 
                bg="white"
                color="black"
                _hover={{ boxShadow: 'xl' }} // Shadow on hover
                transition="0.3s"
              >
                <Table variant="striped" colorScheme="teal">
                  <Thead>
                    <Tr>
                      <Th>Key</Th>
                      <Th>Value</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>Create Date</Td>
                      <Td>{info.create_date ?? 'N/A'}</Td>
                    </Tr>
                    <Tr>
                      <Td>Update Date</Td>
                      <Td>{info.update_date ?? 'N/A'}</Td>
                    </Tr>
                    <Tr>
                      <Td>Expiry Date</Td>
                      <Td>{info.expiry_date ?? 'N/A'}</Td>
                    </Tr>
                    <Tr>
                      <Td>Registrar Name</Td>
                      <Td>{info.domain_registrar?.registrar_name ?? 'N/A'}</Td>
                    </Tr>
                    <Tr>
                      <Td>Full Name</Td>
                      <Td>{info.registrant_contact?.full_name ?? 'N/A'}</Td>
                    </Tr>
                    <Tr>
                      <Td>Company Name</Td>
                      <Td>{info.registrant_contact?.company_name ?? 'N/A'}</Td>
                    </Tr>
                    <Tr>
                      <Td>Email Address</Td>
                      <Td>{info.registrant_contact?.email_address ?? 'N/A'}</Td>
                    </Tr>
                    <Tr>
                      <Td>Phone Number</Td>
                      <Td>{info.registrant_contact?.phone_number ?? 'N/A'}</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </Card>
            ))}
          </Grid>
        ) : (
          <Text fontSize="lg" textAlign="center" mt={8}>No domain information available.</Text>
        )}
      </Box>
      <MainpageFooter />
    </Box>
  );
};

export default DomainInfoDetailPage;
