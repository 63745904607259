import React from 'react';
import ReactDOM from 'react-dom/client'; // Correct import for React 18
import { BrowserRouter as Router } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { Amplify } from 'aws-amplify'; // Correct import for AWS Amplify
import App from './App'; // Ensure this file exists and is correctly implemented
import awsconfig from './aws-exports'; // Ensure this file exists and is correctly configured

// Configure Amplify with the configuration
Amplify.configure(awsconfig);

// Render the application
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Router>
      <ChakraProvider>
        <App />
      </ChakraProvider>
    </Router>
  </React.StrictMode>
);
