import React from 'react';
import { Box, Text, Divider, Button, useToast, Icon } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { FaExclamationTriangle } from 'react-icons/fa';

// Function to get user plan from session storage
const getUserPlan = () => {
  return sessionStorage.getItem('userPlan') || 'Free'; // Default to 'Free' if not found
};

const DomainLeaksCard = ({ data }) => {
  const navigate = useNavigate();
  const totalLeaks = data?.data?.total;
  const toast = useToast();
  const userPlan = getUserPlan(); // Retrieve user plan here

  const handleViewMore = () => {
    if (userPlan === 'Free') {
      toast({
        title: 'Upgrade Required',
        description: 'You need to upgrade your plan to access this feature.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
    } else {
      navigate('/leaks-details', { state: { leaks: data?.data?.leaks } });
    }
  };

  return (
    <Box
      p={6}
      borderWidth="1px"
      borderRadius="lg"
      boxShadow="lg"
      background="linear-gradient(to right, #f0f4c3, #bbdefb)" // Gradient background
      width={['95%', '80%', '70%']}
      mx="auto"
      mt={6}
      transition="transform 0.3s, box-shadow 0.3s"
      _hover={{ boxShadow: '2xl', transform: 'translateY(-4px)' }} // Slight upward movement on hover
    >
      <Box display="flex" justifyContent="center" alignItems="center" mb={4}>
        <Icon as={FaExclamationTriangle} boxSize={8} color="blue.600" mr={2} />
        <Text fontWeight="bold" fontSize="2xl" textAlign="center" color="blue.800">
          Domain Leaks
        </Text>
      </Box>
      <Divider mb={4} />
      <Box maxH="400px" overflowY="auto" mb={4}>
        <Text fontWeight="bold" fontSize="lg" color="blue.800" textAlign="center">
          Total Leaks: <Box as="span" color="red.500">{totalLeaks !== undefined ? totalLeaks : 'N/A'}</Box>
        </Text>
      </Box>
      <Box textAlign="center">
        <Button
          colorScheme="blue"
          variant="solid"
          size="lg"
          onClick={handleViewMore}
          _hover={{ bg: 'blue.600', transform: 'scale(1.05)' }} // Scale effect on hover
          transition="background 0.3s, transform 0.3s"
        >
          View More
        </Button>
      </Box>
    </Box>
  );
};

export default DomainLeaksCard;
