import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { 
  Box, 
  Input, 
  VStack,  
  Table, 
  Thead, 
  Tbody, 
  Tr, 
  Th, 
  Td, 
  Text, 
  Button, 
  Center, 
  useBreakpointValue, 
  Menu, 
  MenuButton, 
  MenuItem, 
  MenuList, 
  Stack, 
  HStack, 
  Icon, 
  Spinner ,Divider,useToast
} from '@chakra-ui/react';
import { FaBuilding, FaInfoCircle, FaQuestionCircle, FaLink, FaChevronDown ,FaTools} from 'react-icons/fa'; // Added ChevronDown for dropdown icon
import ImageOne from '../../assets/images/Global_network_generated.jpg';
import UserNavbar from '../auth/UserNavbar';
import MainpageFooter from '../MainpageFooter';
import MainComponent from '../Maincomponent';
import {getToken } from '../auth/loginPage';


const SecurityHeaders = () => {
  const [domain, setDomain] = useState('');
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [, setError] = useState(null);
  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const [message, setMessage] = useState('');
  const toast = useToast();

    // Define responsive values for padding, font size, etc.
const paddingX = useBreakpointValue({ base: '4', md: '6', lg: '8' });
const buttonWidth = useBreakpointValue({ base: '80%', sm: '60%', md: '50%' });
const containerWidth = useBreakpointValue({ base: '90%', md: '70%', lg: '60%' });
const containerHeight = useBreakpointValue({ base: '70%', md: '60%', lg: '50%' });

  // Load results from sessionStorage if available
  useEffect(() => {
    const checkAuthentication = () => {
      const storedToken = getToken(); // Retrieve token

    setToken(storedToken);
      const accessToken = sessionStorage.getItem('accessToken'); // Access token should be stored in session storage
  
      if (!accessToken) {
        navigate('/signin'); // Redirect to sign-in page if no access token
        return;
      }
    };
  
    checkAuthentication();
  }, [navigate]);
  

  const fetchSecurityHeaders = async () => {
    setIsLoading(true);
    setError(null);
    if (!token) {
      setMessage('Token is missing. Please sign in first.');
      return;
    }
    try {
      const encodedDomain = encodeURIComponent(domain);
      const response = await axios.get(
        `https://g1o62ob9e6.execute-api.ap-south-1.amazonaws.com/v1/api/domain_tools/response_headers_security?url=${encodedDomain}`, 
        {
          method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        }
      );
      setData(response.data.results); // Set the results array
    
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFetchClick = () => {
    if (domain) {
      fetchSecurityHeaders();
    }
  };
  

  const headings = [
    'Security Header Summary',
    'Missing Headers',
    'Warnings',
    'Raw Headers',
    'Upcoming Headers',
    'Additional Information'
  ];

  const renderTable = (title, result) => (
    <Box
      marginBottom="8"
      borderWidth="1px"
      borderRadius="lg"
      padding="6"
      bg="white"
      boxShadow="xl"
      _hover={{ transform: "scale(1.02)", transition: "0.3s ease-in-out" }}
    >
      <Text
        fontSize="2xl"
        fontWeight="extrabold"
        color="blue.600"
        marginBottom="4"
        textAlign="center"
        textTransform="uppercase"
      >
        {title}
      </Text>
      <Table
        variant="striped"
        colorScheme="green"
        size="md"
        border="1px solid"
        borderColor="gray.300"
        borderRadius="md"
        overflowX="auto"
      >
        <Thead bg="green.600">
          <Tr>
            <Th color="white" fontWeight="bold" textAlign="center">
              Header
            </Th>
            <Th color="white" fontWeight="bold" textAlign="center">
              Value
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {Object.entries(result).map(([key, value], index) => (
            <Tr key={index}>
              {/* Left-side header (keys) in blue */}
              <Td
                fontWeight="semibold"
                textAlign="center"
                color="blue.600"
                padding="12px"
                borderRight="2px solid"
                borderColor="gray.200"
              >
                {key}
              </Td>
              {/* Right-side value */}
              <Td
                textAlign="center"
                padding="12px"
                style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}
              >
                {key === "content-security-policy" ? formatContentSecurityPolicy(value) : value}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
  

  const formatContentSecurityPolicy = (policy) => {
    // Split, clean, and sort the URLs in the Content-Security-Policy header
    const parts = policy.split(';').map(part => part.trim()).filter(part => part);
    return (
      <Box>
        {parts.map((part, index) => (
          <Text key={index} whiteSpace="pre-wrap" mb="1">{part}</Text>
        ))}
      </Box>
    );
  };

  return (

    <div>
     <div
    style={{
      position: 'fixed',                         // Fix the position of the navbar
      top: 0,                                    // Stick it to the top
      left: 0,                                   // Align it to the left edge
      width: '100%',                             // Full width for the navbar
      zIndex: 1000,                              // Ensure it's above other content
    }}
  >
   <UserNavbar /> 
  </div>
    
 <div>
    <Box
    height={{ base: '60vh', md: '60vh', lg: '60vh' }}
    backgroundImage={`url(${ImageOne})`} 
    backgroundSize="cover"
    backgroundPosition="center"
    position="relative"
  >
    <Center height="100%">
      <Box
        width={containerWidth}
        height={containerHeight}
        backgroundColor="rgba(0, 0, 0, 0.6)"
        color="white"
        borderRadius="20px"
        paddingX={paddingX}
        paddingY={{ base: '4', md: '6', lg: '8' }}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
      >
        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: '4', md: '6' }}
          align="center"
          justify="center"
          mb={6}
          wrap="wrap" // Ensure that items wrap if needed
          gap={{ base: '4', md: '6' }} // Responsive gap
        >

          {/* Normal Button */}
          <Button
            bg="transparent"
            color="white"
            borderRadius="md"
            _hover={{ bg: 'rgba(0, 0, 0, 0.3)' }}
            onClick={() => navigate('/companyinfo')}
          >
            <HStack spacing={2}>
            <Icon as={FaBuilding} />
              <Text>Domain Risk Analysis</Text>
            </HStack>
          </Button>
    

           {/* Normal Button */}
        <Button
                bg="transparent"
                color="white"
                borderRadius="md"
                _hover={{ bg: 'rgba(0, 0, 0, 0.3)' }}
                onClick={() => navigate('/companysearch')}
              >
                <HStack spacing={2}>
                <Icon as={FaInfoCircle} />
                  <Text>Deep and Dark Search</Text>
                </HStack>
              </Button>

          {/* Dropdown Button 3 */}
          <Menu>
            <MenuButton
              as={Button}
              bg="transparent"
              color="white"
              borderRadius="md"
              _hover={{ bg: 'rgba(0, 0, 0, 0.3)' }}
              rightIcon={<FaChevronDown />}
            >
              <HStack spacing={2}>
                <Icon as={FaTools} />
                <Text>Security Headers</Text>
              </HStack>
            </MenuButton>
            <MenuList bg="black" color="white">
              <MenuItem
                bg="black"
                _hover={{ bg: 'rgba(255, 255, 255, 0.2)' }}
                onClick={() => navigate('/dkim')}
              >
                DKIM
              </MenuItem>
              
              <MenuItem
                bg="black"
                _hover={{ bg: 'rgba(255, 255, 255, 0.2)' }}
                onClick={() => navigate('/sitetechnology')}
              >
                Site Technology
              </MenuItem>
              <MenuItem
                    bg="black"
                    _hover={{ bg: 'rgba(255, 255, 255, 0.2)' }}
                    onClick={() => navigate('/url')}
                  >
                    URL Analysis
                  </MenuItem>
            </MenuList>
          </Menu>

          {/* Normal Button */}
          {/* <Button
            bg="transparent"
            color="white"
            borderRadius="md"
            _hover={{ bg: 'rgba(0, 0, 0, 0.3)' }}
            onClick={() => navigate('/url')}
          >
            <HStack spacing={2}>
              <Icon as={FaLink} />
              <Text>URL Analysis</Text>
            </HStack>
          </Button> */}
        </Stack>

        {/* Input and Search Button */}
        <VStack spacing={4} width={containerWidth} align="center">
          <Input
            placeholder="Company Name: 'example.com'"
            value={domain}
            onChange={(e) => setDomain(e.target.value)}
            size="md"
            color="white"
            backgroundColor="rgba(255, 255, 255, 0.2)"
            borderRadius="md"
            _placeholder={{ color: 'white' }}
            border="1px solid white"
          />
          {/* <Button
            colorScheme="teal"
            width={buttonWidth}
            borderRadius="20px"
            backgroundColor="rgba(0, 0, 0, 0.5)"
            _hover={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}
            onClick={handleFetchClick}
          >
            Search
            </Button> */}
            <MainComponent domain={domain} onSuccess={handleFetchClick} />
            </VStack>
          </Box>
        </Center>
      </Box>
    <br></br>
    <center>
                {isLoading && <Spinner size="xl" />}
        
                </center>


    {/* <Box padding="20px" borderWidth="2px" borderRadius="md" boxShadow="md" bg="white">
      <VStack spacing="6" align="start">
        <Text fontSize="2xl" fontWeight="bold" color="teal.800">Security Headers</Text>

       
        <Stack spacing="4" width="100%">
          <Input
            placeholder="Enter domain"
            value={domain}
            onChange={(e) => setDomain(e.target.value)}
            borderColor="teal.500"
            focusBorderColor="teal.300"
          />
        
         <MainComponent domain={domain} onSuccess={handleFetchClick} />

        </Stack>

     
        {error && <Text color="red.500" fontSize="md">Error: {error}</Text>} */}

        {/* Display Data in Tables with Headings */}

        {data && data.length > 0 && (
          <Box width="100%">
            {data.map((result, index) => (
              <Box key={index}>
                {renderTable(headings[index], result)}
                {index < data.length - 1 && <Divider my="6" />}
              </Box>
            ))}
          </Box>
        )}
       <br></br>
         <MainpageFooter/>
            </div> 

        </div>
  );
};

export default SecurityHeaders;
