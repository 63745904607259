import React from 'react';
import {
  Box,
  Button,
  Text,
  Divider,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useToast,
  Flex,
  Icon,
} from '@chakra-ui/react';
import { AtSignIcon, CheckCircleIcon, WarningIcon } from '@chakra-ui/icons'; // Importing icons
import { useNavigate } from 'react-router-dom';

// Function to get user plan from session storage
const getUserPlan = () => {
  return sessionStorage.getItem('userPlan') || 'Free'; // Default to 'Free' if not found
};

const SubdomainsCard = ({ data }) => {
  const navigate = useNavigate();
  const toast = useToast();
  const userPlan = getUserPlan(); // Retrieve user plan here

  const subdomains = data?.SubDomains.sub_domains?.subdomains || [];
  const wildcardSubdomains = data?.SubDomains.sub_domains?.wildcard_subdomains || [];
  const foundEmails = data?.SubDomains.sub_domains?.found_emails || [];
  const resolvedIps = data?.SubDomains.sub_domains?.resolved_ips || {};

  const handleViewMoreClick = () => {
    if (userPlan === 'Free') {
      toast({
        title: 'Upgrade Required',
        description: 'You need to upgrade your plan to access this feature.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
    } else {
      navigate('/subdomain-details', {
        state: {
          subdomains,
          wildcardSubdomains,
          foundEmails,
          resolvedIps,
        },
      });
    }
  };

  return (
    <Box
      p={6}
      borderWidth="1px"
      borderRadius="lg"
      boxShadow="lg"
      backgroundColor="gray.50"
      width={['95%', '80%', '70%']}
      mx="auto"
      mt={6}
      transition="0.3s"
      _hover={{ boxShadow: 'xl', transform: 'scale(1.02)' }} // Scale on hover
    >
      <Flex alignItems="center" justifyContent="center" mb={4}>
        <AtSignIcon boxSize={8} color="blue.600" mr={2} />
        <Text fontWeight="bold" fontSize="2xl" color="blue.800">
          Subdomains Overview
        </Text>
      </Flex>
      <Divider mb={4} />
      <Table variant="striped" colorScheme="teal">
        <Thead>
          <Tr>
            <Th fontWeight="bold" fontSize="md" color="blue.700">Category</Th>
            <Th fontWeight="bold" fontSize="md" color="blue.700">Count</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>Subdomains</Td>
            <Td color={subdomains.length > 0 ? 'green.500' : 'red.500'}>
              {subdomains.length > 0 ? (
                <>
                  <CheckCircleIcon color="green.500" boxSize={5} mr={2} />
                  {subdomains.length}
                </>
              ) : (
                <>
                  <WarningIcon color="red.500" boxSize={5} mr={2} />
                  0
                </>
              )}
            </Td>
          </Tr>
          <Tr>
            <Td>Wildcard Subdomains</Td>
            <Td color={wildcardSubdomains.length > 0 ? 'green.500' : 'red.500'}>
              {wildcardSubdomains.length > 0 ? (
                <>
                  <CheckCircleIcon color="green.500" boxSize={5} mr={2} />
                  {wildcardSubdomains.length}
                </>
              ) : (
                <>
                  <WarningIcon color="red.500" boxSize={5} mr={2} />
                  0
                </>
              )}
            </Td>
          </Tr>
          <Tr>
            <Td>Found Emails</Td>
            <Td color={foundEmails.length > 0 ? 'green.500' : 'red.500'}>
              {foundEmails.length > 0 ? (
                <>
                  <CheckCircleIcon color="green.500" boxSize={5} mr={2} />
                  {foundEmails.length}
                </>
              ) : (
                <>
                  <WarningIcon color="red.500" boxSize={5} mr={2} />
                  0
                </>
              )}
            </Td>
          </Tr>
          <Tr>
            <Td>Resolved IPs</Td>
            <Td color={Object.keys(resolvedIps).length > 0 ? 'green.500' : 'red.500'}>
              {Object.keys(resolvedIps).length > 0 ? (
                <>
                  <CheckCircleIcon color="green.500" boxSize={5} mr={2} />
                  {Object.keys(resolvedIps).length}
                </>
              ) : (
                <>
                  <WarningIcon color="red.500" boxSize={5} mr={2} />
                  0
                </>
              )}
            </Td>
          </Tr>
        </Tbody>
      </Table>
      <Box mt={6} textAlign="center">
        <Button
          colorScheme="blue"
          size="lg"
          onClick={handleViewMoreClick}
          _hover={{ bg: 'blue.600' }} // Darken button on hover
          transition="background 0.3s"
        >
          View More
        </Button>
      </Box>
    </Box>
  );
};

export default SubdomainsCard;
 