import React, { useEffect } from 'react';
import {
    Box,
    Heading,
    Text,
    SimpleGrid,
    Container,
    Image,
    Flex,
    Button,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { ChevronRightIcon, InfoOutlineIcon } from '@chakra-ui/icons'; // Importing icons from Chakra UI
import cyber from '../../assets/images/cybersecurity.jpg';
import { useNavigate } from 'react-router-dom';
import UserNavbar from '../auth/UserNavbar';
import Navbar from '../Navbar';
import siteTechnology from '../../assets/images/siteTechnology.jpg';
import dkim from '../../assets/images/dkim.avif';
import securityHeaders from '../../assets/images/securityHeaders.avif';
import squatting from '../../assets/images/squatting.avif';

const MotionBox = motion(Box);

const KeyServices = ({ isAuthenticated }) => {

    useEffect(() => {
       
      }, [isAuthenticated]);


    const animationVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
    };

    const navigate = useNavigate();
    navigate('/contact')

    const getPricing = () => {
        navigate('/pricing');

    };

    const getContact = () => {
        navigate('/contact');

    };

    return (

        <div>
            {isAuthenticated ? <UserNavbar /> : <Navbar />}

            <Box css={{ scrollBehavior: 'smooth', overflowY: 'auto', height: '100vh' }}
                flex={1} textAlign={['center', 'center', 'left']}>
                <Container maxW="7xl" mt={[5, 5]}>
                    <MotionBox
                        initial="hidden"
                        animate="visible"
                        variants={animationVariants}
                        textAlign="center"
                        py={[10, 20]}
                        backgroundImage={`url(${cyber})`}
                        backgroundPosition="center"
                        backgroundRepeat="no-repeat"
                        backgroundSize="cover"
                        borderRadius="lg"
                        boxShadow="lg"
                        color="white"
                        mb={[10, 20]} // Add margin bottom to create space between sections
                    >
                        <Heading as="h1" size={["xl", "2xl"]} mb={5} color="black.900">
                            Additional Services
                        </Heading>
                        <Text fontSize={["lg", "xl"]} color="gray.100">
                            Introduce your platform as an all-encompassing solution designed to protect your business from external threats, ensuring your brand, data, and digital assets are secure.
                        </Text>
                    </MotionBox>


                    <MotionBox id="siteTechnology"
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        variants={animationVariants}
                        mb={5}
                        bg="gray.50" // Light background for Key Services
                        p={5} // Padding for better readability
                        borderRadius="lg"
                        boxShadow="md"
                    >
                        <Heading as="h2" size="xl" mb={8}>
                        Site Technology
                        </Heading>
                        <Flex
                            direction={['column', 'column', 'row']}
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Box flex="0 0 25%" mt={[10, 0]}>
                                <Image
                                    src={siteTechnology}
                                    alt="Cybersecurity illustration"
                                    width="100%"
                                    borderRadius="lg"
                                />
                            </Box>
                            <Box flex="1" textAlign={['center', 'center', 'left']} pl={[0, 0, 10]}>

                                <SimpleGrid columns={[1, 1, 2]} spacing={10}>
                                    {[
                                        {
                                            icon: <ChevronRightIcon boxSize={5} color="teal.500" />,
                                            heading: 'Service Overview',
                                            text: 'Understanding the technologies used by a domain is crucial for assessing the security posture of a website or service. This feature identifies the web technologies, frameworks, server types, CMS (Content Management Systems), JavaScript libraries, and other technologies in use.',
                                        },
                                        {
                                            icon: <ChevronRightIcon boxSize={5} color="teal.500" />,
                                            heading: 'Identifying Potential Vulnerabilities',
                                            text: 'Knowing what technologies are deployed on a domain helps security professionals identify potential vulnerabilities associated with those technologies. ',
                                        },
                                        {
                                            icon: <ChevronRightIcon boxSize={5} color="teal.500" />,
                                            heading: 'Pinpointing Outdated or Unpatched Software',
                                            text: 'It aids in pinpointing outdated software or unpatched systems that could be exploited.',
                                        },
                                        {
                                            icon: <ChevronRightIcon boxSize={5} color="teal.500" />,
                                            heading: 'Evaluating Security Risks and Attack Vectors',
                                            text: 'Assessing the tech stack helps in evaluating the security risks and understanding the potential attack vectors.',
                                        },
                                        {
                                            icon: <ChevronRightIcon boxSize={5} color="teal.500" />,
                                            heading: 'Ensuring Compliance with Security Standards',
                                            text: 'Organizations can ensure their tech stack complies with security standards and regulations.',
                                        },
                                        
                                    ].map((item, index) => (
                                        <Box key={index} mb={5} display="flex" alignItems="flex-start">
                                            {item.icon}
                                            <Box as="span" ml={3}>
                                                <Heading as="h3" size="md" mb={2}>
                                                    {item.heading}
                                                </Heading>
                                                <Text>
                                                    {item.text}
                                                </Text>
                                            </Box>
                                        </Box>
                                    ))}
                                </SimpleGrid>
                            </Box>
                        </Flex>
                    </MotionBox>

                    <MotionBox id="dkim"
                        initial="hidden"
                        animate="visible"
                        variants={animationVariants}
                        py={[10, 20]}
                        mt={[10, 20]}
                        borderRadius="lg"
                        boxShadow="lg"
                        width="100%"
                        maxW="100%" // Ensure it takes up full width
                        px={[5, 10]}
                        bg="gray.100" // Light gray background for Importance section
                    >
                        <Heading as="h2" size="xl" mb={8}>
                        DKIM
                        </Heading>
                        <Flex
                            direction={['column', 'column', 'row']}
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Box flex="0 0 65%" textAlign={['center', 'center', 'left']} pr={[0, 0, 10]}>

                                <SimpleGrid columns={[1, 1, 2]} spacing={10}>
                                    {[
                                        {
                                            icon: <InfoOutlineIcon boxSize={5} color="teal.500" />,
                                            heading: 'Importance',
                                            text: 'DomainKeys Identified Mail (DKIM) is an email authentication method designed to detect email spoofing. It allows the receiver to check that an email claimed to have come from a specific domain was indeed authorized by the owner of that domain.',
                                        },
                                        {
                                            icon: <InfoOutlineIcon boxSize={5} color="teal.500" />,
                                            heading: 'Preventing Phishing and Email Spoofing Attacks',
                                            text: 'DKIM helps block malicious actors from sending fraudulent emails on behalf of your domain, enhancing security against phishing and spoofing.',
                                        },
                                        {
                                            icon: <InfoOutlineIcon boxSize={5} color="teal.500" />,
                                            heading: 'Safeguarding Brand Integrity',
                                            text: 'By ensuring that only authorized emails are sent under your domain, DKIM protects your brand’s reputation and credibility.',
                                        },
                                        {
                                            icon: <InfoOutlineIcon boxSize={5} color="teal.500" />,
                                            heading: 'Improving Email Deliverability',
                                            text: 'A properly configured DKIM setup increases the likelihood of your emails reaching recipients inboxes, ensuring reliable communication.',
                                        },
                                        {
                                            icon: <InfoOutlineIcon boxSize={5} color="teal.500" />,
                                            heading: 'Establishing a Trusted Email Channel',
                                            text: 'DKIM creates a verified and trusted channel for your emails, minimizing the chances of them being flagged as spam.',
                                        },
                                        
                                    ].map((item, index) => (
                                        <Box key={index} mb={5} display="flex" alignItems="flex-start">
                                            {item.icon}
                                            <Box as="span" ml={3}>
                                                <Heading as="h3" size="md" mb={2}>
                                                    {item.heading}
                                                </Heading>
                                                <Text>
                                                    {item.text}
                                                </Text>
                                            </Box>
                                        </Box>
                                    ))}
                                </SimpleGrid>
                            </Box>
                            <Box flex="0 0 25%" mt={[10, 0]}>
                                <Image
                                    src={dkim}
                                    alt="Cybersecurity illustration"
                                    width="100%"
                                    borderRadius="lg"
                                />
                            </Box>

                        </Flex>
                    </MotionBox> <br />

                    <MotionBox id="securityHeaders"
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        variants={animationVariants}
                        mb={5}
                        bg="gray.50"  // Background color for Key Benefits section
                        p={8}
                        borderRadius="lg"
                        boxShadow="lg"
                    >
                        <Heading as="h2" size="xl" mb={8}>
                        Security Headers Vulnerabilities
                        </Heading>
                        <Flex
                            direction={['column', 'column', 'row']}
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Box flex="0 0 25%" mt={[10, 0]}>
                                <Image
                                    src={securityHeaders}
                                    alt="Cybersecurity illustration"
                                    width="100%"
                                    borderRadius="lg"
                                />
                            </Box>
                            <Box flex="1" textAlign={['center', 'center', 'left']} pl={[0, 0, 10]}>

                                <SimpleGrid columns={[1, 1, 2]} spacing={10}>
                                    {[
                                        {
                                            icon: <ChevronRightIcon boxSize={5} color="teal.500" />,
                                            heading: 'Service Overview',
                                            text: "Security headers are directives that inform the web browser about how to behave when handling the website's data. They play a critical role in protecting web applications from various types of attacks.",
                                        },
                                        {
                                            icon: <ChevronRightIcon boxSize={5} color="teal.500" />,
                                            heading: 'Mitigating XSS and Code Injection Risks',
                                            text: 'ecurity headers help reduce risks associated with Cross-Site Scripting (XSS), Clickjacking, and other code injection attacks.',
                                        },
                                        {
                                            icon: <ChevronRightIcon boxSize={5} color="teal.500" />,
                                            heading: 'Enforcing HTTPS for Data Integrity',
                                            text: 'Headers like Strict-Transport-Security ensure that the browser connects only over HTTPS, which enhances data integrity and security.',
                                        },
                                        {
                                            icon: <ChevronRightIcon boxSize={5} color="teal.500" />,
                                            heading: 'Controlling Resource Loading',
                                            text: 'Helps in controlling resources that the user agent is allowed to load, reducing the attack surface for malicious scripts.',
                                        },
                                        {
                                            icon: <ChevronRightIcon boxSize={5} color="teal.500" />,
                                            heading: 'Maintaining a Strong Security Posture',
                                            text: 'Regularly scanning for missing or misconfigured security headers is essential for maintaining a strong security posture and reducing vulnerabilities.',
                                        },
                                        
                                    ].map((item, index) => (
                                        <Box key={index} mb={5} display="flex" alignItems="flex-start">
                                            {item.icon}
                                            <Box as="span" ml={3}>
                                                <Heading as="h3" size="md" mb={2}>
                                                    {item.heading}
                                                </Heading>
                                                <Text>
                                                    {item.text}
                                                </Text>
                                            </Box>
                                        </Box>
                                    ))}
                                </SimpleGrid>
                            </Box>
                        </Flex>
                    </MotionBox>

                    <MotionBox id="squatting"
                        initial="hidden"
                        animate="visible"
                        variants={animationVariants}
                        py={[10, 20]}
                        mt={[10, 20]}
                        borderRadius="lg"
                        boxShadow="lg"
                        width="100%"
                        maxW="100%"
                        px={[5, 10]}
                        bg="gray.100"  // Background color for In-Depth Analysis section
                    >
                        <Flex
                            direction={['column', 'column', 'row']}
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Box flex="0 0 65%" textAlign={['center', 'center', 'left']} pr={[0, 0, 10]}>
                                <Heading as="h2" size="xl" mb={8}>
                                Squatting & Homoglyphs
                                </Heading>
                                <SimpleGrid columns={[1, 1, 2]} spacing={10}>
                                    {[
                                        {
                                            icon: <InfoOutlineIcon boxSize={5} color="teal.500" />,
                                            heading: 'Importance',
                                            text: 'Domain squatting (also known as cybersquatting) and homoglyph attacks involve registering domain names similar to legitimate domains to deceive users, often used for phishing, fraud, and brand abuse.',
                                        },
                                        {
                                            icon: <InfoOutlineIcon boxSize={5} color="teal.500" />,
                                            heading: 'Identifying Phishing Domains',
                                            text: 'Identifies potential phishing domains designed to mimic your brand and trick users into revealing sensitive information.',
                                        },
                                        {
                                            icon: <InfoOutlineIcon boxSize={5} color="teal.500" />,
                                            heading: 'Preventing Brand Identity Misuse',
                                            text: 'Detects and acts upon attempts to misuse your brand identity, protecting it from domain squatting.',
                                        },
                                        {
                                            icon: <InfoOutlineIcon boxSize={5} color="teal.500" />,
                                            heading: 'Protecting Against Fraud',
                                            text: 'Protects your organization and customers from fraud by identifying look-alike domains that could be used to impersonate your brand.',
                                        },
                                        {
                                            icon: <InfoOutlineIcon boxSize={5} color="teal.500" />,
                                            heading: 'Preserving Brand Reputation',
                                            text: 'Ensures your brand’s reputation remains intact by monitoring and preventing the malicious or unauthorized use of similar domain names.',
                                        },
                                        
                                    ].map((item, index) => (
                                        <Box key={index} mb={5} display="flex" alignItems="flex-start">
                                            {item.icon}
                                            <Box as="span" ml={3}>
                                                <Heading as="h3" size="md" mb={2}>
                                                    {item.heading}
                                                </Heading>
                                                <Text> 
                                                    {item.text}
                                                </Text>
                                            </Box>
                                        </Box>
                                    ))}
                                </SimpleGrid>  
                            </Box>
                            <Box flex="0 0 25%" mt={[10, 0]}>
                                <Image
                                    src={squatting}
                                    alt="Cybersecurity illustration"
                                    width="100%" 
                                    borderRadius="lg"
                                />
                            </Box>
                        </Flex>
                    </MotionBox>

                    <MotionBox
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        variants={animationVariants}
                        py={[10, 20]}
                        mt={[10, 20]}
                        borderRadius="xl"
                        boxShadow="2xl"
                        width="100%"
                        maxW="100%"
                        px={[6, 12]}
                        bg="gray.700"
                        textAlign="center"
                        color="white"
                        transition="transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out"
                        _hover={{ transform: 'scale(1.02)', boxShadow: 'lg' }}
                    >
                        <Heading as="h2" size="2xl" mb={6} fontWeight="bold" letterSpacing="wide" color="gray.100">
                            Secure Your Brand and Data Now
                        </Heading>
                        <Text fontSize={["lg", "xl"]} mb={8} lineHeight="1.7" color="gray.300">
                            Get Started Today: Schedule a demo to see how our platform can protect your business.
                            <br />
                            Contact Us: Reach out for more information and let us help you safeguard your brand against today’s evolving cyber threats.
                        </Text>
                        <Flex justifyContent="center" gap={6}>
                            <Button
                                size="lg"
                                variant="solid"
                                bg="black"
                                color="white"
                                border="2px solid transparent"  // Initial border set to transparent
                                _hover={{
                                    bg: "gray.700",
                                    borderColor: "gray.400"  // Border color when hovering
                                }}
                                _active={{
                                    bg: "gray.600",
                                    borderColor: "gray.500"  // Border color when active
                                }}
                                onClick={getPricing}
                            >
                                Schedule a Demo
                            </Button>
                            <Button
                                size="lg"
                                variant="solid"
                                bg="black"
                                color="white"
                                border="2px solid transparent"  // Initial border set to transparent
                                _hover={{
                                    bg: "gray.700",
                                    borderColor: "gray.400"  // Border color when hovering
                                }}
                                _active={{
                                    bg: "gray.600",
                                    borderColor: "gray.500"  // Border color when active
                                }}
                                onClick={getContact}
                            >
                                Contact Us
                            </Button>

                        </Flex>
                    </MotionBox>




                </Container>
            </Box> <br />
            
        </div>
    );
};

export default KeyServices;
