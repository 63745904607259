import React, { useEffect } from 'react';
import {
  Box,
  Heading,
  Text,
  SimpleGrid,
  VStack,
  HStack,
  UnorderedList,
  ListItem,
  Button,
  Container,
  useColorModeValue,Image
} from '@chakra-ui/react';
import Navbar from './Navbar';
import UserNavbar from './auth/UserNavbar';
import MainpageFooter from './MainpageFooter';
import { useNavigate } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import Carousel styles
import C1Image from '../assets/images/tools.png'
import C2Image from '../assets/images/lock3.jpg'
import C3Image from '../assets/images/phishing.png'
import C4Image from '../assets/images/about us image.jpg'

const Feature = ({ isAuthenticated }) => {
  const navigate = useNavigate();

  useEffect(() => {
    console.log('isAuthenticated:', isAuthenticated);
  }, [isAuthenticated]);

  const cardBgColor = useColorModeValue('white', 'gray.700');
  const textColor = useColorModeValue('gray.800', 'white');

  return (
    <div>
      <Box
        position="relative"
        minH="100vh"
        bgGradient="linear(to-b, blue.900, blue.700)"
        color={textColor}
      >
        <Box
          position="fixed"
          top={0}
          left={0}
          width="100%"
          zIndex={1000}
          bgColor="rgba(255, 255, 255, 0.7)"
        >
          {isAuthenticated ? <UserNavbar /> : <Navbar />}
        </Box>
        <br /><br />
        
        <Container maxW="container.xl" py={12}>
          <Box textAlign="center" mb={8}>
            <Heading as="h2" fontSize={{ base: '3xl', md: '4xl' }} mb={4} color="white">
              Company Site
            </Heading>
            <Text fontSize={{ base: 'lg', md: 'xl' }} fontWeight="medium" mb={6} color="white">
              Uncover Hidden Threats, Protect Your Digital Fortress
            </Text>
            <Button
              colorScheme="teal"
              size="lg"
              borderRadius="full"
              onClick={() => navigate('/userhome')}
            >
              Get Started
            </Button>
          </Box>

          {/* Image Carousel Section */}
<Box mb={12}>
  <Carousel autoPlay interval={3000} infiniteLoop showThumbs={false} showIndicators={false}>
    <div>
      <img src={C1Image} alt="Tools for security" style={{ height: '400px', width: '100%', objectFit: 'contain' }} />
    </div>
    <div>
      <img src={C2Image} alt="Tools for security" style={{ height: '400px', width: '100%', objectFit: 'contain' }} />
    </div>
    <div>
      <img src={C3Image} alt="Tools for security" style={{ height: '400px', width: '100%', objectFit: 'contain' }} />
    </div>
    <div>
      <img src={C4Image} alt="Tools for security" style={{ height: '400px', width: '100%', objectFit: 'contain' }} />
    </div>
    {/* Add more images as needed */}
  </Carousel>
</Box>

        {/* Key Benefits Section */}
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8} mb={12}>
          {[
            {
              
              title: 'Domain and DNS Protection',
              items: [
                'Safeguard your online identity and protect your brand reputation.',
                'Prevent domain hijacking and unauthorized access.',
                'Ensure business continuity with robust security measures.',
                'Shield your DNS infrastructure from DDoS attacks and other threats.',
                'Minimize downtime and maintain website accessibility.',
                'Protect sensitive data transmitted through DNS.',
              ],
            },
            {
              title: 'Email Security',
              items: [
                'Defend against sophisticated phishing attacks and email-borne malware.',
                'Safeguard sensitive company information from leaks.',
                'Enhance employee awareness of email threats.',
              ],
            },
            {
              title: 'Deep and Dark Data Monitoring',
              items: [
                'Proactively identify data breaches and vulnerabilities.',
                'Detect unauthorized access to sensitive information.',
                'Minimize the impact of data leaks on your business.',
              ],
            },
            {
              title: 'Domain Leaks',
              items: [
                'Discover and prevent accidental exposure of domain information.',
                'Protect your brand from domain squatting and cybersquatting.',
                'Reduce the risk of phishing attacks and identity theft.',
              ],
            },
          ].map((feature, index) => (
            <Box
              key={index}
              bg={cardBgColor}
              p={6}
              rounded="md"
              shadow="lg"
              _hover={{ transform: 'scale(1.02)', transition: 'transform 0.3s ease' }}
            >
              <Heading as="h3" fontSize="2xl" mb={4}>
                {feature.title}
              </Heading>
              <UnorderedList spacing={3} fontSize="lg">
                {feature.items.map((item, i) => (
                  <ListItem key={i}>{item}</ListItem>
                ))}
              </UnorderedList>
            </Box>
          ))}
        </SimpleGrid>

        {/* How It Works Section */}
        <Box bg={cardBgColor} p={6} rounded="md" shadow="md" mb={8}>
          <Heading as="h3" fontSize="2xl" mb={4} textAlign="center">
            How It Works
          </Heading>
          <VStack spacing={6} align="start">
            {[
              {
                step: '1.',
                title: 'Data Collection and Analysis',
                description: 'We collect and analyze vast amounts of data to uncover hidden threats.',
              },
              {
                step: '2.',
                title: 'Threat Detection and Alerts',
                description: 'Our systems detect potential threats and alert you in real-time.',
              },
              {
                step: '3.',
                title: 'Incident Response and Remediation',
                description: 'We respond quickly to incidents, providing remediation and protection.',
              },
            ].map((process, index) => (
              <HStack key={index} align="start" spacing={4}>
                <Text fontSize="xl" fontWeight="bold">
                  {process.step}
                </Text>
                <VStack align="start">
                  <Heading fontSize="lg">{process.title}</Heading>
                  <Text fontSize="md">{process.description}</Text>
                </VStack>
              </HStack>
            ))}
          </VStack>
        </Box>

        {/* Additional Content Section */}
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8} mb={12}>
          {[
            {
              title: 'Phishing Simulation',
              items: [
                'Identify vulnerabilities in your organization\'s human firewall.',
                'Improve employee awareness and response to phishing threats.',
                'Reduce the risk of successful phishing attacks.',
              ],
            },
            {
              title: 'Automated Penetration Testing',
              items: [
                'Proactively identify and fix vulnerabilities before attackers exploit them.',
                'Accelerate security testing and remediation processes.',
                'Strengthen your overall security posture.',
              ],
            },
            {
              title: 'Code Review',
              items: [
                'Ensure code quality and security from the ground up.',
                'Prevent vulnerabilities from reaching production.',
                'Protect your software from attacks.',
              ],
            },
            {
              title: 'Dark Web Monitoring',
              items: [
                'Detect early signs of data breaches and unauthorized access.',
                'Protect your brand reputation and customer trust.',
                'Minimize the impact of data breaches.',
              ],
            },
          ].map((service, index) => (
            <Box
              key={index}
              bg={cardBgColor}
              p={6}
              rounded="md"
              shadow="lg"
              _hover={{ transform: 'scale(1.02)', transition: 'transform 0.3s ease' }}
            >
              <Heading as="h3" fontSize="2xl" mb={4}>
                {service.title}
              </Heading>
              <UnorderedList spacing={3} fontSize="lg">
                {service.items.map((item, i) => (
                  <ListItem key={i}>{item}</ListItem>
                ))}
              </UnorderedList>
            </Box>
          ))}
        </SimpleGrid>

        {/* Call to Action Section */}
        <Box textAlign="center" mb={8}>
          <Heading as="h2" fontSize={{ base: '2xl', md: '3xl' }} mb={4} color="white">
            Ready to Secure Your Business?
          </Heading>
          <Text fontSize="lg" mb={6} color="white">
            Join us to fortify your defenses and protect your digital assets.
          </Text>
          <Button
            colorScheme="teal"
            size="lg"
            onClick={() => navigate('/signup')}
          >
            Sign Up Now
          </Button>
        </Box>

     
      </Container>
    </Box>
    
    <MainpageFooter />
    </div>
  );
};

export default Feature;
