import React, { useEffect, useState } from 'react';
import { ChakraProvider, Box, Button, Input, VStack, Spinner, Alert, AlertIcon,Center,
    useBreakpointValue,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Stack,
    HStack,
    Text,
    Icon,  useToast} from '@chakra-ui/react';
    import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
  import { FaBuilding, FaInfoCircle, FaQuestionCircle, FaLink, FaChevronDown, FaDownload } from 'react-icons/fa';
  
  import ImageOne from '../../assets/images/Global_network_generated.jpg';
import axios from 'axios';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import 'jspdf-autotable'; // Import jsPDF AutoTable plugin
import DomainInfoCard from './companyInfoDetails/DomainInfoCard';
import EmailSecurityCard from './companyInfoDetails/EmailSecurityCard';
import SSLInfoCard from './companyInfoDetails/SSLInfoCard';
import SquattingCard from './companyInfoDetails/SquattingCard';
import SubdomainsCard from './companyInfoDetails/SubdomainsCard';
import DomainQualityScoreCard from './companyInfoDetails/DomainQualityScoreCard';
import DomainLeaksCard from './companyInfoDetails/DomainLeaksCard';
import logo from '../../assets/images/logo.png';
import UserNavbar from '../auth/UserNavbar';
import MainpageFooter from '../MainpageFooter';
import KeyServices from '../subcomponent/KeyServices';
import MainComponent from '../Maincomponent';
import {getapiToken} from '../auth/loginPage';

 // Function to get user plan from session storage
const getUserPlan = () => {
  return sessionStorage.getItem('userPlan') || 'Free'; // Default to 'Free' if not found 
};



const CompanyInformation = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const userPlan = getUserPlan(); // Assume this is defined elsewhere
  const [message, setMessage] = useState('');
  const [token, setToken] = useState(null);
  const [apiResults, setApiResults] = useState({});
  const [domain, setDomain] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const baseUrl = 'https://ucq3wjoxaffrv6xtbrvaqh3r4e0sgued.lambda-url.ap-south-1.on.aws/api/domain/';
  const endpoints = [
    'domain_info',
    'email_security',
    'ssl_info',
    'squatting',
    'domain_quality_score',
    'domain_leaks',
  ];

  useEffect(() => {
    const storedToken = getapiToken(); // Retrieve token

    setToken(storedToken);

    const accessToken = sessionStorage.getItem('accessToken');
    if (!accessToken) {
      navigate('/signin');
      return;
    }

    const savedResults = sessionStorage.getItem('searchResults');
    if (savedResults) {
      setApiResults(JSON.parse(savedResults));
      console.log(savedResults)
    }
  }, [navigate]);

  const fetchApiData = async () => {
    if (!token) {
      setMessage('Token is missing. Please sign in first.');
      return;
    }
  
    setIsLoading(true);
    setError(null);
  
    try {
      const fetchPromises = endpoints.map(async (endpoint) => {
        const result = await fetch(`${baseUrl}${endpoint}?domain=${domain}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
  
        if (!result.ok) {
          throw new Error(`Network response was not ok for ${endpoint}: ${result.statusText}`);
        }
  
        return await result.json();
      });
  
      const results = await Promise.all(fetchPromises);
      const data = results.reduce((acc, result, index) => {
        acc[endpoints[index]] = result;
        return acc;
      }, {});
  
      setApiResults(data);
      console.log(data)
      setMessage('Data fetched successfully!');
  
      // Set the fetched results in session storage
      sessionStorage.setItem('searchResults', JSON.stringify(data));
    } catch (error) {
      setMessage(`Error fetching data: ${error.message}`);
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  

  const handleSearch = () => {
    fetchApiData();
  };

  // const handleDownloadClick = () => {
  //   if (userPlan === 'Free') {
  //     toast({
  //       title: 'Upgrade Required',
  //       description: 'You need to upgrade your plan to download the PDF.',
  //       status: 'warning',
  //       duration: 5000,
  //       isClosable: true,
  //     });
  //   } else {
  //     generatePdf(apiResults); // Assume this is defined elsewhere
  //   }
  // };

  const paddingX = useBreakpointValue({ base: '4', md: '6', lg: '8' });
  const buttonWidth = useBreakpointValue({ base: '80%', sm: '60%', md: '50%' });
  const containerWidth = useBreakpointValue({ base: '90%', md: '70%', lg: '60%' });
  const containerHeight = useBreakpointValue({ base: '70%', md: '60%', lg: '50%' });

  return (
    <div>
     <div
    style={{
      position: 'fixed',                         // Fix the position of the navbar
      top: 0,                                    // Stick it to the top
      left: 0,                                   // Align it to the left edge
      width: '100%',                             // Full width for the navbar
      zIndex: 1000,                              // Ensure it's above other content
    }}
  >
   <UserNavbar /> 
  </div>
    
     <ChakraProvider>
<div>
    <Box
height={{ base: '60vh', md: '60vh', lg: '60vh' }}
backgroundImage={`url(${ImageOne})`}
backgroundSize="cover"
backgroundPosition="center"
position="relative"
>
<Center height="100%">
  <Box
    width={containerWidth}
    height={containerHeight}
    backgroundColor="rgba(0, 0, 0, 0.6)"
    color="white"
    borderRadius="20px"
    paddingX={paddingX}
    paddingY={{ base: '4', md: '6', lg: '8' }}
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    textAlign="center"
  >
    <Stack
      direction={{ base: 'column', md: 'row' }}
      spacing={{ base: '4', md: '6' }}
      align="center"
      justify="center"
      mb={6}
      wrap="wrap" // Ensure that items wrap if needed
      gap={{ base: '4', md: '6' }} // Responsive gap
    >
      {/* Normal Button */}
  <Button
    bg="transparent"
    color="white"
    borderRadius="md"
    _hover={{ bg: 'rgba(0, 0, 0, 0.3)' }}
    onClick={() => navigate('/companyinfo')}
  >
    <HStack spacing={2}>
    <Icon as={FaBuilding} />
      <Text>Domain Analysis</Text>
    </HStack>
  </Button>


      {/* Normal Button */}
<Button
        bg="transparent"
        color="white"
        borderRadius="md"
        _hover={{ bg: 'rgba(0, 0, 0, 0.3)' }}
        onClick={() => navigate('/companysearch')}
      >
        <HStack spacing={2}>
        <Icon as={FaInfoCircle} />
          <Text>Deep and Dark Search</Text>
        </HStack>
      </Button>

      {/* Dropdown Button 3 */}
      <Menu>
        <MenuButton
          as={Button}
          bg="transparent"
          color="white"
          borderRadius="md"
          _hover={{ bg: 'rgba(0, 0, 0, 0.3)' }}
          rightIcon={<FaChevronDown />}
        >
          <HStack spacing={2}>
            <Icon as={FaQuestionCircle} />
            <Text>Other Services</Text>
          </HStack>
        </MenuButton>
        <MenuList bg="black" color="white">
          <MenuItem
            bg="black"
            _hover={{ bg: 'rgba(255, 255, 255, 0.2)' }}
            onClick={() => navigate('/dkim')}
          >
            DKIM
          </MenuItem>
          <MenuItem
            bg="black"
            _hover={{ bg: 'rgba(255, 255, 255, 0.2)' }}
            onClick={() => navigate('/securityheaders')}
          >
            Security Headers
          </MenuItem>
          <MenuItem
            bg="black"
            _hover={{ bg: 'rgba(255, 255, 255, 0.2)' }}
            onClick={() => navigate('/sitetechnology')}
          >
            Site Technology
          </MenuItem>
        </MenuList>
      </Menu>

      {/* Normal Button */}
      <Button
        bg="transparent"
        color="white"
        borderRadius="md"
        _hover={{ bg: 'rgba(0, 0, 0, 0.3)' }}
        onClick={() => navigate('/url')}
      >
        <HStack spacing={2}>
          <Icon as={FaLink} />
          <Text>URL Analysis</Text>
        </HStack>
      </Button>
    </Stack>

    {/* Input and Search Button */}
    <VStack spacing={4} width={containerWidth} align="center">
      <Input
        placeholder="Company Name: 'example.com'"
        value={domain}
        onChange={(e) => setDomain(e.target.value)}
        size="md"
        color="white"
        backgroundColor="rgba(255, 255, 255, 0.2)"
        borderRadius="md"
        _placeholder={{ color: 'white' }}
        border="1px solid white"
      />
      <Button
      onClick={handleSearch}
        colorScheme="teal"
        width={buttonWidth}
        borderRadius="20px"
        backgroundColor="rgba(0, 0, 0, 0.5)"
        _hover={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}
      >
        Search
      </Button>
      {/* <MainComponent domain={domain} onSuccess={handleSearch} /> */}
    </VStack>
  </Box>
</Center>
</Box>
<br/><br/> 

    {/* <Box p={paddingX} width={containerWidth} height={containerHeight}>
      <VStack spacing={4} align="stretch">
        <Input
          placeholder="Enter domain"
          value={domain}
          onChange={(e) => setDomain(e.target.value)}
        />
        <Button colorScheme="teal" onClick={handleSearch} width={buttonWidth}>
          Search
        </Button>
      </VStack> */}

      <center>
        {isLoading && <Spinner size="xl" />}
        {error && (
          <Alert status="error">
            <AlertIcon />
            {error}
          </Alert>
        )}
      </center>

      {Object.keys(apiResults).length > 0 && (
        <VStack spacing={4} align="stretch">
          {apiResults.domain_info && apiResults.domain_info.basic_info && (
            <DomainInfoCard data={apiResults.domain_info} />
          )}
          {apiResults.email_security && apiResults.email_security.scanResult && (
            <EmailSecurityCard data={apiResults.email_security} />
          )}
          {apiResults.ssl_info && (
            <SSLInfoCard data={apiResults.ssl_info} />
          )}
          {apiResults.squatting && (
            <SquattingCard data={apiResults.squatting} />
          )}
          {apiResults.domain_quality_score && (
            <SubdomainsCard data={apiResults.domain_quality_score} />
          )}
          {apiResults.domain_quality_score && (
            <DomainQualityScoreCard data={apiResults.domain_quality_score} />
          )}
          {apiResults.domain_leaks && (
            <DomainLeaksCard data={apiResults.domain_leaks} />
          )}
          {/* <center><Button
  leftIcon={<FaDownload />} // Adding an icon
  width='50%'
  size="lg" // Larger size
  colorScheme="teal" // Change color scheme for better aesthetics
  variant="solid" // Solid variant
  borderRadius="md" // Rounded corners
  bgGradient="linear(to-r, teal.500, green.500)" // Gradient background
  _hover={{ 
    bgGradient: "linear(to-r, teal.400, green.400)", // Slightly different gradient on hover
    boxShadow: "lg", // Add shadow on hover
  }}
  _active={{ 
    bgGradient: "linear(to-r, teal.600, green.600)", // Darker gradient on active
    transform: "scale(0.98)", // Slightly smaller on click
  }}
  onClick={handleDownloadClick}
>
  Download PDF
</Button>
</center> */}
        </VStack>
      )}
      <br />
      {/* Render KeyServices only if apiResults is empty */}
      {Object.keys(apiResults).length === 0 && <KeyServices />}
        
      <br />
      <MainpageFooter/>
            </div>
        </ChakraProvider>

        </div>
  );
};

export default CompanyInformation;
