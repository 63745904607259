import React from 'react';
import {
  ChakraProvider,
  Box,
  Center,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Button,
  Stack,
  Input,
  VStack,
  HStack,
  Text,
  Icon,
  useBreakpointValue
} from '@chakra-ui/react';
import { FaBuilding, FaInfoCircle, FaQuestionCircle, FaLink, FaChevronDown,FaTools } from 'react-icons/fa'; // Added ChevronDown for dropdown icon
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import ImageOne from '../assets/images/Global_network_generated.jpg';

function MainpageContainer() {
  const navigate = useNavigate(); // Initialize the useNavigate hook

  // Define responsive values for padding, font size, etc.
  const paddingX = useBreakpointValue({ base: '4', md: '6', lg: '8' });
  const buttonWidth = useBreakpointValue({ base: '80%', sm: '60%', md: '50%' });
  const containerWidth = useBreakpointValue({ base: '90%', md: '70%', lg: '60%' });
  const containerHeight = useBreakpointValue({ base: '70%', md: '60%', lg: '50%' });

  return (
    <ChakraProvider>
      <Box
        height={{ base: '60vh', md: '60vh', lg: '60vh' }}
        backgroundImage={`url(${ImageOne})`} 
        backgroundSize="cover"
        backgroundPosition="center"
        position="relative"
      >
        <Center height="100%">
          <Box
            width={containerWidth}
            height={containerHeight}
            backgroundColor="rgba(0, 0, 0, 0.6)"
            color="white"
            borderRadius="20px"
            paddingX={paddingX}
            paddingY={{ base: '4', md: '6', lg: '8' }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            <Stack
              direction={{ base: 'column', md: 'row' }}
              spacing={{ base: '4', md: '6' }}
              align="center"
              justify="center"
              mb={6}
              wrap="wrap" // Ensure that items wrap if needed
              gap={{ base: '4', md: '6' }} // Responsive gap
            >

              {/* Normal Button */}
              <Button
                bg="transparent"
                color="white"
                borderRadius="md"
                _hover={{ bg: 'rgba(0, 0, 0, 0.3)' }}
                onClick={() => navigate('/companyinfo')}
              >
                <HStack spacing={2}>
                <Icon as={FaBuilding} />
                  <Text>Domain Risk Analysis</Text>
                </HStack>
              </Button>
        
        {/* Normal Button */}
        <Button
                bg="transparent"
                color="white"
                borderRadius="md"
                _hover={{ bg: 'rgba(0, 0, 0, 0.3)' }}
                onClick={() => navigate('/companysearch')}
              >
                <HStack spacing={2}>
                <Icon as={FaInfoCircle} />
                  <Text>Deep and Dark Search</Text>
                </HStack>
              </Button>

          
              {/* Dropdown Button 3 */}
              <Menu>
                <MenuButton
                  as={Button}
                  bg="transparent"
                  color="white"
                  borderRadius="md"
                  _hover={{ bg: 'rgba(0, 0, 0, 0.3)' }}
                  rightIcon={<FaChevronDown />}
                >
                  <HStack spacing={2}>
                    <Icon as={FaTools} />
                    <Text>Tools</Text>
                  </HStack>
                </MenuButton>
                <MenuList bg="black" color="white">
                  <MenuItem
                    bg="black"
                    _hover={{ bg: 'rgba(255, 255, 255, 0.2)' }}
                    onClick={() => navigate('/dkim')}
                  >
                    DKIM
                  </MenuItem>
                  <MenuItem
                    bg="black"
                    _hover={{ bg: 'rgba(255, 255, 255, 0.2)' }}
                    onClick={() => navigate('/securityheaders')}
                  >
                    Security Headers
                  </MenuItem>
                  <MenuItem
                    bg="black"
                    _hover={{ bg: 'rgba(255, 255, 255, 0.2)' }}
                    onClick={() => navigate('/sitetechnology')}
                  >
                    Site Technology
                  </MenuItem>
                  <MenuItem
                    bg="black"
                    _hover={{ bg: 'rgba(255, 255, 255, 0.2)' }}
                    onClick={() => navigate('/url')}
                  >
                    URL Analysis
                  </MenuItem>
                </MenuList>
              </Menu>

              {/* Normal Button
              <Button
                bg="transparent"
                color="white"
                borderRadius="md"
                _hover={{ bg: 'rgba(0, 0, 0, 0.3)' }}
                onClick={() => navigate('/url')}
              >
                <HStack spacing={2}>
                  <Icon as={FaLink} />
                  <Text>URL Analysis</Text>
                </HStack>
              </Button> */}
            </Stack>

            {/* Input and Search Button */}
            <VStack spacing={4} width={containerWidth} align="center">
              <Input
                placeholder="Company Name"
                size="md"
                color="white"
                backgroundColor="rgba(255, 255, 255, 0.2)"
                borderRadius="md"
                _placeholder={{ color: 'white' }}
                border="1px solid white"
              />
              <Button
                colorScheme="teal"
                width={buttonWidth}
                borderRadius="20px"
                backgroundColor="rgba(0, 0, 0, 0.5)"
                _hover={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}
                onClick={() => navigate('/companyinfo')}
              >
                Search
              </Button>
            </VStack>
          </Box>
        </Center>
      </Box>
    </ChakraProvider>
  );
}

export default MainpageContainer;
