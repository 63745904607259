import React from 'react';
import { Box, Text, SimpleGrid } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import UserNavbar from '../../auth/UserNavbar';
import MainpageFooter from '../../MainpageFooter';

const TypoSquattingDetails = () => {
  const location = useLocation();
  const { typoSquatting } = location.state;

  // Function to decode the characters if necessary
  const decodeCharacters = (input) => {
    try {
      return decodeURIComponent(escape(input));
    } catch (e) {
      return input; // Return the input unchanged if decoding fails
    }
  };

  return (
    <div>
    <UserNavbar/>
    
 <div>
    <Box p={6}>
      <Text fontWeight="bold" fontSize="2xl" color="blue.900" textAlign="center" mb={4}>
        Typo Squatting Data
      </Text>

      {typoSquatting && typoSquatting.length > 0 ? (
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
          {typoSquatting.map((item, index) => (
            <Box key={index} p={4} borderWidth="1px" borderRadius="md" boxShadow="md">
              <Text>{decodeCharacters(item)}</Text>
            </Box>
          ))}
        </SimpleGrid>
      ) : (
        <Text fontSize="lg">No Typo Squatting Data Available</Text>
      )}
    </Box>
    <br></br>
         <MainpageFooter/>
            </div> 

        </div>
  );
};

export default TypoSquattingDetails;
