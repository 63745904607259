import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Image,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  useToast,
} from '@chakra-ui/react';

const DeepsearchResult = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const results = location.state?.data || []; // Safely accessing results


  // Load results from sessionStorage if available
  useEffect(() => {
    const checkAuthentication = () => {
      const accessToken = sessionStorage.getItem('accessToken'); // Access token should be stored in session storage

      if (!accessToken) {
        navigate('/signin'); // Redirect to sign-in page if no access token
        return;
      }
    };

    checkAuthentication();
  }, [navigate]);

  
  const handleLogoClick = () => {
    navigate('/');
  };

  const launchURL = (url) => {
    if (url) {
      try {
        window.open(url.trim(), '_blank').focus();
      } catch (e) {
        toast({
          title: 'Error',
          description: `Failed to open URL: ${url}`,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } else {
      toast({
        title: 'Error',
        description: 'URL is empty or invalid',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Container maxW="container.xl">
      <Flex as="nav" bg="black" p={4} justify="space-between">
        <Image
          src="assets/images/logo.png"
          alt="Logo"
          cursor="pointer"
          height="50px"
          onClick={handleLogoClick}
        />
      </Flex>

      {results.length > 0 ? (
        results.map((resultGroup, index) => {
         
          const searchType = resultGroup.searchType || 'N/A';
          const resultsList = Array.isArray(resultGroup.originalJson?.Results)
            ? resultGroup.originalJson.Results
            : []; // Ensure resultsList is an array

          return (
            <Box key={index} my={8}>
              <Heading as="h3" size="lg" color="red.500">
                {searchType}
              </Heading>

              <Box mt={4}>
                {resultsList.length > 0 ? (
                  <Box overflowX="auto">
                    <Table variant="striped" colorScheme="blue">
                      <Thead>
                        <Tr>
                          <Th>Title</Th>
                          <Th>Url</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {resultsList.map((resultItem, idx) => {
                          let url = 'N/A';
                          let title = 'N/A';

                          if (Array.isArray(resultItem)) {
                            if (resultItem.length === 2) {
                              if (typeof resultItem[0] === 'string' && typeof resultItem[1] === 'string') {
                                url = resultItem[0];
                                title = resultItem[1];
                              } else if (typeof resultItem[1] === 'object') {
                                url = resultItem[1].Url || 'N/A';
                                title = resultItem[1].Title || 'N/A';
                              }
                            }
                          } else if (typeof resultItem === 'object') {
                            url = resultItem.Url || 'N/A';
                            title = resultItem.Title || 'N/A';
                          }

                          return (
                            <Tr key={idx}>
                              <Td>{title}</Td>
                              <Td>
                                <Button variant="link" colorScheme="blue" onClick={() => launchURL(url)}>
                                  {url !== 'N/A' ? url : 'Invalid URL'}
                                </Button>
                              </Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                  </Box>
                ) : (
                  <Text>No results</Text>
                )}
              </Box>
            </Box>
          );
        })
      ) : (
        <Text>No search results available</Text>
      )}
    </Container>
  );
};

export default DeepsearchResult;
