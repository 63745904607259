// src/App.js
import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import AboutUs from './component/AboutUs';
import Feature from './component/Features';
import Pricing from './component/Pricing';
import Contact from './component/Contact';
import Home from './component/Home';
import LoginPage from './component/auth/loginPage';
import SignupPage from './component/auth/SignupPage';
import ConfirmUserPage from './component/auth/ConfirmUserPage';


import CompanyInformation from './component/servicesForMainpageContainer/CompanyInformation';
import DomainInformation from './component/servicesForMainpageContainer/DomainInformation';
import Subdomains from './component/servicesForMainpageContainer/Subdomains';

import CompanySearch from './component/servicesForMainpageContainer/CompanySearch';
import CompanySearchResult from './component/servicesForMainpageContainer/CompanySearchResult';
import DeepSearch from './component/servicesForMainpageContainer/DeepSearch';
import DeepSearchResult from './component/servicesForMainpageContainer/DeepSerchResult';
import HiddenSearch from './component/servicesForMainpageContainer/HiddenSearch';

import Dkim from './component/servicesForMainpageContainer/Dkim';
import SecurityHeaders from './component/servicesForMainpageContainer/SecurityHeaders';
import SiteTechnology from './component/servicesForMainpageContainer/SiteTechnology';
import UrlSearch from './component/servicesForMainpageContainer/UrlSearch';

import DomainInfoDetails from './component/servicesForMainpageContainer/companyInfoDetails/DominInfoDetails';
import EmailSecurityDetails from './component/servicesForMainpageContainer/companyInfoDetails/EmailSecurityDetails';
import SslCertificateDetails from './component/servicesForMainpageContainer/companyInfoDetails/SslCertificateDetails';
import TypeSquattingDetails from './component/servicesForMainpageContainer/companyInfoDetails/TypeSquattingDetails';
import BitSquattingDetails from './component/servicesForMainpageContainer/companyInfoDetails/BitSquattingDetails';
import HomoglyphsDetails from './component/servicesForMainpageContainer/companyInfoDetails/HomoglyphsDetails';
import SubdomainDetails from './component/servicesForMainpageContainer/companyInfoDetails/SubdomainDetails';
import LeaksDetails from './component/servicesForMainpageContainer/companyInfoDetails/LeaksDeatils';
import DomainQualityScoreDetails from './component/servicesForMainpageContainer/companyInfoDetails/DomainQualityScoreDetails';

import UserHomePage from './component/auth/UserHomePage';


import SearchComponent from './component/servicesForMainpageContainer/SearchComponent';


import InformationContent from './component/subcomponent/InformationContent';
import KeyServices from './component/subcomponent/KeyServices';
import KeyBenefits from './component/subcomponent/KeyBenefits';

import UpdatePlan from './component/UpdatePlan';
import OtherServices from './component/subcomponent/OtherServices';
import AdditionalServices from './component/subcomponent/AdditionalServices';


import ViewProfile from './component/auth/ViewProfile';
import EditProfile from './component/auth/EditProfile';

import TermsOfServices from './component/TermsOfServices';
import DomainQualityScoreCard from './component/servicesForMainpageContainer/companyInfoDetails/DomainQualityScoreCard';
import GetApi from './component/GetApi';
import PrivacyPolicy from './component/PrivacyPolicy';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);


  useEffect(() => {
    
    // Simulate an authentication check
    const checkAuthentication = async () => {
      // Replace this logic with actual authentication check
      const accessToken = sessionStorage.getItem('accessToken');
      setIsAuthenticated(!!accessToken); // Assuming accessToken is set when authenticated
    };

    checkAuthentication();
  }, []);

  return (
    <div>
      <Routes>
        <Route path="/" element={<Home isAuthenticated={isAuthenticated}/>} />
        <Route path="about" element={<AboutUs isAuthenticated={isAuthenticated}/>} />
        <Route path="features" element={<Feature isAuthenticated={isAuthenticated} />} />
        <Route path="pricing" element={<Pricing isAuthenticated={isAuthenticated}/>} />
        <Route path="contact" element={<Contact isAuthenticated={isAuthenticated}/>} />
        <Route path="signin" element={<LoginPage />} />
        <Route path="signup" element={<SignupPage />} />
        <Route path="confirm" element={<ConfirmUserPage />} />
        <Route path="apis" element={<GetApi isAuthenticated={isAuthenticated}/>}/>


        <Route path="companyinfo" element={<CompanyInformation />} />
        <Route path="domaininfo" element={<DomainInformation />} />
        <Route path="subdomain" element={<Subdomains />} />

        

        <Route path="companysearch" element={<CompanySearch />} />
        <Route path="companysearch_result" element={<CompanySearchResult />} />
        <Route path="deepsearch" element={<DeepSearch />} />
        <Route path="deepsearch_result" element={<DeepSearchResult />} />
        <Route path="hiddensearch" element={<HiddenSearch />} />

        <Route path="dkim" element={<Dkim />} />
        <Route path="securityheaders" element={<SecurityHeaders />} />
        <Route path="sitetechnology" element={<SiteTechnology />} />
        <Route path="url" element={<UrlSearch />} />

        <Route path="domain-info" element={<DomainInfoDetails />} />
        <Route path="email-security" element={<EmailSecurityDetails />} />
        <Route path="ssl-certificate" element={<SslCertificateDetails />} />
        <Route path="typo-squatting" element={<TypeSquattingDetails />} />
        <Route path="bit-squatting" element={<BitSquattingDetails />} />
        <Route path="homoglyphs-details" element={<HomoglyphsDetails />} />
        <Route path="subdomain-details" element={<SubdomainDetails />} />
        <Route path="leaks-details" element={<LeaksDetails />} />
        <Route path="domainqualitydetails" element={<DomainQualityScoreDetails/>} />

        <Route path="userhome" element={<UserHomePage />} />
        <Route path="profile" element={<ViewProfile />} />
        <Route path="profile_edit" element={<EditProfile />} />
        <Route path="searchcomponent" element={<SearchComponent />} />
        

        <Route path="informationcontent" element={<InformationContent isAuthenticated={isAuthenticated}/>} />
        <Route path="keyservices" element={<KeyServices />} />
        <Route path="keybenefits" element={<KeyBenefits />} />
        <Route path="updateplan" element={<UpdatePlan />} />

        <Route path="otherServices" element={<OtherServices isAuthenticated={isAuthenticated}/>} />
        <Route path="additionalServices" element={<AdditionalServices isAuthenticated={isAuthenticated}/>} />
        <Route path="termsofServices" element={<TermsOfServices/> }/>
        <Route path="privacyPolicy" element={<PrivacyPolicy/>}/>
        
      </Routes>
    </div>
  );
}

export default App;
