// components/UserNavbar.js
import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Image,
  Button,
  Avatar,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Stack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  Grid
} from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import { ChevronDownIcon, HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { FaDesktop, FaCog, FaStar, FaInfoCircle, FaDollarSign, FaEnvelope,FaSignInAlt,FaTools ,FaNetworkWired} from 'react-icons/fa';
import logo from '../../assets/images/logo.png';

function UserNavbar() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isProfileOpen, onOpen: onProfileOpen, onClose: onProfileClose } = useDisclosure();
  const [userEmail, setUserEmail] = useState(null);
  const [userPlan, setUserPlan] = useState(null);
  const navigate = useNavigate();  // Initialize useNavigate
  const [isAuthenticated, setIsAuthenticated] = useState(true); // Loading state for user fetching

  useEffect(() => {
    const email = sessionStorage.getItem('userEmail');
    const plan = sessionStorage.getItem('userPlan');
    if (email) {
      setUserEmail(email);
    }
    if (plan) {
      setUserPlan(plan);
    }
  }, []);

  const handleSignOut = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('isAuthenticated');
    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('userEmail');
    setUserEmail(null);
    onProfileClose();
    // Redirect immediately after the state change
    navigate('/');
    // Optionally, trigger a reload to ensure session changes are reflected immediately
    window.location.reload(); // This ensures immediate logout
  };
  

  const headerStyle = {
    fontSize: '16px',
    textAlign: 'center',
    padding: '10px',
    fontFamily:"Arial, sans-serif", 
    fontWeight:"normal"
  };

  const handleNavigation = (sectionId) => {
    navigate('/userhome', { state: { sectionId } });
  }; 
  const handleNavigation1 = (sectionId) => {
    navigate('/otherServices', { state: { sectionId } });
  }; 

  return (
    <Box bg="black" color="white" px={4}>
    <Flex h={16} alignItems="center" justifyContent="space-between">
      <Link to="/">
        <Image
          height="60px"
          width="100px"
          src={logo}
          alt="Logo"
          cursor="pointer"
        />
      </Link>
      <Flex alignItems="center">
        <Stack direction="row" spacing={2} display={{ base: 'none', md: 'flex' }}>
          <Menu>
            <MenuButton as={Button} rightIcon={<ChevronDownIcon />} variant="link" color="white" style={headerStyle}>
              <FaDesktop style={{ marginRight: '10px', display: "inline" }} />
              Platform
            </MenuButton>
            <MenuList bg="black" color="white">
              <MenuItem onClick={() => handleNavigation('company')} bg="black" color="white">Domain Risk Analysis</MenuItem>
              <MenuItem onClick={() => handleNavigation('company')} bg="black" color="white">Deep and Dark Analysis</MenuItem>
              {/* <MenuItem onClick={() => handleNavigation('additionalServices')} bg="black" color="white">Additional Services</MenuItem> */}
              {/* <MenuItem onClick={() => handleNavigation('urlRiskDetection')} bg="black" color="white">URL Risk Detection</MenuItem> */}
              {/* <MenuItem onClick={() => handleNavigation('otherServices')} bg="black" color="white">Other Services</MenuItem>*/}
               <MenuItem onClick={() => handleNavigation('additionalServices')} bg="black" color="white"> Code Analysis <i style={{ color: 'gold' }}>(coming soon)</i></MenuItem>
              <MenuItem onClick={() => handleNavigation('additionalServices')} bg="black" color="white"> Phishing Simulation <i style={{ color: 'gold' }}>(coming soon)</i></MenuItem>
              <MenuItem onClick={() => handleNavigation('additionalServices')} bg="black" color="white">Automated Penetration Testing <i style={{ color: 'gold' }}>(coming soon)</i></MenuItem>
            </MenuList>
          </Menu>
          <Menu>
            <MenuButton as={Button} rightIcon={<ChevronDownIcon />} variant="link" color="white" style={headerStyle}>
              <FaCog style={{ marginRight: '10px', display: "inline" }} />
              Services
            </MenuButton>
            <MenuList bg="black" color="white" >
            
              <MenuItem as={Link} to="/otherServices" bg="black" color="white">Manual Business Logic Testing</MenuItem>
              <MenuItem as={Link} to="/otherServices" bg="black" color="white">Cybersecurity Integration Services</MenuItem>
            </MenuList>
          </Menu>
          <Menu>
          <MenuButton as={Button} rightIcon={<ChevronDownIcon />} variant="link" color="white" style={headerStyle}>
<FaTools style={{ marginRight: '10px', display: 'inline' }} />
Tools
</MenuButton>
            <MenuList bg="black" color="white" >
              <MenuItem as={Link} to="/dkim" bg="black" color="white">DKIM</MenuItem>
              <MenuItem as={Link} to="/securityheaders" bg="black" color="white">Security Headers</MenuItem>
              <MenuItem as={Link} to="/sitetechnology" bg="black" color="white">Site Technology</MenuItem>
              <MenuItem as={Link} to="/url" bg="black" color="white">Url Analysis</MenuItem>
            </MenuList>
          </Menu>
          <Button as={Link} to="/apis" variant="link" color="white" style={headerStyle}>
          <FaNetworkWired style={{ marginRight: '10px', display: "flex" }} />API's</Button>

          <Button as={Link} to="/features" variant="link" color="white" style={headerStyle}>
            <FaStar style={{ marginRight: '10px', display: "flex" }} />Features</Button>
            <Button as={Link} to="/about" variant="link" color="white" style={headerStyle}>
              <FaInfoCircle style={{ marginRight: '10px', display: 'flex' }} />
              About Us
            </Button>

            <Button as={Link} to="/pricing" variant="link" color="white" style={headerStyle}>
              <FaDollarSign style={{ marginRight: '10px', display: 'flex' }} />
              Pricing
            </Button>

            <Button as={Link} to="/contact" variant="link" color="white" style={headerStyle} marginRight='20px'>
              <FaEnvelope style={{ marginRight: '10px', display: 'flex' }} />
              Contact
            </Button>
          </Stack>
          <Button
            display={{ base: 'flex', md: 'none' }}
            onClick={isOpen ? onClose : onOpen}
            variant="outline"
            color="white"
            margin='20px'
          >
            {isOpen ? <CloseIcon /> : <HamburgerIcon />}
          </Button>
          {userEmail ? (
            <Avatar size="md" name={userEmail} onClick={onProfileOpen} cursor="pointer" />
          ) :
            null
          }
        </Flex>
      </Flex>

      {/* Mobile Menu */}
      {isOpen ? (
       <Box pb={4} display={{ md: 'none' }}>
       <Flex justify="center" align="center">
         <Grid
           templateColumns="repeat(auto-fit, minmax(150px, 1fr))"
           gap={6}
           w="100%"
           maxW="600px"
           alignItems="center"
         >
            {/* Platform Menu */}
            <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                  justifyContent="center"
                  variant="solid"
                  color="white"
                  bg="black.600"
                  border="2px solid"
                  borderColor="gray.700"
                  _hover={{ bg: 'gray.600' }}
                  display="flex"
                  alignItems="center"
                  p={4}
                  borderRadius="md"
                >
                  <Flex justifyContent="center" alignItems="center">
                    <FaDesktop style={{ marginRight: '8px' }} />
                    Platform
                  </Flex>
                </MenuButton>
                <MenuList bg="black" color="white">
                <MenuItem onClick={() => handleNavigation('company')} bg="black" color="white">Domain Risk Analysis</MenuItem>
                <MenuItem onClick={() => handleNavigation('company')} bg="black" color="white">Deep and Dark Analysis</MenuItem>
                {/* <MenuItem onClick={() => handleNavigation('additionalServices')} bg="black" color="white">Additional Services</MenuItem> */}
                {/* <MenuItem onClick={() => handleNavigation('urlRiskDetection')} bg="black" color="white">URL Risk Detection</MenuItem> */}
                {/* <MenuItem onClick={() => handleNavigation('otherServices')} bg="black" color="white">Other Services</MenuItem>*/}
                 <MenuItem onClick={() => handleNavigation('additionalServices')} bg="black" color="white"> Code Analysis <i style={{ color: 'gold' }}>(coming soon)</i></MenuItem>
                <MenuItem onClick={() => handleNavigation('additionalServices')} bg="black" color="white"> Phishing Simulation <i style={{ color: 'gold' }}>(coming soon)</i></MenuItem>
                <MenuItem onClick={() => handleNavigation('additionalServices')} bg="black" color="white">Automated Penetration Testing <i style={{ color: 'gold' }}>(coming soon)</i></MenuItem>
              </MenuList>
              </Menu>

              {/* Services Menu */}
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                  variant="solid"
                  color="white"
                  bg="black.600"
                  border="2px solid"
                  borderColor="gray.700"
                  _hover={{ bg: 'gray.600' }}
                  display="flex"
                  alignItems="center"
                  p={4}
                  borderRadius="md"
                >
                  <Flex justifyContent="center" alignItems="center">
                    <FaCog style={{ marginRight: '8px' }} />
                    Services
                  </Flex>
                </MenuButton>
                <MenuList bg="black" color="white" >
              
              <MenuItem as={Link} to="/otherServices" bg="black" color="white">Manual Business Logic Testing</MenuItem>
              <MenuItem as={Link} to="/otherServices" bg="black" color="white">Cybersecurity Integration Services</MenuItem>
            </MenuList>
              </Menu>

{/* Services Menu */}
<Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                  variant="solid"
                  color="white"
                  bg="black.600"
                  border="2px solid"
                  borderColor="gray.700"
                  _hover={{ bg: 'gray.600' }}
                  display="flex"
                  alignItems="center"
                  p={4}
                  borderRadius="md"
                >
                  <Flex justifyContent="center" alignItems="center">
                    <FaTools style={{ marginRight: '8px' }} />
                    Tools
                  </Flex>
                </MenuButton>
                <MenuList bg="black" color="white" >
                <MenuItem as={Link} to="/dkim" bg="black" color="white">DKIM</MenuItem>
                <MenuItem as={Link} to="/securityheaders" bg="black" color="white">Security Headers</MenuItem>
                <MenuItem as={Link} to="/sitetechnology" bg="black" color="white">Site Technology</MenuItem>
                <MenuItem as={Link} to="/url" bg="black" color="white">Url Analysis</MenuItem>
              </MenuList>
              </Menu>

  {/* Other Buttons */}
  <Button
                as={Link}
                to="/apis"
                variant="solid"
                color="white"
                bg="black.600"
                  border="2px solid"
                  borderColor="gray.700"
                  _hover={{ bg: 'gray.600' }}
                display="flex"
                justifyContent="center"
                alignItems="center"
                p={4}
                borderRadius="md"
              >
                <FaNetworkWired style={{ marginRight: '8px' }} /> API's
              </Button>
              {/* Other Buttons */}
              <Button
                as={Link}
                to="/features"
                variant="solid"
                color="white"
                bg="black.600"
                  border="2px solid"
                  borderColor="gray.700"
                  _hover={{ bg: 'gray.600' }}
                display="flex"
                justifyContent="center"
                alignItems="center"
                p={4}
                borderRadius="md"
              >
                <FaStar style={{ marginRight: '8px' }} /> Features
              </Button>

           <Button
             as={Link}
             to="/about"
             variant="solid"
             color="white"
             bg="black.600"
               border="2px solid"
               borderColor="gray.700"
               _hover={{ bg: 'gray.600' }}
             display="flex"
             alignItems="center"
             p={4}
             borderRadius="md"
           >
             <FaInfoCircle style={{ marginRight: '8px' }} /> About Us
           </Button>

           <Button
             as={Link}
             to="/pricing"
             variant="solid"
             color="white"
             bg="black.600"
               border="2px solid"
               borderColor="gray.700"
               _hover={{ bg: 'gray.600' }}
             display="flex"
             alignItems="center"
             p={4}
             borderRadius="md"
           >
             <FaDollarSign style={{ marginRight: '8px' }} /> Pricing
           </Button>

           <Button
             as={Link}
             to="/contact"
             variant="solid"
             color="white"
             bg="black.600"
               border="2px solid"
               borderColor="gray.700"
               _hover={{ bg: 'gray.600' }}
             display="flex"
             alignItems="center"
             p={4}
             borderRadius="md"
           >
             <FaEnvelope style={{ marginRight: '8px' }} /> Contact
           </Button>
         </Grid>
       </Flex>
     </Box>
      ) : null}

      {/* User Profile Drawer */}
      <Drawer isOpen={isProfileOpen} placement="right" onClose={onProfileClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <center>
            <DrawerHeader>User Profile</DrawerHeader>
          </center>
          <DrawerBody>
            <center>
              <Avatar size="xl" mb={4} name={userEmail} />
              <Box mb={4} fontSize="lg" fontWeight="bold">
                {userEmail}
              </Box>
              <Box mb={4} fontSize="lg" fontWeight="bold">
                {userPlan}
              </Box>
              <Button as={Link} to="/profile" mb={4} variant="outline" width="100%">
                View Profile
              </Button>
              <Button as={Link} to="/profile_edit" mb={4} variant="outline" width="100%">
                Settings
              </Button>
              <Button colorScheme="red" variant="solid" width="100%" onClick={handleSignOut}>
                Sign Out
              </Button>
            
            </center>
          </DrawerBody>
          <DrawerFooter>
            <Button colorScheme="red" onClick={onProfileClose}>
              Close
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Box>
  );
}

export default UserNavbar;
