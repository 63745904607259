import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { 
  Box, 
  Input, 
  VStack,  
  Table, 
  Thead, 
  Tbody, 
  Tr, 
  Th, 
  Td, 
  Text, 
  Button, 
  Center, 
  useBreakpointValue, 
  Menu, 
  MenuButton, 
  MenuItem, 
  MenuList, 
  Stack, 
  HStack, 
  Icon, 
  Spinner ,useToast
} from '@chakra-ui/react';
import { FaBuilding, FaInfoCircle, FaQuestionCircle, FaLink, FaChevronDown ,FaTools} from 'react-icons/fa'; // Added ChevronDown for dropdown icon
import ImageOne from '../../assets/images/Global_network_generated.jpg';
import UserNavbar from '../auth/UserNavbar';
import MainpageFooter from '../MainpageFooter';
import MainComponent from '../Maincomponent';
import {getToken } from '../auth/loginPage';

const TechStackComponent = () => {
  const [domain, setDomain] = useState('');
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [, setError] = useState(null);
  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const [message, setMessage] = useState('');
  const toast = useToast();

      // Define responsive values for padding, font size, etc.
const paddingX = useBreakpointValue({ base: '4', md: '6', lg: '8' });
const buttonWidth = useBreakpointValue({ base: '80%', sm: '60%', md: '50%' });
const containerWidth = useBreakpointValue({ base: '90%', md: '70%', lg: '60%' });
const containerHeight = useBreakpointValue({ base: '70%', md: '60%', lg: '50%' });

  // Load results from sessionStorage if available
  useEffect(() => {
    const checkAuthentication = () => {
      const storedToken = getToken(); // Retrieve token

      setToken(storedToken);
      const accessToken = sessionStorage.getItem('accessToken'); // Access token should be stored in session storage
  
      if (!accessToken) {
        navigate('/signin'); // Redirect to sign-in page if no access token
        return;
      }
    };
  
    checkAuthentication();
  }, [navigate]);
  

  const fetchTechStack = async (domain) => {
    setIsLoading(true);
    setError(null);
    if (!token) {
      setMessage('Token is missing. Please sign in first.');
      return;
    }
    try {
      const encodedDomain = encodeURIComponent(domain);
      const response = await axios.get(
        `https://g1o62ob9e6.execute-api.ap-south-1.amazonaws.com/v1/api/domain_tools/find_tech_stack?domain=${encodedDomain}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
      });
      setData(response.data.tech_details); // Get the tech details
      const result = await response.json();
      console.log(result) 
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFetchClick = () => {
    if (domain) {
      fetchTechStack(domain);
    }
  };

 // Prepare table data, excluding 'Domain', 'Overview', and keys that start with 'Website Quality AlertsFound'
const prepareTableData = () => {
  if (!data) return [];

  return Object.entries(data)
    .filter(([key]) => 
      key !== 'Domain' && 
      key !== 'Overview' && 
      !key.startsWith('Website Quality AlertsFound') // Exclude keys starting with this string
    );
};

const tableData = prepareTableData();


  return (

    <div>
    <div
    style={{
      position: 'fixed',                         // Fix the position of the navbar
      top: 0,                                    // Stick it to the top
      left: 0,                                   // Align it to the left edge
      width: '100%',                             // Full width for the navbar
      zIndex: 1000,                              // Ensure it's above other content
    }}
  >
   <UserNavbar /> 
  </div>
    
 <div>
    <Box
    height={{ base: '60vh', md: '60vh', lg: '60vh' }}
    backgroundImage={`url(${ImageOne})`} 
    backgroundSize="cover"
    backgroundPosition="center"
    position="relative"
  >
    <Center height="100%">
      <Box
        width={containerWidth}
        height={containerHeight}
        backgroundColor="rgba(0, 0, 0, 0.6)"
        color="white"
        borderRadius="20px"
        paddingX={paddingX}
        paddingY={{ base: '4', md: '6', lg: '8' }}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
      >
        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: '4', md: '6' }}
          align="center"
          justify="center"
          mb={6}
          wrap="wrap" // Ensure that items wrap if needed
          gap={{ base: '4', md: '6' }} // Responsive gap
        >

          {/* Normal Button */}
          <Button
            bg="transparent"
            color="white"
            borderRadius="md"
            _hover={{ bg: 'rgba(0, 0, 0, 0.3)' }}
            onClick={() => navigate('/companyinfo')}
          >
            <HStack spacing={2}>
            <Icon as={FaBuilding} />
              <Text>Domain Risk Analysis</Text>
            </HStack>
          </Button>
    

          {/* Normal Button */}
        <Button
                bg="transparent"
                color="white"
                borderRadius="md"
                _hover={{ bg: 'rgba(0, 0, 0, 0.3)' }}
                onClick={() => navigate('/companysearch')}
              >
                <HStack spacing={2}>
                <Icon as={FaInfoCircle} />
                  <Text>Deep and Dark Search</Text>
                </HStack>
              </Button>
          {/* Dropdown Button 3 */}
          <Menu>
            <MenuButton
              as={Button}
              bg="transparent"
              color="white"
              borderRadius="md"
              _hover={{ bg: 'rgba(0, 0, 0, 0.3)' }}
              rightIcon={<FaChevronDown />}
            >
              <HStack spacing={2}>
                <Icon as={FaTools} />
                <Text> Site Technology</Text>
              </HStack>
            </MenuButton>
            <MenuList bg="black" color="white">
              <MenuItem
                bg="black"
                _hover={{ bg: 'rgba(255, 255, 255, 0.2)' }}
                onClick={() => navigate('/dkim')}
              >
                DKIM
              </MenuItem>
              <MenuItem
                bg="black"
                _hover={{ bg: 'rgba(255, 255, 255, 0.2)' }}
                onClick={() => navigate('/securityheaders')}
              >
                Security Headers
              </MenuItem>
              <MenuItem
                    bg="black"
                    _hover={{ bg: 'rgba(255, 255, 255, 0.2)' }}
                    onClick={() => navigate('/url')}
                  >
                    URL Analysis
                  </MenuItem>
            </MenuList>
          </Menu>

          {/* Normal Button */}
          {/* <Button
            bg="transparent"
            color="white"
            borderRadius="md"
            _hover={{ bg: 'rgba(0, 0, 0, 0.3)' }}
            onClick={() => navigate('/url')}
          >
            <HStack spacing={2}>
              <Icon as={FaLink} />
              <Text>URL Analysis</Text>
            </HStack>
          </Button> */}
        </Stack>

        {/* Input and Search Button */}
        <VStack spacing={4} width={containerWidth} align="center">
          <Input
            placeholder="Company Name: 'example.com'"
            value={domain}
            onChange={(e) => setDomain(e.target.value)}
            size="md"
            color="white"
            backgroundColor="rgba(255, 255, 255, 0.2)"
            borderRadius="md"
            _placeholder={{ color: 'white' }}
            border="1px solid white"
          />
          {/* <Button
            colorScheme="teal"
            width={buttonWidth}
            borderRadius="20px"
            backgroundColor="rgba(0, 0, 0, 0.5)"
            _hover={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}
            onClick={handleFetchClick}
          >
            Search
            </Button> */}
            <MainComponent domain={domain} onSuccess={handleFetchClick} />
            </VStack>
          </Box>
        </Center>
      </Box>
    <br></br>
    <center>
                {isLoading && <Spinner size="xl" />}
        
                </center>


    {/* <Box padding="20px" borderWidth="2px" borderRadius="md" boxShadow="md">
      <VStack spacing="4" align="start">
        <Text fontSize="xl" fontWeight="bold">Tech Stack Information:</Text> */}

        {/* <Box mb="4">
          <Input
            placeholder="Enter domain"
            value={domain}
            onChange={(e) => setDomain(e.target.value)}
            mb="2"
          />
        
          
        <MainComponent domain={domain} onSuccess={handleFetchClick} />
      

        </Box>

       
        {loading && <Text>Loading...</Text>}
        {error && <Text color="red.500">Error: {error}</Text>} */}

       {/* Data Table */}
{data && (
  <Box
    width="100%"
    overflowX="auto"
    bg="gray.50"
    borderRadius="md"
    boxShadow="lg"
    padding="6"
    marginY="6"
  >
    <Table
      variant="simple"
      border="2px solid"
      borderColor="gray.200"
      borderRadius="md"
      overflow="hidden"
      size="md"
    >
      <Thead bg="teal.600">
        <Tr>
          <Th
            color="white"
            textAlign="center"
            fontSize="lg"
            padding="16px"
            textTransform="uppercase"
            letterSpacing="wide"
            fontWeight="bold"
          >
            Key
          </Th>
          <Th
            color="white"
            textAlign="center"
            fontSize="lg"
            padding="16px"
            textTransform="uppercase"
            letterSpacing="wide"
            fontWeight="bold"
          >
            Value
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {tableData.map(([key, value], index) => (
          <Tr
            key={index}
            _hover={{ bg: "gray.100" }}
            transition="background-color 0.3s ease"
          >
            <Td
              fontWeight="bold"
              bg="gray.50"
              borderRight="1px solid"
              borderColor="gray.300"
              padding="16px"
              textAlign="center"
              color="teal.700"
              fontSize="md"
            >
              {key}
            </Td>
            <Td
              bg="white"
              color="gray.800"
              padding="16px"
              textAlign="center"
              fontSize="md"
              wordBreak="break-word"
            >
              {value}
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  </Box>
)}

     <br></br>
         <MainpageFooter/>
            </div> 

        </div>
  );
};

export default TechStackComponent;
