import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Input, Box, Table, Thead, Tbody, Tr, Th, Td, Spinner, useToast } from '@chakra-ui/react';

const JsonDataDisplay = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const toast = useToast();
  const navigate = useNavigate();

  // Load results from sessionStorage if available
  useEffect(() => {
    const checkAuthentication = () => {
      const accessToken = sessionStorage.getItem('accessToken'); // Access token should be stored in session storage
  
      if (!accessToken) {
        navigate('/signin'); // Redirect to sign-in page if no access token
        return;
      }
    }; 
  
    checkAuthentication();
  }, [navigate]);

  const fetchData = async () => {
    if (!searchTerm.trim()) {
      toast({
        title: "Search term is required.",
        status: "warning", 
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setLoading(true);
    setError(null);
    setData([]);

    try {
      const response = await fetch(`https://rdvcaeqh7s5a6nhlkswsjoytyy0jdzlx.lambda-url.ap-south-1.on.aws/api/deepweb/hidden_search/?query=${encodeURIComponent(searchTerm)}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'api-key': 'AQICAHi610eH2JqZkg2KBFmEoZDpxMsSdrGwwWa-T9g-i5VsgQHrW4jbEQirSb0W0T9RWPKjAAAAYzBhBgkqhkiG9w0BBwagVDBSAgEAME0GCSqGSIb3DQEHATAeBglghkgBZQMEAS4wEQQMX8NyNj4SvlwHQTA-AgEQgCArZn5rLd5grOj2fHyqEmlR8J4h2RsvlFxcmUkHJ4w8Qg',
            'access-token':'ngopi',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      setData(result);
    } catch (err) {
      setError(err.message);
    }

    setLoading(false);
  };

  return (
    <Box p={4}>
      <Input
        placeholder="Enter Search Term"
        mb={4}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <Button onClick={fetchData} colorScheme="teal" disabled={loading}>
        {loading ? <Spinner size="sm" /> : 'Fetch Data'}
      </Button>

      {error && <Box mt={4} color="red.500">{error}</Box>}

      {data && data.length > 0 && (
        <Table mt={4} variant="simple" border="1px" borderColor="black">
          <Thead>
            <Tr>
              <Th textAlign="center" fontWeight="bold" border="1px" borderColor="black">URL</Th>
              <Th textAlign="center" fontWeight="bold" border="1px" borderColor="black">Description</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.map((item, index) => (
              <Tr key={index}>
                <Td textAlign="center" border="1px" borderColor="black">{item.url}</Td>
                <Td textAlign="center" border="1px" borderColor="black">{item.description || 'No description provided'}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
      {data && data.length === 0 && <Box mt={4} textAlign="center">No data available</Box>}
    </Box>
  );
};

export default JsonDataDisplay;
