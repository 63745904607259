// src/component/Home.js
import React, { useEffect } from 'react';
import Navbar from './Navbar';
import UserNavbar from './auth/UserNavbar';
import MainpageSubContainer from './MainpageSubContainer';
import MainpageFooter from './MainpageFooter';
import { Outlet } from 'react-router-dom';
// import UserNavbar from './auth/UserNavbar';

function Home({ isAuthenticated }) {
  useEffect(() => {
    
  }, [isAuthenticated]);
  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
     <div
    style={{
      position: 'fixed',                         // Fix the position of the navbar
      top: 0,                                    // Stick it to the top
      left: 0,                                   // Align it to the left edge
      width: '100%',                             // Full width for the navbar
      zIndex: 1000,                              // Ensure it's above other content
    }}
  >
    {isAuthenticated ? <UserNavbar /> : <Navbar />}
  </div>
  <br /><br />

      <Outlet />
      <MainpageSubContainer /> 

      <MainpageFooter />
    </div>
  );
}

export default Home;
