import React, { useEffect } from 'react';
import {
  Box,
  Heading,
  Text,
  SimpleGrid,
  Button,
  VStack,
  useColorModeValue,
  Container,
  Divider,
  Icon,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import Navbar from './Navbar'; // Ensure to import your Navbar component
import MainpageFooter from './MainpageFooter'; // Ensure to import your Footer component
import { MdContactMail } from 'react-icons/md'; // For the contact icon
import UserNavbar from './auth/UserNavbar';
import { useNavigate } from 'react-router-dom';

export default function ApiList({ isAuthenticated }) {
  const navigate = useNavigate();

  useEffect(() => {
    console.log('isAuthenticated:', isAuthenticated);
  }, [isAuthenticated]);

  // Set the background color for the cards
  const cardBgColor = useColorModeValue('white', 'gray.700');
  
  return (
    <Box   position="relative"
    minH="100vh"
    bgGradient="linear(to-b, blue.900, blue.700)">
      <Box
          position="fixed"
          top={0}
          left={0}
          width="100%"
          zIndex={1000}
          bgColor="rgba(255, 255, 255, 0.7)"
        >
          {isAuthenticated ? <UserNavbar /> : <Navbar />}
        </Box>
        <br /><br />

      <Container 
        maxW="container.xl" 
        p={10} 
        minHeight="100vh" >
      
        <Heading as="h1" size="2xl" textAlign="center" mb={10} color="white">
          Available APIs
        </Heading>
        
        <Text textAlign="center" fontSize="lg" mb={8} color="white">
          Our APIs offer powerful tools to enhance your security posture. Explore the options below to find the right API for your needs.
        </Text>

        <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={10} mb={10}>
           {/* DKIM API Card */}
           <Box 
            bg={cardBgColor} 
            shadow="lg" 
            borderRadius="lg" 
            p={6} 
            textAlign="center" 
            transition="transform 0.2s" 
            _hover={{ transform: 'scale(1.05)', shadow: 'xl' }}
          >
            <Heading as="h2" size="lg" mb={4}>Domain Risk Analysis</Heading>
            <Text mb={6}>
              Check the Domain Risk Analysis Output
            </Text>
            <Button as={Link} to="/companyinfo" colorScheme="teal" variant="solid">
              Try the Output
            </Button>
          </Box>
          
           {/* DKIM API Card */}
           <Box 
            bg={cardBgColor} 
            shadow="lg" 
            borderRadius="lg" 
            p={6} 
            textAlign="center" 
            transition="transform 0.2s" 
            _hover={{ transform: 'scale(1.05)', shadow: 'xl' }}
          >
            <Heading as="h2" size="lg" mb={4}>Deep and Dark Search</Heading>
            <Text mb={6}>
            Check the Deep and Dark Search Output
            </Text>
            <Button as={Link} to="/companysearch" colorScheme="teal" variant="solid">
              Try the Output
            </Button>
          </Box>
          
          
          {/* DKIM API Card */}
          <Box 
            bg={cardBgColor} 
            shadow="lg" 
            borderRadius="lg" 
            p={6} 
            textAlign="center" 
            transition="transform 0.2s" 
            _hover={{ transform: 'scale(1.05)', shadow: 'xl' }}
          >
            <Heading as="h2" size="lg" mb={4}>DKIM API</Heading>
            <Text mb={6}>
              Validate and check DKIM signatures for email security.
            </Text>
            <Button as={Link} to="/dkim" colorScheme="teal" variant="solid">
              Try the Output
            </Button>
          </Box>
          
          {/* Security Headers API Card */}
          <Box 
            bg={cardBgColor} 
            shadow="lg" 
            borderRadius="lg" 
            p={6} 
            textAlign="center" 
            transition="transform 0.2s" 
            _hover={{ transform: 'scale(1.05)', shadow: 'xl' }}
          >
            <Heading as="h2" size="lg" mb={4}>Security Headers API</Heading>
            <Text mb={6}>
              Analyze website security headers for vulnerabilities.
            </Text>
            <Button as={Link} to="/securityheaders" colorScheme="teal" variant="solid">
              Try the Output
            </Button>
          </Box>

          {/* Site Technology API Card */}
          <Box 
            bg={cardBgColor} 
            shadow="lg" 
            borderRadius="lg" 
            p={6} 
            textAlign="center" 
            transition="transform 0.2s" 
            _hover={{ transform: 'scale(1.05)', shadow: 'xl' }}
          >
            <Heading as="h2" size="lg" mb={4}>Site Technology API</Heading>
            <Text mb={6}>
              Identify the technologies used by any website.
            </Text>
            <Button as={Link} to="/sitetechnology" colorScheme="teal" variant="solid">
              Try the Output
            </Button>
          </Box>

          {/* URL Analysis API Card */}
          <Box 
            bg={cardBgColor} 
            shadow="lg" 
            borderRadius="lg" 
            p={6} 
            textAlign="center" 
            transition="transform 0.2s" 
            _hover={{ transform: 'scale(1.05)', shadow: 'xl' }}
          >
            <Heading as="h2" size="lg" mb={4}>URL Analysis API</Heading>
            <Text mb={6}>
              Analyze the safety and performance of any URL.
            </Text>
            <Button as={Link} to="/url" colorScheme="teal" variant="solid">
              Try the Output
            </Button>
          </Box>
          
        </SimpleGrid>

        <Divider my={10} />

        {/* Contact Section */}
        <VStack spacing={4} textAlign="center" mb={10}>
          <Heading as="h3" size="xl" color="white">
            Interested in Our APIs?
          </Heading>
          <Text fontSize="lg" color="white">
            If you would like to get access to any of these APIs or have questions, feel free to contact us!
          </Text>
          <Button 
            colorScheme="teal" 
            variant="solid" 
            leftIcon={<Icon as={MdContactMail} />}
            onClick={() => window.location.href = 'mailto:your-email@example.com'} // Replace with your email
          >
            Contact Us
          </Button>
        </VStack>
      </Container>

      <MainpageFooter /> {/* Include the Footer at the bottom */}
    </Box>
  );
}
