import React, { useEffect, useState } from 'react';
import {
  Box,
  Input,
  Button,
  FormControl,
  FormLabel,
  VStack,
  Heading,
  useToast,
  IconButton,
  InputGroup,
  InputRightElement,
  Text
} from '@chakra-ui/react';
import {
  CognitoIdentityProviderClient,
  UpdateUserAttributesCommand,
  GetUserCommand,
  ChangePasswordCommand,
  InitiateAuthCommand,
} from '@aws-sdk/client-cognito-identity-provider';
import { useNavigate } from 'react-router-dom';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';


// Initialize the CognitoIdentityProviderClient with the region from environment variables
const cognitoClient = new CognitoIdentityProviderClient({
  region: process.env.REACT_APP_AWS_REGION, // Ensure this is correctly read
});


// Define common input styles
const inputStyles = {
  width: '100%', // Full width
  maxWidth: '400px', // Max width for larger screens
  variant: 'outline',
  size: 'md',
};


const EditProfile = () => {
  const [userDetails, setUserDetails] = useState({
    firstName: '',
    lastName: '',
    email: '',
    currentPlan: '',
  });

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isPasswordVerified, setIsPasswordVerified] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const accessToken = sessionStorage.getItem('accessToken');
        const command = new GetUserCommand({
          AccessToken: accessToken,
        });
        const { UserAttributes } = await cognitoClient.send(command);

        const attributes = UserAttributes.reduce((acc, attr) => {
          acc[attr.Name] = attr.Value;
          return acc;
        }, {});

        setUserDetails({
          firstName: attributes['name'] || '',
          lastName: '', // Add lastName if available or needed
          email: attributes['email'] || '',
          currentPlan: attributes['custom:plan'] || '',
        });
      } catch (error) {
        console.error('Error fetching user details:', error);
        navigate('/'); // Redirect if the user is not authenticated
      }
    };

    fetchUserDetails();
  }, [navigate]);

  const handleVerifyPassword = async () => {
    try {
      const command = new InitiateAuthCommand({
        AuthFlow: 'USER_PASSWORD_AUTH',
        ClientId: process.env.REACT_APP_CLIENT_ID,
        AuthParameters: {
          USERNAME: userDetails.email,
          PASSWORD: currentPassword,
        },
      });

      await cognitoClient.send(command);
      setIsPasswordVerified(true);
      setIsVerified(true); // Set verified state to true
      toast({
        title: 'Password Verified',
        description: 'You are now authorized to change your password.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error verifying password:', error);
      setIsPasswordVerified(false);
      setIsVerified(false); // Ensure the button remains blue on failure
      toast({
        title: 'Verification Failed',
        description: 'The current password is incorrect. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleChangePassword = async () => {
    if (!isPasswordVerified) {
      toast({
        title: 'Unauthorized',
        description: 'Please verify your current password first.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (newPassword === currentPassword) {
      toast({
        title: 'Invalid Password',
        description: 'You are using the same password as your current password. Please choose a different password.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (newPassword !== confirmPassword) {
      toast({
        title: 'Password Mismatch',
        description: 'New password and confirmation password do not match.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      const command = new ChangePasswordCommand({
        AccessToken: sessionStorage.getItem('accessToken'),
        PreviousPassword: currentPassword,
        ProposedPassword: newPassword,
      });
      await cognitoClient.send(command);
      toast({
        title: 'Password Changed',
        description: 'Your password has been changed successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
      setIsPasswordVerified(false); // Reset verification after changing the password
    } catch (error) {
      console.error('Error changing password:', error);
      toast({
        title: 'Error',
        description: 'An error occurred while changing your password.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleUpdate = async () => {
    try {
      const accessToken = sessionStorage.getItem('accessToken');
      const command = new UpdateUserAttributesCommand({
        AccessToken: accessToken,
        UserAttributes: [
          { Name: 'name', Value: userDetails.firstName },
          { Name: 'email', Value: userDetails.email },
          { Name: 'custom:plan', Value: userDetails.currentPlan },
        ],
      });
      await cognitoClient.send(command);
      toast({
        title: 'Profile updated.',
        description: 'Your profile has been updated successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      navigate('/profile'); // Redirect after successful update
    } catch (error) {
      console.error('Error updating profile:', error);
      toast({
        title: 'Error',
        description: 'An error occurred while updating your profile.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <VStack spacing={4} align="center" p={8} bg="gray.100" borderRadius="lg">
      <Heading as="h3" size="lg">
        Edit Profile
      </Heading>
      <Text fontSize="lg" color="gray.600">
        Current Plan: {userDetails.currentPlan.charAt(0).toUpperCase() + userDetails.currentPlan.slice(1)}
      </Text>
      <Box width="100%">
        <FormControl id="firstName" mb={4}>
          <FormLabel>First Name</FormLabel>
          <Input
            {...inputStyles}
            value={userDetails.firstName}
            onChange={(e) => setUserDetails({ ...userDetails, firstName: e.target.value })}
          />
        </FormControl>

        <FormControl id="email" mb={4}>
          <FormLabel>Primary Email</FormLabel>
          <Input {...inputStyles} type="email" value={userDetails.email} isDisabled />
        </FormControl>
        
        <FormControl id="currentPassword" mb={4}>
          <FormLabel>Current Password</FormLabel>
          <InputGroup>
            <Input
              {...inputStyles}
              type={showCurrentPassword ? 'text' : 'password'}
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
            <InputRightElement>
              <IconButton
                variant="link"
                aria-label={showCurrentPassword ? 'Hide password' : 'Show password'}
                icon={showCurrentPassword ? <ViewOffIcon /> : <ViewIcon />}
                onClick={() => setShowCurrentPassword(!showCurrentPassword)}
              />
            </InputRightElement>
          </InputGroup>
        </FormControl>
        
        <FormControl id="newPassword" mb={4}>
          <FormLabel>New Password</FormLabel>
          <InputGroup>
            <Input
              {...inputStyles}
              type={showNewPassword ? 'text' : 'password'}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <InputRightElement>
              <IconButton
                variant="link"
                aria-label={showNewPassword ? 'Hide password' : 'Show password'}
                icon={showNewPassword ? <ViewOffIcon /> : <ViewIcon />}
                onClick={() => setShowNewPassword(!showNewPassword)}
              />
            </InputRightElement>
          </InputGroup>
        </FormControl>
        
        <FormControl id="confirmPassword" mb={4}>
          <FormLabel>Confirm New Password</FormLabel>
          <InputGroup>
            <Input
              {...inputStyles}
              type={showConfirmPassword ? 'text' : 'password'}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <InputRightElement>
              <IconButton
                variant="link"
                aria-label={showConfirmPassword ? 'Hide password' : 'Show password'}
                icon={showConfirmPassword ? <ViewOffIcon /> : <ViewIcon />}
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              />
            </InputRightElement>
          </InputGroup>
        </FormControl>
        
        <Button colorScheme="blue" onClick={handleUpdate}>
          Update
        </Button>

        <Button
          colorScheme="blue"
          onClick={handleChangePassword}
          isDisabled={!isPasswordVerified}
          mt={4}
        >
          Change Password
        </Button>
      </Box>
    </VStack>
  );
};

export default EditProfile;
