import React, { useEffect } from 'react';
import {
    Box,
    Heading,
    Text,
    SimpleGrid,
    Container,
    Image,
    Flex,
    Button,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { ChevronRightIcon, InfoOutlineIcon } from '@chakra-ui/icons'; // Importing icons from Chakra UI
import cyber from '../../assets/images/cybersecurity.jpg';
import { useNavigate } from 'react-router-dom';
import UserNavbar from '../auth/UserNavbar';
import Navbar from '../Navbar';
import phishing from '../../assets/images/phishing.avif';
import automated from '../../assets/images/automated.avif';
import business from '../../assets/images/business.jpg';
import analysis from '../../assets/images/analysis.avif';


import MainpageFooter from '../MainpageFooter';


const MotionBox = motion(Box);

const KeyServices = ({ isAuthenticated }) => {

    useEffect(() => {
    
    }, [isAuthenticated]);

    const animationVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
    };

    const navigate = useNavigate();
 

    const getPricing = () => {
        navigate('/pricing');

    };

    const getContact = () => {
        navigate('/contact');

    };

    return (

        <div>
           {isAuthenticated ? <UserNavbar /> : <Navbar />}

            <Box css={{ scrollBehavior: 'smooth', overflowY: 'auto', height: '100vh' }}
                         flex={1} textAlign={['center', 'center', 'left']}>
                <Container maxW="7xl" mt={[5, 5]}>
                    <MotionBox
                        initial="hidden"
                        animate="visible"
                        variants={animationVariants}
                        textAlign="center"
                        py={[10, 20]}
                        backgroundImage={`url(${cyber})`}
                        backgroundPosition="center"
                        backgroundRepeat="no-repeat"
                        backgroundSize="cover"
                        borderRadius="lg"
                        boxShadow="lg"
                        color="white"
                        mb={[10, 20]} // Add margin bottom to create space between sections
                    >
                        <Heading as="h1" size={["xl", "2xl"]} mb={5} color="black.900">
                            Other Services
                        </Heading>
                        <Text fontSize={["lg", "xl"]} color="gray.100">
                            Introduce your platform as an all-encompassing solution designed to protect your business from external threats, ensuring your brand, data, and digital assets are secure.
                        </Text>
                    </MotionBox>

                    
                        <MotionBox id="phishing"
                           
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.5 }}
                            variants={animationVariants}
                            mb={5}
                            bg="gray.50" // Light background for Key Services
                            p={5} // Padding for better readability
                            borderRadius="lg"
                            boxShadow="md"
                        >
                            <Heading as="h2" size="xl" mb={8}>
                                Phishing Simulation: Strengthen Your Human Firewall
                            </Heading>
                            <Flex
                                direction={['column', 'column', 'row']}
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Box flex="0 0 25%" mt={[10, 0]}>
                                    <Image
                                        src={phishing}
                                        alt="Cybersecurity illustration"
                                        width="100%"
                                        borderRadius="lg"
                                    />
                                </Box>
                                <Box flex="1" textAlign={['center', 'center', 'left']} pl={[0, 0, 10]}>

                                    <SimpleGrid columns={[1, 1, 2]} spacing={10}>
                                        {[
                                            {
                                                icon: <ChevronRightIcon boxSize={5} color="teal.500" />,
                                                heading: 'Service Overview',
                                                text: 'Simulate real-world phishing attacks to test and train employees on recognizing and responding to phishing attempts.',
                                            },
                                            {
                                                icon: <ChevronRightIcon boxSize={5} color="teal.500" />,
                                                heading: 'Importance',
                                                text: 'Human Vulnerability: Employees are often the weakest link in cybersecurity. Phishing simulations educate and prepare your team to spot phishing emails and avoid compromising sensitive data. ',
                                            },
                                            {
                                                icon: <ChevronRightIcon boxSize={5} color="teal.500" />,
                                                heading: 'Compliance & Training',
                                                text: ' Helps meet compliance requirements for security awareness training.',
                                            },
                                            {
                                                icon: <ChevronRightIcon boxSize={5} color="teal.500" />,
                                                heading: 'Realistic Scenarios',
                                                text: 'Customizable phishing simulations that mimic actual threats, helping employees learn in a controlled environment.',
                                            },
                                            {
                                                icon: <ChevronRightIcon boxSize={5} color="teal.500" />,
                                                heading: 'Detailed Reporting',
                                                text: 'Identify who clicked on phishing links, which emails were flagged, and areas that need improvement.',
                                            },
                                            {
                                                icon: <ChevronRightIcon boxSize={5} color="teal.500" />,
                                                heading: 'Ongoing Education:',
                                                text: 'Monitor for compromised email addresses linked to your domain.',
                                            },
                                            {
                                                icon: <ChevronRightIcon boxSize={5} color="teal.500" />,
                                                heading: 'Deep & Dark Web Analysis',
                                                text: 'Regular simulations keep security awareness high and employees vigilant.',
                                            },
                                            {
                                                icon: <ChevronRightIcon boxSize={5} color="teal.500" />,
                                                heading: 'Why It’s Important:',
                                                text: 'With phishing being one of the most common attack vectors, continuous education helps reduce the risk of successful attacks.',
                                            },
                                        ].map((item, index) => (
                                            <Box key={index} mb={5} display="flex" alignItems="flex-start">
                                                {item.icon}
                                                <Box as="span" ml={3}>
                                                    <Heading as="h3" size="md" mb={2}>
                                                        {item.heading}
                                                    </Heading>
                                                    <Text>
                                                        {item.text}
                                                    </Text>
                                                </Box>
                                            </Box>
                                        ))}
                                    </SimpleGrid>
                                </Box>
                            </Flex>
                        </MotionBox>

                        <MotionBox id="automated"
                            initial="hidden"
                            animate="visible"
                            variants={animationVariants}
                            py={[10, 20]}
                            mt={[10, 20]}
                            borderRadius="lg"
                            boxShadow="lg"
                            width="100%"
                            maxW="100%" // Ensure it takes up full width
                            px={[5, 10]}
                            bg="gray.100" // Light gray background for Importance section
                        >
                            <Heading as="h2" size="xl" mb={8}>
                                Automated Penetration Testing & Vulnerability Analysis: Identify Weak Points Before Attackers
                                Do
                            </Heading>
                            <Flex
                                direction={['column', 'column', 'row']}
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Box flex="0 0 65%" textAlign={['center', 'center', 'left']} pr={[0, 0, 10]}>

                                    <SimpleGrid columns={[1, 1, 2]} spacing={10}>
                                        {[
                                            {
                                                icon: <InfoOutlineIcon boxSize={5} color="teal.500" />,
                                                heading: 'Service Overview',
                                                text: 'Automated tools scan your applications, networks, and systems to identify vulnerabilities that could be exploited by attackers.',
                                            },
                                            {
                                                icon: <InfoOutlineIcon boxSize={5} color="teal.500" />,
                                                heading: 'Continuous Security Assessment:',
                                                text: 'Automated scans provide constant monitoring, ensuring that vulnerabilities are identified and addressed promptly.',
                                            },
                                            {
                                                icon: <InfoOutlineIcon boxSize={5} color="teal.500" />,
                                                heading: 'Proactive Defense:',
                                                text: 'Identifies weak spots before they can be exploited, helping to prevent data breaches and system compromises.',
                                            },
                                            {
                                                icon: <InfoOutlineIcon boxSize={5} color="teal.500" />,
                                                heading: 'Comprehensive Scans',
                                                text: 'Cover web applications, networks, databases, and endpoints for a holistic security posture.',
                                            },
                                            {
                                                icon: <InfoOutlineIcon boxSize={5} color="teal.500" />,
                                                heading: 'Actionable Insights',
                                                text: 'Detailed reports with risk assessments and recommendations for remediation.',
                                            },
                                            {
                                                icon: <InfoOutlineIcon boxSize={5} color="teal.500" />,
                                                heading: 'Time & Cost Efficiency',
                                                text: 'Automated testing reduces the need for manual checks, saving time and resources while providing thorough analysis.',
                                            },
                                        ].map((item, index) => (
                                            <Box key={index} mb={5} display="flex" alignItems="flex-start">
                                                {item.icon}
                                                <Box as="span" ml={3}>
                                                    <Heading as="h3" size="md" mb={2}>
                                                        {item.heading}
                                                    </Heading>
                                                    <Text>
                                                        {item.text}
                                                    </Text>
                                                </Box>
                                            </Box>
                                        ))}
                                    </SimpleGrid>
                                </Box>
                                <Box flex="0 0 25%" mt={[10, 0]}>
                                    <Image
                                        src={automated}
                                        alt="Cybersecurity illustration"
                                        width="100%"
                                        borderRadius="lg"
                                    />
                                </Box>

                            </Flex>
                        </MotionBox> <br />

                        <MotionBox 
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.5 }}
                            variants={animationVariants}
                            mb={5}
                            bg="gray.50"  // Background color for Key Benefits section
                            p={8}
                            borderRadius="lg"
                            boxShadow="lg"
                        >
                            <Heading as="h2" size="xl" mb={8}id="manualBusiness">
                                Manual Business Logic Testing: Uncover Hidden Vulnerabilities
                            </Heading>
                            <Flex
                                direction={['column', 'column', 'row']}
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Box flex="0 0 25%" mt={[10, 0]}>
                                    <Image
                                        src={business}
                                        alt="Cybersecurity illustration"
                                        width="100%"
                                        borderRadius="lg"
                                    />
                                </Box>
                                <Box flex="1" textAlign={['center', 'center', 'left']} pl={[0, 0, 10]}>

                                    <SimpleGrid columns={[1, 1, 2]} spacing={10}>
                                        {[
                                            {
                                                icon: <ChevronRightIcon boxSize={5} color="teal.500" />,
                                                heading: 'Service Overview',
                                                text: 'Focuses on identifying security issues that automated testing tools might miss, particularly those related to the unique business logic of your application.',
                                            },
                                            {
                                                icon: <ChevronRightIcon boxSize={5} color="teal.500" />,
                                                heading: 'Customized Testing',
                                                text: 'Manual testing by experts who understand your application’s specific workflows and can identify logic flaws that might lead to security breaches.',
                                            },
                                            {
                                                icon: <ChevronRightIcon boxSize={5} color="teal.500" />,
                                                heading: 'Critical for High-Risk Applications',
                                                text: ' Especially important for applications handling sensitive data, financial transactions, or critical operations.',
                                            },
                                            {
                                                icon: <ChevronRightIcon boxSize={5} color="teal.500" />,
                                                heading: 'Detailed Analysis',
                                                text: ' Identifies complex vulnerabilities, such as authentication bypass, business logic abuse, and privilege escalation. ',
                                            },
                                            {
                                                icon: <ChevronRightIcon boxSize={5} color="teal.500" />,
                                                heading: 'Tailored Recommendations',
                                                text: 'Provides specific guidance on how to fix identified issues, tailored to the applications business context.',
                                            },
                                            {
                                                icon: <ChevronRightIcon boxSize={5} color="teal.500" />,
                                                heading: 'Comprehensive Protection',
                                                text: 'Complements automated testing by covering gaps and providing a deeper analysis of potential attack vectors.',
                                            },
                                        ].map((item, index) => (
                                            <Box key={index} mb={5} display="flex" alignItems="flex-start">
                                                {item.icon}
                                                <Box as="span" ml={3}>
                                                    <Heading as="h3" size="md" mb={2}>
                                                        {item.heading}
                                                    </Heading>
                                                    <Text>
                                                        {item.text}
                                                    </Text>
                                                </Box>
                                            </Box>
                                        ))}
                                    </SimpleGrid>
                                </Box>
                            </Flex>
                        </MotionBox>

                        <MotionBox 
                            initial="hidden"
                            animate="visible"
                            variants={animationVariants}
                            py={[10, 20]}
                            mt={[10, 20]}
                            borderRadius="lg"
                            boxShadow="lg"
                            width="100%"
                            maxW="100%"
                            px={[5, 10]}
                            bg="gray.100"  // Background color for In-Depth Analysis section
                        >
                            <Flex
                            id="cyberSecurity"
                                direction={['column', 'column', 'row']}
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Box flex="0 0 65%" textAlign={['center', 'center', 'left']} pr={[0, 0, 10]}>
                                    <Heading as="h2" size="xl" mb={8}>
                                    Cybersecurity Integration Services
                                    </Heading>
                                    <SimpleGrid columns={[1, 1, 2]} spacing={10}>
                                        {[
                                            {
                                                icon: <InfoOutlineIcon boxSize={5} color="teal.500" />,
                                                heading: 'Service Overview',
                                                text: 'Monitor historical changes in domain ownership and contact details to detect anomalies, potential takeovers, or hijacking attempts.',
                                            },
                                            {
                                                icon: <InfoOutlineIcon boxSize={5} color="teal.500" />,
                                                heading: 'Unified Security Approach',
                                                text: ' Integrating security solutions creates a cohesive defence strategy that reduces gaps and enhances overall protection. ',
                                            },
                                            {
                                                icon: <InfoOutlineIcon boxSize={5} color="teal.500" />,
                                                heading: 'Scalability and Flexibility',
                                                text: 'Ensures that security measures grow with your business needs without disrupting operations.',
                                            },
                                            {
                                                icon: <InfoOutlineIcon boxSize={5} color="teal.500" />,
                                                heading: 'Expert Guidance',
                                                text: 'Assistance with the selection, implementation, and configuration of cybersecurity tools tailored to your specific environment.',
                                            },
                                            {
                                                icon: <InfoOutlineIcon boxSize={5} color="teal.500" />,
                                                heading: 'Streamlined Operations',
                                                text: 'Reduce complexity and improve efficiency by integrating security processes into existing workflows.',
                                            },
                                            {
                                                icon: <InfoOutlineIcon boxSize={5} color="teal.500" />,
                                                heading: 'Continuous Support',
                                                text: 'Ongoing support and optimization to adapt to new threats and changing business requirements.',
                                            },
                                            {
                                                icon: <InfoOutlineIcon boxSize={5} color="teal.500" />,
                                                heading: 'Why It’s Important',
                                                text: 'Properly integrated security solutions not only protect your data but also improve operational efficiency by reducing redundant or conflicting security measures.',
                                            },
                                        ].map((item, index) => (
                                            <Box key={index} mb={5} display="flex" alignItems="flex-start">
                                                {item.icon}
                                                <Box as="span" ml={3}>
                                                    <Heading as="h3" size="md" mb={2}>
                                                        {item.heading}
                                                    </Heading>
                                                    <Text>
                                                        {item.text}
                                                    </Text>
                                                </Box>
                                            </Box>
                                        ))}
                                    </SimpleGrid>
                                </Box>
                                <Box flex="0 0 25%" mt={[10, 0]}>
                                    <Image
                                        src={analysis}
                                        alt="Cybersecurity illustration"
                                        width="100%"
                                        borderRadius="lg"
                                    />
                                </Box>
                            </Flex>
                        </MotionBox>

                        <MotionBox
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.5 }}
                            variants={animationVariants}
                            py={[10, 20]}
                            mt={[10, 20]}
                            borderRadius="xl"
                            boxShadow="2xl"
                            width="100%"
                            maxW="100%"
                            px={[6, 12]}
                            bg="gray.700"
                            textAlign="center"
                            color="white"
                            transition="transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out"
                            _hover={{ transform: 'scale(1.02)', boxShadow: 'lg' }}
                        >
                            <Heading as="h2" size="2xl" mb={6} fontWeight="bold" letterSpacing="wide" color="gray.100">
                                Secure Your Brand and Data Now
                            </Heading>
                            <Text fontSize={["lg", "xl"]} mb={8} lineHeight="1.7" color="gray.300">
                                Get Started Today: Schedule a demo to see how our platform can protect your business.
                                <br />
                                Contact Us: Reach out for more information and let us help you safeguard your brand against today’s evolving cyber threats.
                            </Text>
                            <Flex justifyContent="center" gap={6}>
                               
                                <Button
                                    size="lg"
                                    variant="solid"
                                    bg="black"
                                    color="white"
                                    border="2px solid transparent"  // Initial border set to transparent
                                    _hover={{
                                        bg: "gray.700",
                                        borderColor: "gray.400"  // Border color when hovering
                                    }}
                                    _active={{
                                        bg: "gray.600",
                                        borderColor: "gray.500"  // Border color when active
                                    }}
                                    onClick={getContact}
                                >
                                    Contact Us
                                </Button>

                            </Flex>
                        </MotionBox>



                    
                </Container>
            </Box> <br />
            <MainpageFooter />
        </div>
    );
};

export default KeyServices;
