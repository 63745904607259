import React, { useEffect, useState } from 'react';
import { Avatar, Text, VStack, Heading, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { cognitoClient } from '../../authService'; // Make sure this is correctly configured
import { GetUserCommand } from '@aws-sdk/client-cognito-identity-provider';

const ViewProfile = () => {
  const [userDetails, setUserDetails] = useState({
    name: '',
    email: '',
    currentPlan: '',
  });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const accessToken = sessionStorage.getItem('accessToken');
        if (!accessToken) {
          throw new Error('Access token not found');
        }

        const command = new GetUserCommand({
          AccessToken: accessToken,
        });

        const { UserAttributes } = await cognitoClient.send(command);

        const attributes = Object.fromEntries(UserAttributes.map(attr => [attr.Name, attr.Value]));

        setUserDetails({
          name: attributes['name'] || 'N/A',
          email: attributes['email'] || 'N/A',
          currentPlan: attributes['custom:plan'] || 'N/A',
        });
      } catch (error) {
        console.error('Error fetching user details:', error);
        navigate('/'); // Redirect if the user is not authenticated
      }
    };

    fetchUserDetails();
  }, [navigate]);

  return (
    <VStack spacing={4} align="center" p={8} bg="gray.100" borderRadius="lg">
      <Avatar size="2xl" name={userDetails.name} mb={4} />
      <Heading as="h2" size="xl">
        {userDetails.name}
      </Heading>
      <Text fontSize="lg" color="gray.700">
        Email: {userDetails.email}
      </Text>
      <Text fontSize="lg" color="gray.700">
        Current Plan: {userDetails.currentPlan}
      </Text>
      <Button colorScheme="blue" onClick={() => navigate('/profile_edit')}>
        Edit Profile
      </Button>
    </VStack>
  );
};

export default ViewProfile;
