import React, { useState, useEffect } from 'react';
import axios from 'axios';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import {
  Box,
  Input,
  Button,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  VStack, SimpleGrid,
  Heading,
  Center,
  useToast,
  Spinner,
  useBreakpointValue,
  Stack,HStack,Icon,Menu,MenuButton,MenuList,MenuItem,  useColorModeValue,Divider
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { FaBuilding, FaInfoCircle, FaQuestionCircle, FaLink, FaChevronDown,FaTools } from 'react-icons/fa'; // Added ChevronDown for dropdown icon
import ImageOne from '../../assets/images/Global_network_generated.jpg';
import logo from '../../assets/images/logo.png';
import UserNavbar from '../auth/UserNavbar';
import MainpageFooter from '../MainpageFooter';
import MainComponent from '../Maincomponent';
import {getapiToken} from '../auth/loginPage';
import {  FaDownload } from 'react-icons/fa';

 // Function to get user plan from session storage
 const getUserPlan = () => {
  return sessionStorage.getItem('userPlan') || 'Free'; // Default to 'Free' if not found 
};


 
const CompanySearch = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [companyApiResponses, setCompanyApiResponses] = useState([]);
  const [deepSearchResponses, setDeepSearchResponses] = useState([]);
  const [hiddenSearchData, setHiddenSearchData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hiddenError, setHiddenError] = useState(null);
  const [token, setToken] = useState(null);
  const [message, setMessage] = useState('');
  const toast = useToast();
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const userPlan = getUserPlan(); // Assume this is defined elsewhere

  // Scroll to top when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);

  // Define responsive values for padding, font size, etc.
const paddingX = useBreakpointValue({ base: '4', md: '6', lg: '8' });
const buttonWidth = useBreakpointValue({ base: '80%', sm: '60%', md: '50%' });
const containerWidth = useBreakpointValue({ base: '90%', md: '70%', lg: '60%' });
const containerHeight = useBreakpointValue({ base: '70%', md: '60%', lg: '50%' });

const [apiResults, setApiResults] = useState([]);

  useEffect(() => {
    const checkAuthentication = () => {
      const storedToken = getapiToken(); // Retrieve token

      setToken(storedToken);
      const accessToken = sessionStorage.getItem('accessToken');
      if (!accessToken) {
        navigate('/signin');
        return;
      }
    };

    checkAuthentication();

    const savedCompanyResults = sessionStorage.getItem('companySearchResults');
    if (savedCompanyResults) {
      setCompanyApiResponses(JSON.parse(savedCompanyResults));
    }

    const savedDeepSearchResults = sessionStorage.getItem('deepSearchResults');
    if (savedDeepSearchResults) {
      setDeepSearchResponses(JSON.parse(savedDeepSearchResults));
    }

    const savedHiddenSearchResults = sessionStorage.getItem('hiddenSearchResults');
    if (savedHiddenSearchResults) {
      setHiddenSearchData(JSON.parse(savedHiddenSearchResults));
    }
  }, [navigate]);

  const handleDownloadClick = () => {
    if (userPlan === 'Free') {
      toast({
        title: 'Upgrade Required',
        description: 'You need to upgrade your plan to download the PDF.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
    } else {
       generatePdf(companyApiResponses, hiddenSearchData)
    }
  };

//com

const fetchCompanyData = async () => {
  if (!searchTerm) {
    setError('Please enter a domain name.');
    return;
  }

  // Create an array of search terms from 1 to 16
  const searchTerms = Array.from({ length: 16 }, (_, i) => (i + 1).toString());

  setIsLoading(true);
  setCompanyApiResponses([]); // Reset the state for new results

  try {
    const companyApiResponsesTemp = [];

    // Loop over each search term sequentially using for...of
    for (const term of searchTerms) {
      try {
        const response = await axios.get(
          'https://acdkl3bou7rzkbnsblthlui4du0bkezr.lambda-url.ap-south-1.on.aws/api/deepweb/company_search',
          {
            headers: {
              'Authorization': `Bearer ${token}`, // Assuming the token is stored in a state or session
              'Content-Type': 'application/json',
            },
            params: {
              query_num: term,        // The query number
              company_name: searchTerm,  // The user input or domain name
            },
          }
        );

        // Process the response for the current term
        const data = response.data; // Get the JSON response
        Object.entries(data).forEach(([key, value]) => {
          const resultArray = Array.isArray(value.Results) ? value.Results : [];
          companyApiResponsesTemp.push({
            searchType: value.Search_type || key, // Extract Search_type or key of the object
            resultLength: resultArray.length, // Get the length of the Results array
            originalJson: value,
            url: value.URL || 'No URL available',
          });
        });

      } catch (error) {
        console.error(`Error fetching data from ${term}:`, error);
        setError(`Failed to fetch data from ${term}`);
        // Optionally, break the loop on failure or continue to the next search term
        // break; 
      }
    }

    // Set the processed results to state
    setCompanyApiResponses(companyApiResponsesTemp);
    sessionStorage.setItem('companySearchResults', JSON.stringify(companyApiResponsesTemp)); // Store results in sessionStorage
    setError(''); // Clear any existing error
  } catch (error) {
    setError(`Failed to fetch deep search results: ${error.message}`);
    console.error(error);
    toast({
      title: 'Error fetching deep search data',
      description: `Failed to fetch data. ${error.message}`,
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
  } finally {
    setIsLoading(false); // Stop loading indicator
  }
};


  //com

  // const fetchDeepSearchData = async () => {
  //   if (!searchTerm) {
  //     setError('Please enter a domain name.');
  //     return;
  //   }
  
  //   const searchTerms = [
  //     "gist.github.com", "paste.mozilla.org", "ide.geeksforgeeks.org", "codepen.io",
  //     "pastebin.com", "gitlab.com/explore/snippets", "repl.it", "paste.ubuntu.com",
  //     "justpaste.it", "jsfiddle.net", "paste.centos.org", "justpaste.it (aka jpst.it)",
  //     "jsbin.com", "pastelink.net", "codebeautify.org", "controlc.com (aka pasted.co, tny.cz)",
  //     "invent.kde.org/explore/snippets", "ideone.com", "paste.rohitab.com", "codeshare.io",
  //     "paste.opensuse.org", "dotnetfiddle.net", "notes.io", "snipplr.com", "paste2.org",
  //     "hastebin.com", "ivpaste.com", "codepad.org", "justpaste.me", "pastebin.osuosl.org",
  //     "www.geany.org/p/", "bpa.st", "paste.ofcode.org", "paste.ee", "dpaste.org",
  //     "friendpaste.com", "defuse.ca/pastebin.htm", "dpaste.com", "cl1p.net", "pastie.org",
  //     "pastecode.io", "ghostbin.com", "pastebin.fr", "jsitor.com", "termbin.com", "p.ip.fi",
  //     "cutapaste.net", "paste.sh", "paste.jp", "paste-bin.xyz", "paste.xinu.at", "paste.debian.net",
  //     "vpaste.net", "paste.pound-python.org (read only)", "paste.org.ru", "apaste.info",
  //     "quickhighlighter.com", "sprunge.us", "commie.io", "everfall.com/paste/", "kpaste.net",
  //     "paste.frubar.net/ (read only archive)", "www.pastebin.pt", "n0paste.tk", "www.paste.lv",
  //     "tutpaste.com", "bitbin.it", "pastebin.fi", "nekobin.com", "pasteio.com",
  //     "www.paste4btc.com", "pastebin.ai"
  //   ];
  
  //   setIsLoading(true);
  //   setDeepSearchResponses([]); // Reset the state for new results
  
  //   try {
  //     const deepSearchResponsesTemp = [];
  
  //     // Loop over each search term sequentially using for...of
  //     for (const term of searchTerms) {
  //       try {
  //         const response = await axios.get(
  //           'https://acdkl3bou7rzkbnsblthlui4du0bkezr.lambda-url.ap-south-1.on.aws/api/deepweb/deep_search', 
  //           {
  //             headers: {
  //               'Authorization': `Bearer ${token}`, // Assuming the token is stored in a state or session
  //               'Content-Type': 'application/json',
  //             },
  //             params: {
  //               site: term,        // The domain to search
  //               search_term: searchTerm,  // The user input or domain name
  //             },
  //           }
  //         );
  
  //         // Process the response for the current term
  //         Object.entries(response.data).forEach(([key, value]) => {
  //           const resultArray = Array.isArray(value.Results) ? value.Results : [];
  //           deepSearchResponsesTemp.push({
  //             searchType: `${key} - ${term}`, // Example: "pastebin - gist.github.com"
  //             resultLength: resultArray.length,
  //             originalJson: value,
  //             url: value.URL || 'No URL available',
  //           });
  //         });
  
  //       } catch (error) {
  //         console.error(`Error fetching data from ${term}:`, error);
  //         setError(`Failed to fetch data from ${term}`);
  //         break; // Optionally, break the loop on failure or continue to the next search term
  //       }
  //     }
  
  //     // Set the processed results to state
  //     setDeepSearchResponses(deepSearchResponsesTemp);
  //     sessionStorage.setItem('deepSearchResults', JSON.stringify(deepSearchResponsesTemp)); // Store results in sessionStorage
  //     setError(''); // Clear any existing error
  //   } catch (error) {
  //     setError(`Failed to fetch deep search results: ${error.message}`);
  //     console.error(error);
  //     toast({
  //       title: 'Error fetching deep search data',
  //       description: `Failed to fetch data. ${error.message}`,
  //       status: 'error',
  //       duration: 5000,
  //       isClosable: true,
  //     });
  //   } finally {
  //     setIsLoading(false); // Stop loading indicator
  //   }
  // };
  

  const fetchHiddenData = async () => {
    if (!searchTerm) {
      setError('Please enter a domain name.');
      return;
    }
  
    // Extract the name before the first dot
    const strippedDomain = searchTerm.split('.')[0];
  
    // If the stripped domain contains additional parts, further split and take the first part
    const domainParts = strippedDomain.split('.');
    const domainName = domainParts[0]; // Get the first part (e.g., "sysnet" from "sysnet.com.sg")
  
    try {
      setIsLoading(true); // Start loading indicator
  
      const response = await axios.get(
        'https://acdkl3bou7rzkbnsblthlui4du0bkezr.lambda-url.ap-south-1.on.aws/api/deepweb/hidden_search',
        {
          headers: {
            Authorization: `Bearer ${token}`, // Replace with actual token if needed
          },
          params: {
            query: domainName, // Send the domain name as a query
          },
        }
      );
  
      // Check if the response contains the expected data
      if (Array.isArray(response.data)) {
        setHiddenSearchData(response.data); // Set the hidden search results in the state
        sessionStorage.setItem('hiddenSearchResults', JSON.stringify(response.data)); // Store the results in session storage
        setError(''); // Clear any previous errors
      } else {
        throw new Error('Unexpected response format');
      }
    } catch (err) {
      setError('Failed to fetch hidden search data');
      toast({
        title: 'Error fetching hidden search data',
        description: `Failed to fetch hidden search data. ${err.message}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false); // Stop loading indicator
    }
  };
  
  const handleFetchAllData = async () => {
    setIsLoading(true);
    await Promise.all([fetchCompanyData(), fetchHiddenData()]);
    setIsLoading(false);
  };

  const viewResults = (type) => {
    const userPlan = getUserPlan(); // Get the user plan
    let data;
  
    if (type === 'company') {
      data = companyApiResponses;
  
      // Store the company search responses in session storage if the type is 'company'
      sessionStorage.setItem('companySearchResponse', JSON.stringify(companyApiResponses));
    } else {
      data = deepSearchResponses;
    }
  
    // Check if the user plan is Free
    if (userPlan === 'Free') {
      toast({
        title: 'Upgrade Required',
        description: 'You need to upgrade your plan to access this feature.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
    } else {
      navigate('/companysearch_result', { state: { data } });
    }
  };
  

  // Function to generate and download PDF
  const generatePdf = (companyData, deepSearchData, hiddenData, logo) => {
    const doc = new jsPDF();
    let yOffset = 10;
    const pageHeight = doc.internal.pageSize.height;
    const marginBottom = 10;

    const addPage = () => {
        doc.addPage();
        yOffset = 10;
    };

    const checkPageOverflow = (heightToAdd) => {
        if (yOffset + heightToAdd > pageHeight - marginBottom) {
            addPage();
        }
    };

    // Add Logo at the top of the PDF
    const logoWidth = 50;
    const logoHeight = 20;

    if (logo) {
        doc.addImage(logo, 'PNG', 10, yOffset, logoWidth, logoHeight);
        yOffset += logoHeight + 10; // Logo space adjustment
    }
    
    // Add Title after the Logo
    doc.setFontSize(16); // Set title font size
    doc.text('Company Domain Deep and Dark Analysis', 10, yOffset);
    yOffset += 15; // Increased space after title

    const addTable = (head, body) => {
        checkPageOverflow(body.length * 10 + 20); // Adjust height check based on content

        autoTable(doc, {
            startY: yOffset,
            head: [head],
            body: body,
            styles: {
                fontSize: 10, // Set a suitable font size
                cellPadding: 2, // Add padding to avoid text sticking to borders
                overflow: 'linebreak', // Ensure text wraps within the cell boundaries
            },
            columnStyles: {
                0: { cellWidth: 70, halign: 'left' }, // Adjust widths as needed
                1: { cellWidth: 70, halign: 'left' },
                2: { cellWidth: 70, halign: 'left' },
            },
            tableWidth: '90%', // Set table width to 90%
        });

        yOffset = doc.lastAutoTable.finalY + 10; // Update yOffset after adding the table
    };

     // Company External Search Result Section
     if (companyData && companyData.length > 0) {
      doc.setFontSize(14); // Set section heading font size
      doc.text('Company External Summary', 10, yOffset);
      yOffset += 10; // Adjusted space after heading

      // Adding the companyApiResponses table first
      const companyApiTableBody = companyApiResponses.map((response) => [response.searchType, response.resultLength]);
      addTable(['Search Type', 'Result Length'], companyApiTableBody);

  } else {
      doc.setFontSize(14);
      doc.text('Company External Search Result', 10, yOffset);
      yOffset += 10;
      doc.text('No Company External Search Results Available', 10, yOffset);
      yOffset += 10;
  }


// Hidden Search Results Section
doc.setFontSize(14);
doc.text('Hidden Search Results', 10, yOffset);
yOffset += 10;

if (hiddenData && hiddenData.length > 0) {
    const hiddenTableBody = hiddenData.map((data) => [
        data.url || 'N/A',
        data.title || 'N/A',
        data.description || 'N/A',
    ]);

    // Add the table using autoTable with improved settings
    doc.autoTable({
        head: [['URL', 'Title', 'Description']],
        body: hiddenTableBody,
        startY: yOffset, // Start Y position for the table
        theme: 'grid', // You can use 'striped', 'plain', or 'grid'
        headStyles: { fillColor: [22, 160, 133] }, // Set header color
        styles: { cellPadding: 2, fontSize: 10 }, // Adjust padding and font size
        margin: { top: 10 }, // Add margin at the top
    });

    // Adjust yOffset for any additional content after the table
    yOffset = doc.lastAutoTable.finalY + 10; // Adding some space after the table
} else {
    doc.text('No Hidden Search Results Available', 10, yOffset);
    yOffset += 10;
}



    // Company External Search Result Section
    if (companyData && companyData.length > 0) {
        doc.setFontSize(14); // Set section heading font size
        doc.text('Company External Search Result', 10, yOffset);
        yOffset += 10; // Adjusted space after heading

        // Adding detailed information after the table
        companyData.forEach((response) => {
            const searchType = response.searchType || 'N/A';
            const resultsList = Array.isArray(response.originalJson?.Results) ? response.originalJson.Results : [];

            // Only add title and table if there are results
            if (resultsList.length > 0) {
                doc.text(`Search Type: ${searchType}`, 10, yOffset);
                yOffset += 10; // Adjusted space after search type heading

                // Prepare the table body
                const companyTableBody = resultsList.map((item) => {
                    let title = 'N/A';
                    let url = 'N/A';

                    if (Array.isArray(item)) {
                        if (item.length === 2) {
                            if (typeof item[0] === 'string' && typeof item[1] === 'string') {
                                url = item[0];
                                title = item[1];
                            } else if (typeof item[1] === 'object') {
                                url = item[1].Url || 'N/A';
                                title = item[1].Title || 'N/A';
                            }
                        }
                    } else if (typeof item === 'object') {
                        url = item.Url || 'N/A';
                        title = item.Title || 'N/A';
                    }

                    return [title, url];
                });

                addTable(['Title', 'URL'], companyTableBody);
            }
        });
    } else {
        doc.setFontSize(14);
        doc.text('Company External Search Result', 10, yOffset);
        yOffset += 10;
        doc.text('No Company External Search Results Available', 10, yOffset);
        yOffset += 10;
    }

   
    doc.save('Deep and Dark Analysis.pdf');
};


  //ff
const bgColor = useColorModeValue('white', 'gray.800');
const tableBorderColor = useColorModeValue('gray.200', 'gray.700');
const textPrimaryColor = useColorModeValue('blue.900', 'white');
const errorColor = useColorModeValue('red.500', 'red.300');

  return (


    <div>
         <div
    style={{
      position: 'fixed',                         // Fix the position of the navbar
      top: 0,                                    // Stick it to the top
      left: 0,                                   // Align it to the left edge
      width: '100%',                             // Full width for the navbar
      zIndex: 1000,                              // Ensure it's above other content
    }}
  >
   <UserNavbar /> 
  </div>
        
     <div>
        <Box
        height={{ base: '60vh', md: '60vh', lg: '60vh' }}
        backgroundImage={`url(${ImageOne})`} 
        backgroundSize="cover"
        backgroundPosition="center"
        position="relative"
      >
        <Center height="100%">
          <Box
            width={containerWidth}
            height={containerHeight}
            backgroundColor="rgba(0, 0, 0, 0.6)"
            color="white"
            borderRadius="20px"
            paddingX={paddingX}
            paddingY={{ base: '4', md: '6', lg: '8' }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            <Stack
              direction={{ base: 'column', md: 'row' }}
              spacing={{ base: '4', md: '6' }}
              align="center"
              justify="center"
              mb={6}
              wrap="wrap" // Ensure that items wrap if needed
              gap={{ base: '4', md: '6' }} // Responsive gap
            >
    
              {/* Normal Button */}
              <Button
                bg="transparent"
                color="white"
                borderRadius="md"
                _hover={{ bg: 'rgba(0, 0, 0, 0.3)' }}
                onClick={() => navigate('/companyinfo')}
              >
                <HStack spacing={2}>
                <Icon as={FaBuilding} />
                  <Text>Domain Risk Analysis</Text>
                </HStack>
              </Button>
        
    
              {/* Normal Button */}
        <Button
                bg="transparent"
                color="white"
                borderRadius="md"
                _hover={{ bg: 'rgba(0, 0, 0, 0.3)' }}
                onClick={() => navigate('/companysearch')}
              >
                <HStack spacing={2}>
                <Icon as={FaInfoCircle} />
                  <Text>Deep and Dark Search</Text>
                </HStack>
              </Button>

              
              {/* Dropdown Button 3 */}
              <Menu>
                <MenuButton
                  as={Button}
                  bg="transparent"
                  color="white"
                  borderRadius="md"
                  _hover={{ bg: 'rgba(0, 0, 0, 0.3)' }}
                  rightIcon={<FaChevronDown />}
                >
                  <HStack spacing={2}>
                    <Icon as={FaTools} />
                    <Text>Tools</Text>
                  </HStack>
                </MenuButton>
                <MenuList bg="black" color="white">
                  <MenuItem
                    bg="black"
                    _hover={{ bg: 'rgba(255, 255, 255, 0.2)' }}
                    onClick={() => navigate('/dkim')}
                  >
                    DKIM
                  </MenuItem>
                  <MenuItem
                    bg="black"
                    _hover={{ bg: 'rgba(255, 255, 255, 0.2)' }}
                    onClick={() => navigate('/securityheaders')}
                  >
                    Security Headers
                  </MenuItem>
                  <MenuItem
                    bg="black"
                    _hover={{ bg: 'rgba(255, 255, 255, 0.2)' }}
                    onClick={() => navigate('/sitetechnology')}
                  >
                    Site Technology
                  </MenuItem>
                  <MenuItem
                    bg="black"
                    _hover={{ bg: 'rgba(255, 255, 255, 0.2)' }}
                    onClick={() => navigate('/url')}
                  >
                    URL Analysis
                  </MenuItem>
                </MenuList>
              </Menu>
    
              {/* Normal Button */}
              {/* <Button
                bg="transparent"
                color="white"
                borderRadius="md"
                _hover={{ bg: 'rgba(0, 0, 0, 0.3)' }}
                onClick={() => navigate('/url')}
              >
                <HStack spacing={2}>
                  <Icon as={FaLink} />
                  <Text>URL Analysis</Text>
                </HStack>
              </Button> */}
            </Stack>
    
            {/* Input and Search Button */}
            <VStack spacing={4} width={containerWidth} align="center">
              <Input
                placeholder="Company Name: 'example.com'"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                size="md"
                color="white"
                backgroundColor="rgba(255, 255, 255, 0.2)"
                borderRadius="md"
                _placeholder={{ color: 'white' }}
                border="1px solid white"
              />
            
            {/* <Button
            colorScheme="teal"
            width={buttonWidth}
            borderRadius="20px"
            backgroundColor="rgba(0, 0, 0, 0.5)"
            _hover={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}
            onClick={handleFetchAllData}
          >
            Search
            </Button> */}
            <MainComponent domain={searchTerm} onSuccess={handleFetchAllData} />
            </VStack>
          </Box>
        </Center>
      </Box>
        <br></br>

 {/* <Box>
      <Box padding="20px" bg="teal.500" color="white">
        <Heading as="h1" size="lg">Search Tool</Heading>
      </Box>
      <Box padding="20px" borderWidth="2px" borderRadius="md" boxShadow="md">
        <VStack spacing="4" align="start">
          <Text fontSize="xl" fontWeight="bold">Search</Text>
         <Box mb="4">
            <Input
              placeholder="Enter search term"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              mb="2"
            />
            <Button onClick={handleFetchAllData} colorScheme="teal" isLoading={isLoading}>
              Fetch All Data
            </Button>
          </Box>
        </VStack> */}

<Box bg={bgColor} p={8} borderRadius="md" boxShadow="lg">
  {/* Spinner displayed while loading */}
  {isLoading && (
    <Center my={4}>
      <Spinner size="xl" color="teal.500" />
    </Center>
  )}



      {/* Company External Search Results */}
      <Heading 
        as="h3" 
        size="lg" 
        color={textPrimaryColor} 
        mt={6} 
        mb={4} 
        textAlign="center" 
        fontWeight="bold" 
        textTransform="uppercase"
      >
       Deep Search
      </Heading>
      <Table 
        variant="striped" 
        colorScheme="green" 
        size="md" 
        borderColor={tableBorderColor} 
        mb={4} 
        width="70%" 
        mx="auto"
      >
        <Thead>
          <Tr>
            <Th>Search Type</Th>
            <Th>Result</Th>
          </Tr>
        </Thead>
        <Tbody>
          {companyApiResponses.map((response, index) => (
            <Tr key={index}>
              <Td color="blue.600">
                {response.searchType.replace(/ - \d+$/, '')} {/* Removes trailing ' - number' */}
              </Td>
              <Td color="red.500" fontWeight="bold">
                {response.resultLength}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Center>
        <Button mt={4} colorScheme="teal" onClick={() => viewResults('company')}>
          View Company Search Results
        </Button>
      </Center>

      <Divider my={8} />

      {/* Deep Search Results */}
      {/* <Heading as="h3" size="lg" color={textPrimaryColor} mt={6} mb={4} textAlign="center" fontWeight="bold" textTransform="uppercase">
        Deep Search Results
      </Heading>
      <Table variant="striped" colorScheme="green" size="md" borderColor={tableBorderColor} mb={4} width="70%" mx="auto">
        <Thead>
          <Tr>
            <Th>Search Type</Th>
            <Th>Result</Th>
          </Tr>
        </Thead>
        <Tbody>
          {deepSearchResponses.map((response, index) => (
            <Tr key={index}>
              <Td color="blue.600">
                {response.searchType.replace}
              </Td>
              <Td color="red.500" fontWeight="bold">
                {response.resultLength}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Center>
        <Button mt={4} colorScheme="teal" onClick={() => viewResults('deep')}>
          View Deep Search Results
        </Button>
      </Center>

      <Divider my={8} /> */}

      {/* Hidden Search Results */}
      <Heading  
  as="h3" 
  size={{ base: "md", md: "lg" }} // Responsive heading size
  color={textPrimaryColor} 
  mt={6} 
  mb={4} 
  textAlign="center" 
  fontWeight="bold" 
  textTransform="uppercase"
>
 Dark Search
</Heading>

{/* Responsive Card Layout */}
<SimpleGrid columns={{ base: 1, md: 2, lg: 1 }} spacing={6} mb={6}>
  {hiddenSearchData.map((data, index) => (
    <Box
      key={index}
      p={4}
      borderWidth="1px"
      borderRadius="lg"
      boxShadow="lg"
      backgroundColor={index % 2 === 0 ? "green.100" : "white"} // Alternating colors
      transition="0.3s"
      _hover={{ boxShadow: 'xl', transform: 'scale(1.02)' }}
      width="70%" // Set fixed width to 70%
      mx="auto" // Center the box
    >
      <Stack spacing={4}>
        <Text fontWeight="bold" color="blue.600" textTransform="uppercase">{data.url}</Text>
        <Text fontWeight="bold" color="red.500">{data.title}</Text>
        <Text color="gray.600">{data.description}</Text>
      </Stack>
    </Box>
  ))}
</SimpleGrid>




      {/* Conditionally Render Download PDF Button */}
      {hiddenSearchData.length > 0 && (  // Show button only if there are hidden search results
        <Center>
          <Button
            leftIcon={<FaDownload />} // Adding an icon
            width='50%'
            size="lg" // Larger size
            colorScheme="teal" // Change color scheme for better aesthetics
            variant="solid" // Solid variant
            borderRadius="md" // Rounded corners
            bgGradient="linear(to-r, teal.500, green.500)" // Gradient background
            _hover={{ 
              bgGradient: "linear(to-r, teal.400, green.400)", // Slightly different gradient on hover
              boxShadow: "lg", // Add shadow on hover
            }}
            _active={{ 
              bgGradient: "linear(to-r, teal.600, green.600)", // Darker gradient on active
              transform: "scale(0.98)", // Slightly smaller on click
            }}
            onClick={handleDownloadClick}
          >
            Download PDF
          </Button>
        </Center>
      )}
  
</Box>


<br></br>
         <MainpageFooter/>
            </div> 

        </div>
  );
};

export default CompanySearch;
