import React from 'react';
import {
  Box,
  Heading,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Text,
  Divider,
  Flex,
} from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import UserNavbar from '../../auth/UserNavbar';
import MainpageFooter from '../../MainpageFooter';

const LeakDetailsPage = () => {
  const location = useLocation();
  const leaks = location.state?.leaks || {};
  
  // Green color values for both light and dark mode
  const bgColor = useColorModeValue('white', 'gray.800');
  const tableBorderColor = useColorModeValue('green.200', 'green.700');
  const tableHeaderBgColor = useColorModeValue('green.100', 'green.900');
  const tableHeaderTextColor = useColorModeValue('green.900', 'white');
  const textPrimaryColor = useColorModeValue('green.900', 'green.100');
  const textHighlightColor = useColorModeValue('red.500', 'red.300');
  const containerBgColor = useColorModeValue('gray.50', 'gray.900');

  return (
    <div>
      <UserNavbar />

      <Box bg={containerBgColor} minH="100vh" py={10}>
        {/* Flexbox container for the main heading */}
        <Flex justifyContent="center" mb={6}>
          <Heading size="xl" textAlign="center" color={textPrimaryColor} mb={4}>
            Leak Details
          </Heading>
        </Flex>

        <Box
          maxW="7xl"
          mx="auto"
          borderWidth="1px"
          borderRadius="lg"
          boxShadow="xl"
          bg={bgColor}
          p={8}
        >
          <Divider mb={6} />

          {/* Table for leak details */}
          <Table
            variant="striped"
            colorScheme="green"  
            size="md"
            borderColor={tableBorderColor}
            borderWidth="1px"
            boxShadow="md"
          >
            <Thead bg={tableHeaderBgColor}>
              <Tr>
                <Th color={tableHeaderTextColor} fontSize="md" textTransform="uppercase">
                  Leak Type
                </Th>
                <Th color={tableHeaderTextColor} fontSize="md" textTransform="uppercase">
                  Count
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {Object.entries(leaks).map(([key, value], index) => (
                <Tr key={index}>
                  <Td fontWeight="bold" color={textPrimaryColor}>
                    {key}
                  </Td>
                  <Td fontWeight="bold" color={textHighlightColor}>
                    {value.toString()}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>

          {/* If no leaks data is available */}
          {Object.keys(leaks).length === 0 && (
            <Box textAlign="center" mt={6}>
              <Text fontSize="lg" color="gray.500">
                No leak data available
              </Text>
            </Box>
          )}
        </Box>
      </Box>

      <MainpageFooter />
    </div>
  );
};

export default LeakDetailsPage;
