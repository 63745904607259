import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Input, Button, Text, Table, Thead, Tbody, Tr, Th, Td, VStack, Heading, Center, useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const DeepSearch = () => {
  const [companyName, setCompanyName] = useState('');
  const [apiResponses, setApiResponses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  const sites = [
    "gist.github.com", "paste.mozilla.org", "ide.geeksforgeeks.org", "codepen.io",
    "pastebin.com", "gitlab.com/explore/snippets", "repl.it", "paste.ubuntu.com",
    "justpaste.it", "jsfiddle.net", "paste.centos.org", "justpaste.it (aka jpst.it)",
    "jsbin.com", "pastelink.net", "codebeautify.org", "controlc.com (aka pasted.co, tny.cz)",
    "invent.kde.org/explore/snippets", "ideone.com", "paste.rohitab.com", "codeshare.io",
    "paste.opensuse.org", "dotnetfiddle.net", "notes.io", "snipplr.com", "paste2.org",
    "hastebin.com", "ivpaste.com", "codepad.org", "justpaste.me", "pastebin.osuosl.org",
    "www.geany.org/p/", "bpa.st", "paste.ofcode.org", "paste.ee", "dpaste.org",
    "friendpaste.com", "defuse.ca/pastebin.htm", "dpaste.com", "cl1p.net", "pastie.org",
    "pastecode.io", "ghostbin.com", "pastebin.fr", "jsitor.com", "termbin.com", "p.ip.fi",
    "cutapaste.net", "paste.sh", "paste.jp", "paste-bin.xyz", "paste.xinu.at", "paste.debian.net",
    "vpaste.net", "paste.pound-python.org (read only)", "paste.org.ru", "apaste.info",
    "quickhighlighter.com", "sprunge.us", "commie.io", "everfall.com/paste/", "kpaste.net",
    "paste.frubar.net/ (read only archive)", "www.pastebin.pt", "n0paste.tk", "www.paste.lv",
    "tutpaste.com", "bitbin.it", "pastebin.fi", "nekobin.com", "pasteio.com",
    "www.paste4btc.com", "pastebin.ai"
  ];

  // Load results from sessionStorage if available
  useEffect(() => {
    const checkAuthentication = () => {
      const accessToken = sessionStorage.getItem('accessToken'); // Access token should be stored in session storage

      if (!accessToken) {
        navigate('/signin'); // Redirect to sign-in page if no access token
        return;
      }
    };

    checkAuthentication();

    const savedResults = sessionStorage.getItem('searchResults');
    if (savedResults) {
      setApiResponses(JSON.parse(savedResults));
    }
  }, [navigate]);

  const fetchData = async () => {
    setIsLoading(true);
    setApiResponses([]); // Clear previous responses

    const headers = {
      'Accept': 'application/json',
      'api-key': 'AQICAHi610eH2JqZkg2KBFmEoZDpxMsSdrGwwWa-T9g-i5VsgQHrW4jbEQirSb0W0T9RWPKjAAAAYzBhBgkqhkiG9w0BBwagVDBSAgEAME0GCSqGSIb3DQEHATAeBglghkgBZQMEAS4wEQQMX8NyNj4SvlwHQTA-AgEQgCArZn5rLd5grOj2fHyqEmlR8J4h2RsvlFxcmUkHJ4w8Qg',
              'access-token': 'ngopi',
    };

    const responses = []; // Temporary array to store all the API responses

    for (const site of sites) {
      const apiUrl = `https://rdvcaeqh7s5a6nhlkswsjoytyy0jdzlx.lambda-url.ap-south-1.on.aws/api/deepweb/deep_search/?site=${site}&search_term=${encodeURIComponent(companyName)}`;

      try {
        const response = await axios.get(apiUrl, { headers });

        if (response.status === 200) {
          const decodedResponse = response.data;
          Object.entries(decodedResponse).forEach(([key, value]) => {
            const resultArray = Array.isArray(value.Results) ? value.Results : [];
            responses.push({
              searchType: `${key} - ${site}`,
              resultLength: resultArray.length,
              originalJson: value,
              url: value.URL || 'No URL available',
            });
          });
        } else {
          responses.push({
            searchType: `Error: ${response.status} - ${site}`,
            resultLength: 0,
          });
        }
      } catch (error) {
        responses.push({
          searchType: `Error: ${error.message} - ${site}`,
          resultLength: 0,
        });
        toast({
          title: 'Error fetching data',
          description: `Failed to fetch data from ${site}. ${error.message}`,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }

    // Update state and sessionStorage after all responses are fetched
    setApiResponses(responses);
    sessionStorage.setItem('searchResults', JSON.stringify(responses));

    setIsLoading(false);
  };

  const deepSearchResult = () => {
    navigate('/deepsearch_result', { state: { data: apiResponses } });
  };

  return (
    <Box>
      {/* Header Section */}
      <Box padding="20px" bg="teal.500" color="white">
        <Heading as="h1" size="lg">
          Deep Search Tool
        </Heading>
      </Box>

      {/* Main Content Section */}
      <Box padding="20px" borderWidth="2px" borderRadius="md" boxShadow="md">
        <VStack spacing="4" align="start">
          <Text fontSize="xl" fontWeight="bold">Deep Search</Text>

          {/* Input and Button */}
          <Box mb="4">
            <Input
              placeholder="Enter company name"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              mb="2"
            />
            <Button onClick={fetchData} colorScheme="teal" isLoading={isLoading}>
              Fetch Data
            </Button>
          </Box>

          {/* Loading and Error Messages */}
          {isLoading && <Text>Loading...</Text>}
          {apiResponses.length === 0 && !isLoading && <Text>No search results available</Text>}
          {apiResponses.length > 0 && !isLoading && (
            <>
              <Box width="100%" overflowX="auto">
                <Table variant="simple" border="2px" borderColor="gray.200">
                  <Thead bg="teal.500" color="white">
                    <Tr>
                      <Th>Search Type</Th>
                      <Th>Result Length</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {apiResponses.map((response, index) => (
                      <Tr key={index}>
                        <Td fontWeight="bold" bg="gray.50" borderRight="2px solid" borderColor="gray.300">
                          {response.searchType}
                        </Td>
                        <Td bg="white" color="gray.800" paddingX="4">
                          {response.resultLength}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
              <Center mt="4">
                <Button colorScheme="teal" onClick={deepSearchResult}>
                  View Results
                </Button>
              </Center>
            </>
          )}
        </VStack>
      </Box>
    </Box>
  );
};

export default DeepSearch;
