import React, { useState, useEffect } from 'react';
import { Box, Button, FormControl, FormLabel, Input, Heading } from '@chakra-ui/react';
import { signUp } from '../../authService'; // Import your signUp function
import { useNavigate, useLocation } from 'react-router-dom';

const SignupPage = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [givenName, setGivenName] = useState(''); // State for given name
  const [familyName, setFamilyName] = useState(''); // State for family name
  const [customPlan, setCustomPlan] = useState('Free'); // State for custom plan
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Get the plan from the location state
    if (location.state && location.state.customPlan) {
      setCustomPlan(location.state.customPlan);
    }
  }, [location.state]);

  const handleSignUp = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert('Passwords do not match');
      return;
    }

    try {
      // Pass custom attributes including family_name and given_name
      await signUp(username, email, password, {
        'custom:plan': customPlan,
        'given_name': givenName,
        'family_name': familyName,
      });
      alert('Sign up successful! Please check your email for the confirmation code.');
      navigate('/confirm', { state: { username, email } });
    } catch (error) {
      alert(`Sign up failed: ${error.message}`);
    }
  };

  return (
    <Box p={8} maxW="lg" mx="auto">
      <Heading mb={6}>Sign Up</Heading> 
      <form onSubmit={handleSignUp}>
        <FormControl mb={4}>
          <FormLabel>Username</FormLabel>
          <Input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel>Email</FormLabel>
          <Input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel>Given Name</FormLabel>
          <Input
            type="text"
            value={givenName}
            onChange={(e) => setGivenName(e.target.value)}
            required
          />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel>Family Name</FormLabel>
          <Input
            type="text"
            value={familyName}
            onChange={(e) => setFamilyName(e.target.value)}
            required
          />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel>Password</FormLabel>
          <Input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel>Confirm Password</FormLabel>
          <Input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </FormControl>
        <Button colorScheme="teal" type="submit" width="full">
          Sign Up
        </Button>
      </form>
    </Box>
  );
};

export default SignupPage;
