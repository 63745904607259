import React, { useEffect, useState } from 'react';
import { Box, Button, Text, Divider, Table, Thead, Tbody, Tr, Th, Td, useToast, Icon } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { CheckCircleIcon, WarningIcon, LinkIcon } from '@chakra-ui/icons';

// Function to get user plan from session storage
const getUserPlan = () => {
  return sessionStorage.getItem('userPlan') || 'Free'; // Default to 'Free' if not found
};

const DomainInfoCard = ({ data }) => {
  const navigate = useNavigate();
  const toast = useToast();
  const [domainData, setDomainData] = useState(data);
  const userPlan = getUserPlan(); // Retrieve user plan here

  useEffect(() => {
    if (!domainData) {
      const savedData = sessionStorage.getItem('domainInfoData');
      if (savedData) {
        setDomainData(JSON.parse(savedData));
      }
    } else {
      sessionStorage.setItem('domainInfoData', JSON.stringify(domainData));
    }
  }, [domainData]);

  if (!domainData || !domainData.domain_info || !domainData.basic_info) {
    return <Text>Data is null or missing required fields</Text>;
  }

  const domainInfo = domainData.domain_info;

  // Ensure domainInfo is iterable
  const domainInfoArray = Array.isArray(domainInfo) ? domainInfo : [domainInfo];

  const basicInfo =
    domainData.basic_info?.dns_records?.dkim &&
    Object.keys(domainData.basic_info.dns_records.dkim).length > 0;

  let emailAddressFound = false;
  let phoneNumberFound = false;
  let dkimFound = false;

  // Iterate over domainInfo to find relevant data
  for (const item of domainInfoArray) {
    if (
      (item.registrant_contact && item.registrant_contact.email_address) ||
      (item.administrative_contact && item.administrative_contact.email_address)
    ) {
      emailAddressFound = true;
    }
    if (
      (item.registrant_contact && item.registrant_contact.phone_number) ||
      (item.administrative_contact && item.administrative_contact.phone_number)
    ) {
      phoneNumberFound = true;
    }
    if (basicInfo) {
      dkimFound = true;
    }
    if (emailAddressFound && phoneNumberFound && dkimFound) {
      break;
    }
  }

  const emailStatus = emailAddressFound ? 'Found' : 'Not Found';
  const phoneStatus = phoneNumberFound ? 'Found' : 'Not Found';
  const dkimStatus = dkimFound ? 'Found' : 'Not Found';

  const handleViewMoreClick = () => {
    if (userPlan === 'Free') {
      toast({
        title: 'Upgrade Required',
        description: 'You need to upgrade your plan to access this feature.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
    } else {
      navigate('/domain-info', { state: { data: domainData } });
    }
  };

  return (
    <Box
      p={6}
      borderWidth="1px"
      borderRadius="lg"
      boxShadow="lg"
      backgroundColor="gray.50"
      width={['95%', '80%', '70%']}
      mx="auto"
      mt={6}
      _hover={{ boxShadow: 'xl', transform: 'scale(1.02)', transition: '0.3s ease-in-out' }}
    >
      <Box display="flex" justifyContent="center" alignItems="center" mb={4}>
        <Text fontWeight="bold" fontSize="2xl" textAlign="center" color="blue.900" mb={4}>
          <Icon as={LinkIcon} color="blue.600" boxSize={6} mr={2} />
          Domain Information
        </Text>
      </Box>
      <Divider mb={4} />
      <Box>
        <Table variant="striped" colorScheme="teal">
          <Thead>
            <Tr>
              <Th fontWeight="bold" fontSize="md" color="gray.700">
                Category
              </Th>
              <Th fontWeight="bold" fontSize="md" color="gray.700">
                Status
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>Email ID</Td>
              <Td color={emailAddressFound ? 'green.500' : 'red.500'}>
                {emailAddressFound ? (
                  <Icon as={CheckCircleIcon} color="green.500" boxSize={5} mr={2} />
                ) : (
                  <Icon as={WarningIcon} color="red.500" boxSize={5} mr={2} />
                )}
                {emailStatus}
              </Td>
            </Tr>
            <Tr>
              <Td>Phone Number</Td>
              <Td color={phoneNumberFound ? 'green.500' : 'red.500'}>
                {phoneNumberFound ? (
                  <Icon as={CheckCircleIcon} color="green.500" boxSize={5} mr={2} />
                ) : (
                  <Icon as={WarningIcon} color="red.500" boxSize={5} mr={2} />
                )}
                {phoneStatus}
              </Td>
            </Tr>
            <Tr>
              <Td>DKIM</Td>
              <Td color={dkimFound ? 'green.500' : 'red.500'}>
                {dkimFound ? (
                  <Icon as={CheckCircleIcon} color="green.500" boxSize={5} mr={2} />
                ) : (
                  <Icon as={WarningIcon} color="red.500" boxSize={5} mr={2} />
                )}
                {dkimStatus}
              </Td>
            </Tr>
          </Tbody>
        </Table>
        <Box textAlign="center" mt={6}>
          <Button
            colorScheme="blue"
            variant="solid"
            size="lg"
            onClick={handleViewMoreClick}
            _hover={{ bg: 'blue.600' }}
            transition="background 0.3s"
          >
            View More
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default DomainInfoCard;
