import React, { useEffect, useState } from 'react';
import { ChakraProvider, Box, Button, Input, VStack, Spinner, Alert, AlertIcon,Center,
    useBreakpointValue,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Stack,
    HStack,
    Text,
    Icon,  useToast} from '@chakra-ui/react';
    import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
  import { FaBuilding, FaInfoCircle, FaQuestionCircle, FaLink, FaChevronDown, FaDownload,FaTools } from 'react-icons/fa';
  
  import ImageOne from '../../assets/images/Global_network_generated.jpg';
import axios from 'axios';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import 'jspdf-autotable'; // Import jsPDF AutoTable plugin
import DomainInfoCard from './companyInfoDetails/DomainInfoCard';
import EmailSecurityCard from './companyInfoDetails/EmailSecurityCard';
import SSLInfoCard from './companyInfoDetails/SSLInfoCard';
import SquattingCard from './companyInfoDetails/SquattingCard';
import SubdomainsCard from './companyInfoDetails/SubdomainsCard';
import DomainQualityScoreCard from './companyInfoDetails/DomainQualityScoreCard';
import DomainLeaksCard from './companyInfoDetails/DomainLeaksCard';
import logo from '../../assets/images/logo.png';
import UserNavbar from '../auth/UserNavbar';
import MainpageFooter from '../MainpageFooter';
import KeyServices from '../subcomponent/KeyServices';
import MainComponent from '../Maincomponent';
import {getapiToken} from '../auth/loginPage';

 // Function to get user plan from session storage
const getUserPlan = () => {
  return sessionStorage.getItem('userPlan') || 'Free'; // Default to 'Free' if not found 
};


// Function to generate and download PDF
const generatePdf = (data) => {
  const doc = new jsPDF();
  let yOffset = 10;
  const pageHeight = doc.internal.pageSize.height;
  const marginBottom = 10;

  const addPage = () => {
      doc.addPage();
      yOffset = 10;
  };

  const checkPageOverflow = (heightToAdd) => {
      if (yOffset + heightToAdd > pageHeight - marginBottom) {
          addPage();
      }
  };

  // Add Logo at the top of the PDF
  const logoWidth = 50;
  const logoHeight = 20;

  if (logo) {
      doc.addImage(logo, 'PNG', 10, yOffset, logoWidth, logoHeight);
      yOffset += logoHeight + 10;
  }

  // Add Title after the Logo
  doc.text('Company Domain deep and dark Analysis', 10, yOffset);
  yOffset += 20;

  if (data.domain_info && data.domain_info.basic_info) {
    const domainTitle = data.domain_info.basic_info.domain; 
  

    if (typeof domainTitle === 'string' && domainTitle.trim() !== '') {
        // Capitalize the first letter of the domainTitle
        const capitalizedDomainTitle = domainTitle.charAt(0).toUpperCase() + domainTitle.slice(1);

        doc.setFontSize(16); 
        doc.setFont('helvetica', 'bold'); 

        // Ensure yOffset is defined and a number
        if (typeof yOffset === 'number') {
            // Calculate the text width and center it
            const textWidth = doc.getTextWidth(capitalizedDomainTitle);
            const pageWidth = doc.internal.pageSize.getWidth();
            const xOffset = (pageWidth - textWidth) / 2; // Centering calculation

            doc.text(capitalizedDomainTitle, xOffset, yOffset); // Use calculated xOffset for centering
            yOffset += 10; // Increment yOffset for the next text
        } else {
            console.error("Invalid yOffset:", yOffset); 
        }

        doc.setFont('helvetica', 'normal'); 
        yOffset = doc.lastAutoTable.finalY ? doc.lastAutoTable.finalY + 10 : yOffset + 10; // Use lastAutoTable if available
    } else {
        console.error("Invalid domain title:", domainTitle); 
    }
} else {
    console.error("Invalid data structure:", data); 
}


// Domain Info Card
if (data.domain_info && data.domain_info.basic_info && data.domain_info.domain_info) {
doc.text('Domain Info Summary:', 10, yOffset);
yOffset += 10;

// Summary Data Extraction
const domainInfoArray = data.domain_info.domain_info; // Accessing the array of domain info
const registrantContact = domainInfoArray[0]?.registrant_contact || {};
const administrativeContact = domainInfoArray[0]?.administrative_contact || {};
const technicalContact = domainInfoArray[0]?.technical_contact || {};

// Initialize flags for found statuses
const emailAddressFound = !!(
    registrantContact.email_address || 
    administrativeContact.email_address || 
    technicalContact.email_address
);

const phoneNumberFound = !!(
    registrantContact.phone_number || 
    administrativeContact.phone_number || 
    technicalContact.phone_number
);

// Checking for DKIM in the 'dns_records' object
const dkimFound = data.domain_info.basic_info?.dns_records?.dkim && 
    Object.keys(data.domain_info.basic_info.dns_records.dkim).length > 0;


autoTable(doc, {
    startY: yOffset,
    head: [['Category', 'Status']],
    body: [
        ['Email Address', emailAddressFound ? 'Found' : 'Not Found'],
        ['Phone Number', phoneNumberFound ? 'Found' : 'Not Found'],
        ['DKIM', dkimFound ? 'Found' : 'Not Found'],
    ],
});

yOffset = doc.lastAutoTable.finalY + 10; // Update yOffset after summary
}

  // Email Security Card
  if (data.email_security) {
    doc.text('Email Security Summary:', 10, yOffset);
    yOffset += 10;

    // Email Security Data Extraction
    const { scanResult, overall_score: overallScore, advice } = data.email_security;

    // Initialize flags for found statuses
    const dkimFound = scanResult.dkim.length > 0;
    const bimiFound = scanResult.bimi.length > 0;
    const dmarcFound = scanResult.dmarc.length > 0;
    const mxFound = scanResult.mx.length > 0;
    const spfFound = scanResult.spf.length > 0;

    autoTable(doc, {
        startY: yOffset,
        head: [['Category', 'Status']],
        body: [
            ['DKIM', dkimFound ? 'Found' : 'Not Found'],
            ['BIMI', bimiFound ? 'Found' : 'Not Found'],
            ['DMARC', dmarcFound ? 'Found' : 'Not Found'],
            ['MX Records', mxFound ? 'Found' : 'Not Found'],
            ['SPF', spfFound ? 'Found' : 'Not Found'],
        ],
    });

    yOffset = doc.lastAutoTable.finalY + 10; // Update yOffset after summary

    // Detailed Advice Section
if (advice) {
doc.text('Observation and Recommendation:', 10, yOffset); // Section title
yOffset += 10;

// Prepare the table data
const adviceTable = [];

Object.keys(advice).forEach((key) => {
    const adviceEntries = advice[key];
    
    // Add rows to the table for each advice entry under the corresponding category
    adviceEntries.forEach(entry => {
        adviceTable.push([key.toUpperCase(), entry]);
    });
});

// Generate the table using autoTable
autoTable(doc, {
    startY: yOffset,
    head: [['Category', 'Recommendation']],
    body: adviceTable,
});

yOffset = doc.lastAutoTable.finalY + 10; // Update yOffset after the table
}


    // Overall Score Section
    let scoreText = 'Bad';
    let scoreColor = 'red.500';

    if (overallScore >= 85) {
        scoreText = 'Good';
        scoreColor = 'green.500';
    } else if (overallScore >= 65) {
        scoreText = 'Average';
        scoreColor = 'orange.500';
    }

    doc.text(`Overall Score: ${scoreText}`, 10, yOffset);
    doc.text(`Score Value: ${overallScore}`, 10, yOffset + 10);
    yOffset += 30; // Update yOffset for the next section
}



//ssl card
if (data.ssl_info && data.ssl_info.certificates) {
checkPageOverflow(20);
doc.text('SSL Certificate Summary:', 10, yOffset);
yOffset += 10;

const certificates = data.ssl_info.certificates;
const expiredCertificates = certificates.filter(cert => new Date(cert.not_after) < new Date());
const nonExpiredCertificates = certificates.filter(cert => new Date(cert.not_after) >= new Date());

// Summary Table
checkPageOverflow(40);
autoTable(doc, {
    startY: yOffset,
    body: [
        ['SSL Status', 'Found'],
        ['Number of Certificates', certificates.length],
        ['Expired Certificates', expiredCertificates.length],
        ['Non-Expired Certificates', nonExpiredCertificates.length],
    ],
});
yOffset = doc.lastAutoTable.finalY + 10;
};


// Squatting Info Card
if (data.squatting) {
doc.text('Squatting Info Summary:', 10, yOffset);
yOffset += 10;

// Get scores or default to '-'
const typoSquattingScore = data.squatting.scores.typo_squatting ?? '-';
const bitSquattingScore = data.squatting.scores.bit_squatting ?? '-';
const homoglyphsScore = data.squatting.scores.homoglyphs ?? '-';

// Calculate lengths of each squatting category
const typoSquattingLength = data.squatting.typo_squatting.length;
const bitSquattingLength = data.squatting.bit_squatting.length;
const homoglyphsLength = data.squatting.homoglyphs.reduce((acc, arr) => acc + arr.length, 0);

// Create the autoTable with lengths and scores
autoTable(doc, {
    startY: yOffset,
    head: [['Category', 'Length', 'Score']],
    body: [
        ['Typo Squatting', typoSquattingLength, typoSquattingScore],
        ['Bit Squatting', bitSquattingLength, bitSquattingScore],
        ['Homoglyphs', homoglyphsLength, homoglyphsScore],
    ],
});

yOffset = doc.lastAutoTable.finalY + 10; // Update yOffset after squatting summary
}



// Subdomains Card
if (data.domain_quality_score.SubDomains.sub_domains) {
// Title for the section
doc.text('Subdomains Summary', 10, yOffset);
yOffset += 10;
doc.line(10, yOffset, 200, yOffset); // Horizontal line to separate the section
yOffset += 5;

// Prepare subdomains data
const subdomains = data.domain_quality_score.SubDomains.sub_domains.subdomains || [];
const wildcardSubdomains = data.domain_quality_score.SubDomains.sub_domains.wildcard_subdomains || [];
const foundEmails = data.domain_quality_score.SubDomains.sub_domains.found_emails || [];
const resolvedIps = data.domain_quality_score.SubDomains.sub_domains.resolved_ips || {};

// Create autoTable to display the Subdomains data
autoTable(doc, {
  startY: yOffset,
  head: [['Category', 'Count']],
  body: [
    ['Subdomains', subdomains.length],
    ['Wildcard Subdomains', wildcardSubdomains.length],
    ['Found Emails', foundEmails.length],
    ['Resolved IPs', Object.keys(resolvedIps).length],
  ],
});

yOffset = doc.lastAutoTable.finalY + 10; // Update yOffset after the table
}

// Domain Quality Scores Table
if (data.domain_quality_score && Object.keys(data.domain_quality_score).length > 0) {
  doc.text('Domain Quality Scores:', 10, yOffset);
  yOffset += 10;

  const tableBody = Object.keys(data.domain_quality_score)
    .filter(domain => domain !== 'SubDomains' && data.domain_quality_score[domain]?.data)
    .map(domain => {
      const domainData = data.domain_quality_score[domain]?.data || {};
      const ipScore = domainData?.ip_score || {};
      const ipAddress = ipScore?.ip_address || 'N/A';
      const normalizedIpScore = ipScore?.normalized_ip_score?.toString() || 'N/A';

      // Apply conditional coloring for normalized IP score
      const color = normalizedIpScore !== 'N/A' && parseFloat(normalizedIpScore) > 0 ? 'green' : 'red';

      return [
        domain, 
        ipAddress, 
        { content: normalizedIpScore, styles: { textColor: color === 'green' ? [0, 128, 0] : [255, 0, 0] } }
      ];
    });

  if (tableBody.length > 0) {
    autoTable(doc, {
      startY: yOffset,
      head: [['Domain', 'IP Address', 'Normalized IP Score']],
      body: tableBody,
      theme: 'striped',
    });

    yOffset = doc.lastAutoTable.finalY + 10; // Update yOffset after the table
  } else {
    console.error('No valid domain quality score data found.');
  }
} else {
  console.error('Domain quality score object is empty or not valid.');
}


// Domain Leaks section
if (data?.domain_leaks?.data) {
// Add title for Domain Leaks
doc.text('Domain Leaks Summary:', 10, yOffset); // Add the title
yOffset += 10; // Increase the yOffset to move the next text down

// Extract total leaks
const totalLeaks = data.domain_leaks.data.total;

// If you're adding a table, ensure to update the yOffset after the table
// autoTable(doc, { ... });

// Safely check and use finalY from last table or yOffset
const lastTableY = doc.lastAutoTable?.finalY || yOffset;

// Add some more space before adding the total leaks text
yOffset = lastTableY +25; // Add a buffer space after the last element
doc.text(`Total Leaks: ${totalLeaks}`, 10, yOffset); // Add total leaks info
yOffset += 10; // Move the yOffset further down if you plan to add more content
} else {
// If no data is available, print a message
doc.text('No Data Available', 10, yOffset);
yOffset += 10;
}



  // Domain Info Details
  if (data.domain_info && data.domain_info.domain_info) {
      doc.text('Domain Info Details:', 10, yOffset);
      yOffset += 10;



      data.domain_info.domain_info.forEach(info => {
          checkPageOverflow(60);
          autoTable(doc, {
              startY: yOffset,
              body: [
                  ['Create Date', info.create_date ?? 'N/A'],
                  ['Update Date', info.update_date ?? 'N/A'],
                  ['Expiry Date', info.expiry_date ?? 'N/A'],
                  ['Registrar Name', info.domain_registrar?.registrar_name ?? 'N/A'],
                  ['Full Name', info.registrant_contact?.full_name ?? 'N/A'],
                  ['Company Name', info.registrant_contact?.company_name ?? 'N/A'],
                  ['Email Address', info.registrant_contact?.email_address ?? 'N/A'],
                  ['Phone Number', info.registrant_contact?.phone_number ?? 'N/A'],
              ],
          });
          yOffset = doc.lastAutoTable.finalY + 10;
      });
  }

  // SSL Certificate Details
  if (data.ssl_info && data.ssl_info.certificates) {
      checkPageOverflow(20);
      doc.text('SSL Certificate Details:', 10, yOffset);
      yOffset += 10;

      const certificates = data.ssl_info.certificates;
      const expiredCertificates = certificates.filter(cert => new Date(cert.not_after) < new Date());
      const nonExpiredCertificates = certificates.filter(cert => new Date(cert.not_after) >= new Date());

      // Summary Table
      checkPageOverflow(40);
      autoTable(doc, {
          startY: yOffset,
          body: [
              ['SSL Status', 'Found'],
              ['Number of Certificates', certificates.length],
              ['Expired Certificates', expiredCertificates.length],
              ['Non-Expired Certificates', nonExpiredCertificates.length],
          ],
      });
      yOffset = doc.lastAutoTable.finalY + 10;

      // Detailed Certificates Table
      checkPageOverflow(certificates.length * 10);
      autoTable(doc, {
          startY: yOffset,
          head: [['Issuer CA ID', 'Serial Number', 'Issuer Name', 'Common Name', 'Expiration Date']],
          body: certificates.map(cert => [
              cert.issuer_ca_id,
              cert.serial_number,
              cert.issuer_name,
              cert.common_name,
              new Date(cert.not_after).toLocaleDateString(),
          ]),
      });
      yOffset = doc.lastAutoTable.finalY + 10;
  } else {
      checkPageOverflow(20);
      doc.text('No SSL Certificate Details Available', 10, yOffset);
      yOffset += 10;
  }

  if (data.email_security && data.email_security.scanResult) {
      doc.text('Email Security Details:', 10, yOffset);
      yOffset += 10;
    
      const { bimi, dkim, dmarc, mx, spf } = data.email_security.scanResult;
    
      // BIMI
      if (bimi && bimi.length > 0) {
          checkPageOverflow(20);
          doc.text('BIMI:', 10, yOffset);
          yOffset += 10;
          autoTable(doc, {
              startY: yOffset,
              head: [['Index', 'Value']],
              body: bimi.map((item, index) => [index, item]),
          });
          yOffset = doc.lastAutoTable.finalY + 10;
      }
    
      // DKIM
      if (dkim && dkim.length > 0) {
          checkPageOverflow(20);
          doc.text('DKIM:', 10, yOffset);
          yOffset += 10;
          autoTable(doc, {
              startY: yOffset,
              head: [['Selector', 'Domain', 'Public Key']],
              body: dkim.map(entry => [
                  entry.selector || 'N/A',
                  entry.domain || 'N/A',
                  entry.public_key || 'N/A'
              ]),
          });
          yOffset = doc.lastAutoTable.finalY + 10;
      }
    
      // DMARC
      if (dmarc && dmarc.length > 0) {
          checkPageOverflow(20);
          doc.text('DMARC:', 10, yOffset);
          yOffset += 10;
          doc.text(dmarc.join(', '), 10, yOffset);
          yOffset += 10;
      }
    
      // MX Records
      if (mx && mx.length > 0) {
          checkPageOverflow(20);
          doc.text('MX Records:', 10, yOffset);
          yOffset += 10;
          doc.text(mx.join(', '), 10, yOffset);
          yOffset += 10;
      }
    
      // SPF Records
      if (spf && spf.length > 0) {
          checkPageOverflow(20);
          doc.text('SPF:', 10, yOffset);
          yOffset += 10;
          autoTable(doc, {
              startY: yOffset,
              head: [['Index', 'Value']],
              body: spf.map((item, index) => [index, item]),
          });
          yOffset = doc.lastAutoTable.finalY + 10;
      }
    } else {
      checkPageOverflow(20);
      doc.text('No Email Security Details Available', 10, yOffset);
      yOffset += 10;
    }
    //squatting
//bit squtting
    if (data.squatting && data.squatting.bit_squatting) {
      doc.text('Bit Squatting Details:', 10, yOffset);
      yOffset += 10;
  
      // Function to decode characters
      const decodeCharacters = (input) => {
          try {
              return decodeURIComponent(escape(input));
          } catch (e) {
              return input; // Return the input unchanged if decoding fails
          }
      };
  
      // Prepare the data for the table with decoding
      const bitSquattingData = data.squatting.bit_squatting.map(info => [decodeCharacters(info)]);
  
      // Add the table to the PDF
      autoTable(doc, {
          startY: yOffset,
          head: [['Bit Squatting Entries']], // Table header
          body: bitSquattingData,           // Table body
          margin: { left: 10, right: 10 },
          styles: { cellPadding: 2, fontSize: 10 },
          didPageAdd: () => { // Callback function for page handling
              yOffset = doc.lastAutoTable.finalY + 10;
          },
      });
  
      // Update yOffset after adding the table
      yOffset = doc.lastAutoTable.finalY + 10;
  }
// typosquatting
if (data.squatting && data.squatting.typo_squatting) {
  doc.text('Typo Squatting Details:', 10, yOffset);
  yOffset += 10;

  // Function to decode characters
  const decodeCharacters = (input) => {
      try {
          return decodeURIComponent(escape(input));
      } catch (e) {
          return input; // Return the input unchanged if decoding fails
      }
  };

  // Check if there are typo squatting entries
  if (data.squatting.typo_squatting.length > 0) {
      // Prepare the data for the table with decoding
      const typoSquattingData = data.squatting.typo_squatting.map(info => [decodeCharacters(info)]);

      // Add the table to the PDF
      autoTable(doc, {
          startY: yOffset,
          head: [['Typo Squatting Entries']], // Table header
          body: typoSquattingData,           // Table body
          margin: { left: 10, right: 10 },
          styles: { cellPadding: 2, fontSize: 10 },
          didPageAdd: () => { // Callback function for page handling
              yOffset = doc.lastAutoTable.finalY + 10;
          },
      });

      // Update yOffset after adding the table
      yOffset = doc.lastAutoTable.finalY + 10;
  } else {
      // Handle the case where there are no typo squatting entries
      doc.text('No Typo Squatting Data Available', 10, yOffset);
      yOffset += 10;
  }
}

if (data.squatting && data.squatting.homoglyphs) {
  doc.text('Homoglyphs Squatting Details:', 10, yOffset);
  yOffset += 10;

  // Function to decode characters
  const decodeCharacters = (input) => {
      try {
          return decodeURIComponent(escape(input));
      } catch (e) {
          return input; // Return the input unchanged if decoding fails
      }
  };
//homoglyphs
  if (data.squatting.homoglyphs.length > 0) {
      const homoglyphsData = data.squatting.homoglyphs.flat().map(info => [decodeCharacters(info)]);

      autoTable(doc, {
          startY: yOffset,
          head: [['Homoglyphs Squatting Entries']],
          body: homoglyphsData,
          margin: { left: 10, right: 10 },
          styles: { cellPadding: 2, fontSize: 10 },
          didPageAdd: () => {
              yOffset = doc.lastAutoTable.finalY + 10;
          },
      });

      yOffset = doc.lastAutoTable.finalY + 10;
  } else {
      doc.text('No Homoglyphs Data Available', 10, yOffset);
      yOffset += 10;
  }
}

//subdomains
const decodeCharacters = (input) => {
  try {
    return decodeURIComponent(escape(input));
  } catch (e) {
    return input; // Return the input unchanged if decoding fails
  }
};

// Handle subdomains
if (data.subdomains && data.subdomains.sub_domains) {
  doc.text('Subdomains:', 10, yOffset);
  yOffset += 10;

  // Subdomains
  if (Array.isArray(data.subdomains.sub_domains.subdomains) && data.subdomains.sub_domains.subdomains.length > 0) {
    const subdomainsData = data.subdomains.sub_domains.subdomains.map(info => [decodeCharacters(info)]);

    autoTable(doc, {
      startY: yOffset,
      head: [['Subdomains Entries']],
      body: subdomainsData,
      margin: { left: 10, right: 10 },
      styles: { cellPadding: 2, fontSize: 10 },
      didPageAdd: () => {
        yOffset = doc.lastAutoTable.finalY + 10;
      },
    });

    yOffset = doc.lastAutoTable.finalY + 10;
  } else {
    doc.text('No Subdomains Data Available', 10, yOffset);
    yOffset += 10;
  }

  // Wildcard Subdomains
  if (Array.isArray(data.subdomains.sub_domains.wildcard_subdomains) && data.subdomains.sub_domains.wildcard_subdomains.length > 0) {
    const wildcardSubdomainsData = data.subdomains.sub_domains.wildcard_subdomains.map(info => [decodeCharacters(info)]);

    autoTable(doc, {
      startY: yOffset,
      head: [['Wildcard Subdomains Entries']],
      body: wildcardSubdomainsData,
      margin: { left: 10, right: 10 },
      styles: { cellPadding: 2, fontSize: 10 },
      didPageAdd: () => {
        yOffset = doc.lastAutoTable.finalY + 10;
      },
    });

    yOffset = doc.lastAutoTable.finalY + 10;
  } else {
    doc.text('No Wildcard Subdomains Data Available', 10, yOffset);
    yOffset += 10;
  }

  // Found Emails
  if (Array.isArray(data.subdomains.sub_domains.found_emails) && data.subdomains.sub_domains.found_emails.length > 0) {
      const foundEmailsData = data.subdomains.sub_domains.found_emails.map(info => [decodeCharacters(info)]);

      autoTable(doc, {
        startY: yOffset,
        head: [['Found Emails Entries']],
        body: foundEmailsData,
        margin: { left: 10, right: 10 },
        styles: { cellPadding: 2, fontSize: 10 },
        didPageAdd: () => {
          yOffset = doc.lastAutoTable.finalY + 10;
        },
      });

      yOffset = doc.lastAutoTable.finalY + 10;
    } else {
      doc.text('No Found Emails Data Available', 10, yOffset);
      yOffset += 10;
    }

  // Resolved IPs
  if (data.subdomains.sub_domains.resolvedIps && typeof data.subdomains.sub_domains.resolvedIps === 'object' && Object.keys(data.subdomains.sub_domains.resolvedIps).length > 0) {
    const resolvedIpsData = Object.entries(data.subdomains.sub_domains.resolvedIps).map(([key, value]) => [key, value.toString()]);

    autoTable(doc, {
      startY: yOffset,
      head: [['Domain', 'Resolved IP']],
      body: resolvedIpsData,
      margin: { left: 10, right: 10 },
      styles: { cellPadding: 2, fontSize: 10 },
      didPageAdd: () => {
        yOffset = doc.lastAutoTable.finalY + 10;
      },
    });

    yOffset = doc.lastAutoTable.finalY + 10;
  } else {
    doc.text('No Resolved IPs Data Available', 10, yOffset);
    yOffset += 10;
  }
}

// Domain Quality Score Details
if (data.domain_quality_score && Object.keys(data.domain_quality_score).length > 0) {
  doc.setFontSize(14);
  doc.setFont('helvetica', 'bold');
  doc.text('Domain Quality Score Details:', 10, yOffset);
  yOffset += 10;
  doc.setFont('helvetica', 'normal');
  doc.setFontSize(12);

  const domainKeys = Object.keys(data.domain_quality_score).filter(
    domain => domain !== 'SubDomains' && data.domain_quality_score[domain]?.data
  );

  domainKeys.forEach((domain) => {
    const domainData = data.domain_quality_score[domain]?.data || {};
    const ipScore = domainData?.ip_score || {};

    const metrics = [
      ['IP Address', ipScore?.ip_address || 'N/A'],
      ['Normalized IP Score', ipScore?.normalized_ip_score?.toString() || 'N/A'],
      ['Attack Surface Score', ipScore?.detailed_scores?.attack_surface?.toString() || 'N/A'],
      ['Encryption Score', ipScore?.detailed_scores?.encryption?.toString() || 'N/A'],
      ['Open Ports', ipScore?.results_detailed?.ports?.open?.length > 0 ? ipScore.results_detailed.ports.open.join(', ') : 'None'],
      ['Ports Score', ipScore?.results_detailed?.ports?.score?.toString() || 'N/A'],
      ['Torrents Score', ipScore?.results_detailed?.torrents?.score?.toString() || 'N/A'],
      ['Load Balancer', ipScore?.results_detailed?.is_load_balancer ? 'Yes' : 'No'],
    ];

    checkPageOverflow(60); // Adjust based on expected content height

    // Domain Title
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.text(domain, 10, yOffset);
    yOffset += 10;

    doc.setFont('helvetica', 'normal');

    // Add the table
    autoTable(doc, {
      startY: yOffset,
      head: [['Metric', 'Value']],
      body: metrics,
      theme: 'grid',
      styles: { fontSize: 10 },
      headStyles: { fillColor: [22, 160, 133] },
      margin: { left: 10, right: 10 },
      didDrawPage: (dataArg) => {
        yOffset = dataArg.cursor.y;
      },
    });

    yOffset = doc.lastAutoTable.finalY + 10;
  });
} else {
  doc.text('No Domain Quality Score data available.', 10, yOffset);
  yOffset += 10;
}


//domain leaks
if (data?.domain_leaks?.data) {
  doc.text('Domain Leaks Details:', 10, yOffset);
  yOffset += 10;

  const leaks = data.domain_leaks.data.leaks;
  const totalLeaks = data.domain_leaks.data.total;

  // Create an array of leak entries for the PDF table
  const leakEntries = Object.entries(leaks).map(([source, count]) => [source, count.toString()]);

  autoTable(doc, {
      startY: yOffset,
      head: [['Source', 'Count']],
      body: leakEntries,
  });

  // Add total leaks at the end
  doc.text(`Total Leaks: ${totalLeaks}`, 10, doc.lastAutoTable.finalY + 10);
  yOffset = doc.lastAutoTable.finalY + 20;
} else {
  doc.text('No Data Available', 10, yOffset);
  yOffset += 10;
}



  // Final PDF Save
  doc.save('company_and_security_report.pdf');
};



const CompanyInformation = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const userPlan = getUserPlan(); // Assume this is defined elsewhere
  const [message, setMessage] = useState('');
  const [token, setToken] = useState(null);
  const [apiResults, setApiResults] = useState({});
  const [domain, setDomain] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const baseUrl = 'https://ucq3wjoxaffrv6xtbrvaqh3r4e0sgued.lambda-url.ap-south-1.on.aws/api/domain/';
  const endpoints = [
    'domain_info',
    'email_security',
    'ssl_info',
    'squatting',
    'domain_quality_score',
    'domain_leaks',
  ];

  useEffect(() => {
    const storedToken = getapiToken(); // Retrieve token

    setToken(storedToken);

    const accessToken = sessionStorage.getItem('accessToken');
    if (!accessToken) {
      navigate('/signin');
      return;
    }

    const savedResults = sessionStorage.getItem('searchResults');
    if (savedResults) {
      setApiResults(JSON.parse(savedResults));
      // console.log(savedResults)
    }
  }, [navigate]);

  const fetchApiData = async () => {
    if (!token) {
      setMessage('Token is missing. Please sign in first.');
      return;
    }
  
    setIsLoading(true);
    setError(null);
  
    try {
      const fetchPromises = endpoints.map(async (endpoint) => {
        const result = await fetch(`${baseUrl}${endpoint}?domain=${domain}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
  
        if (!result.ok) {
          throw new Error(`Network response was not ok for ${endpoint}: ${result.statusText}`);
        }
  
        return await result.json();
      });
  
      const results = await Promise.all(fetchPromises);
      const data = results.reduce((acc, result, index) => {
        acc[endpoints[index]] = result;
        return acc;
      }, {});
  
      setApiResults(data);
      console.log(data)
      setMessage('Data fetched successfully!');
  
      // Set the fetched results in session storage
      sessionStorage.setItem('searchResults', JSON.stringify(data));
    } catch (error) {
      setMessage(`Error fetching data: ${error.message}`);
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  

  const handleSearch = () => {
    fetchApiData();
  };

  const handleDownloadClick = () => {
    if (userPlan === 'Free') {
      toast({
        title: 'Upgrade Required',
        description: 'You need to upgrade your plan to download the PDF.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
    } else {
      generatePdf(apiResults); // Assume this is defined elsewhere
    }
  };

  const paddingX = useBreakpointValue({ base: '4', md: '6', lg: '8' });
  const buttonWidth = useBreakpointValue({ base: '80%', sm: '60%', md: '50%' });
  const containerWidth = useBreakpointValue({ base: '90%', md: '70%', lg: '60%' });
  const containerHeight = useBreakpointValue({ base: '70%', md: '60%', lg: '50%' });

  return (
    <div>
     <div
    style={{
      position: 'fixed',                         // Fix the position of the navbar
      top: 0,                                    // Stick it to the top
      left: 0,                                   // Align it to the left edge
      width: '100%',                             // Full width for the navbar
      zIndex: 1000,                              // Ensure it's above other content
    }}
  >
   <UserNavbar /> 
  </div>
    
     <ChakraProvider>
<div>
    <Box
height={{ base: '60vh', md: '60vh', lg: '60vh' }}
backgroundImage={`url(${ImageOne})`}
backgroundSize="cover"
backgroundPosition="center"
position="relative"
>
<Center height="100%">
  <Box
    width={containerWidth}
    height={containerHeight}
    backgroundColor="rgba(0, 0, 0, 0.6)"
    color="white"
    borderRadius="20px"
    paddingX={paddingX}
    paddingY={{ base: '4', md: '6', lg: '8' }}
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    textAlign="center"
  >
    <Stack
      direction={{ base: 'column', md: 'row' }}
      spacing={{ base: '4', md: '6' }}
      align="center"
      justify="center"
      mb={6}
      wrap="wrap" // Ensure that items wrap if needed
      gap={{ base: '4', md: '6' }} // Responsive gap
    >
      {/* Normal Button */}
  <Button
    bg="transparent"
    color="white"
    borderRadius="md"
    _hover={{ bg: 'rgba(0, 0, 0, 0.3)' }}
    onClick={() => navigate('/companyinfo')}
  >
    <HStack spacing={2}>
    <Icon as={FaBuilding} />
      <Text>Domain Risk Analysis</Text>
    </HStack>
  </Button>


      {/* Normal Button */}
<Button
        bg="transparent"
        color="white"
        borderRadius="md"
        _hover={{ bg: 'rgba(0, 0, 0, 0.3)' }}
        onClick={() => navigate('/companysearch')}
      >
        <HStack spacing={2}>
        <Icon as={FaInfoCircle} />
          <Text>Deep and Dark Search</Text>
        </HStack>
      </Button>

      {/* Dropdown Button 3 */}
      <Menu>
        <MenuButton
          as={Button}
          bg="transparent"
          color="white"
          borderRadius="md"
          _hover={{ bg: 'rgba(0, 0, 0, 0.3)' }}
          rightIcon={<FaChevronDown />}
        >
          <HStack spacing={2}>
            <Icon as={FaTools} />
            <Text>Tools</Text>
          </HStack>
        </MenuButton>
        <MenuList bg="black" color="white">
          <MenuItem
            bg="black"
            _hover={{ bg: 'rgba(255, 255, 255, 0.2)' }}
            onClick={() => navigate('/dkim')}
          >
            DKIM
          </MenuItem>
          <MenuItem
            bg="black"
            _hover={{ bg: 'rgba(255, 255, 255, 0.2)' }}
            onClick={() => navigate('/securityheaders')}
          >
            Security Headers
          </MenuItem>
          <MenuItem
            bg="black"
            _hover={{ bg: 'rgba(255, 255, 255, 0.2)' }}
            onClick={() => navigate('/sitetechnology')}
          >
            Site Technology
          </MenuItem>
          <MenuItem
                    bg="black"
                    _hover={{ bg: 'rgba(255, 255, 255, 0.2)' }}
                    onClick={() => navigate('/url')}
                  >
                    URL Analysis
                  </MenuItem>
        </MenuList>
      </Menu>

      {/* Normal Button
      <Button
        bg="transparent"
        color="white"
        borderRadius="md"
        _hover={{ bg: 'rgba(0, 0, 0, 0.3)' }}
        onClick={() => navigate('/url')}
      >
        <HStack spacing={2}>
          <Icon as={FaLink} />
          <Text>URL Analysis</Text>
        </HStack>
      </Button> */}
    </Stack>

    {/* Input and Search Button */}
    <VStack spacing={4} width={containerWidth} align="center">
      <Input
        placeholder="Company Name: 'example.com'"
        value={domain}
        onChange={(e) => setDomain(e.target.value)}
        size="md"
        color="white"
        backgroundColor="rgba(255, 255, 255, 0.2)"
        borderRadius="md"
        _placeholder={{ color: 'white' }}
        border="1px solid white"
      />
      {/* <Button
      onClick={handleSearch}
        colorScheme="teal"
        width={buttonWidth}
        borderRadius="20px"
        backgroundColor="rgba(0, 0, 0, 0.5)"
        _hover={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}
      >
        Search
      </Button> */}
      <MainComponent domain={domain} onSuccess={handleSearch} />
    </VStack>
  </Box>
</Center>
</Box>
<br/><br/> 

    {/* <Box p={paddingX} width={containerWidth} height={containerHeight}>
      <VStack spacing={4} align="stretch">
        <Input
          placeholder="Enter domain"
          value={domain}
          onChange={(e) => setDomain(e.target.value)}
        />
        <Button colorScheme="teal" onClick={handleSearch} width={buttonWidth}>
          Search
        </Button>
      </VStack> */}

      <center>
        {isLoading && <Spinner size="xl" />}
        {error && (
          <Alert status="error">
            <AlertIcon />
            {error}
          </Alert>
        )}
      </center>

      {Object.keys(apiResults).length > 0 && (
        <VStack spacing={4} align="stretch">
          {apiResults.domain_info && apiResults.domain_info.basic_info && (
            <DomainInfoCard data={apiResults.domain_info} />
          )}
          {apiResults.email_security && apiResults.email_security.scanResult && (
            <EmailSecurityCard data={apiResults.email_security} />
          )}
          {apiResults.ssl_info && (
            <SSLInfoCard data={apiResults.ssl_info} />
          )}
          {apiResults.squatting && (
            <SquattingCard data={apiResults.squatting} />
          )}
          {apiResults.domain_quality_score && (
            <SubdomainsCard data={apiResults.domain_quality_score} />
          )}
          {apiResults.domain_quality_score && (
            <DomainQualityScoreCard data={apiResults.domain_quality_score} />
          )}
          {apiResults.domain_leaks && (
            <DomainLeaksCard data={apiResults.domain_leaks} />
          )}
          <center><Button
  leftIcon={<FaDownload />} // Adding an icon
  width='50%'
  size="lg" // Larger size
  colorScheme="teal" // Change color scheme for better aesthetics
  variant="solid" // Solid variant
  borderRadius="md" // Rounded corners
  bgGradient="linear(to-r, teal.500, green.500)" // Gradient background
  _hover={{ 
    bgGradient: "linear(to-r, teal.400, green.400)", // Slightly different gradient on hover
    boxShadow: "lg", // Add shadow on hover
  }}
  _active={{ 
    bgGradient: "linear(to-r, teal.600, green.600)", // Darker gradient on active
    transform: "scale(0.98)", // Slightly smaller on click
  }}
  onClick={handleDownloadClick}
>
  Download PDF
</Button>
</center>
        </VStack>
      )}
      <br />
      {/* Render KeyServices only if apiResults is empty */}
      {Object.keys(apiResults).length === 0 && <KeyServices />}
        
      <br />
      <MainpageFooter/>
            </div>
        </ChakraProvider>

        </div>
  );
};

export default CompanyInformation;
