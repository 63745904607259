import React from 'react';
import { Box, Text, Divider, Table, Thead, Tbody, Tr, Th, Td, Button, useToast } from '@chakra-ui/react';
import { InfoOutlineIcon, CheckCircleIcon, CloseIcon, LockIcon } from '@chakra-ui/icons'; // Importing icons
import { useNavigate } from 'react-router-dom';
import { isBefore } from 'date-fns';

// Function to get user plan from session storage
const getUserPlan = () => {
  return sessionStorage.getItem('userPlan') || 'Free'; // Default to 'Free' if not found
};

const SSLInfoCard = ({ data }) => {
  const navigate = useNavigate();
  const toast = useToast();
  const userPlan = getUserPlan(); // Retrieve user plan here

  // Check if SSL certificates are found
  const sslFound = data && data.certificates && data.certificates.length > 0;

  // Calculate counts for expired and non-expired certificates
  const now = new Date();
  const expiredCertificates = data.certificates.filter(cert => isBefore(new Date(cert.not_after), now));
  const nonExpiredCertificates = data.certificates.filter(cert => !isBefore(new Date(cert.not_after), now));

  const handleViewMoreClick = () => {
    if (userPlan === 'Free') {
      toast({
        title: 'Upgrade Required',
        description: 'You need to upgrade your plan to access this feature.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
    } else {
      navigate('/ssl-certificate', { state: { certificates: data.certificates } });
    }
  };

  return (
    <Box
      p={6}
      borderWidth="1px"
      borderRadius="lg"
      boxShadow="lg"
      backgroundColor="gray.50"
      width={['95%', '80%', '70%']} // Adjust width based on screen size
      mx="auto"
      mt={6}
      _hover={{ boxShadow: 'xl', transform: 'scale(1.02)', transition: '0.3s ease-in-out' }}
    >
       <Box display="flex" justifyContent="center" alignItems="center" mb={4}>
        <LockIcon boxSize={8} color="blue.600" mr={2} /> {/* SSL Certificate Icon */}
        <Text fontWeight="bold" fontSize="2xl" textAlign="center" color="blue.800">
          SSL Certificate Overview
        </Text>
      </Box>
      <Divider mb={4} />
      <Table variant="striped" colorScheme="teal">
        <Thead>
          <Tr>
            <Th fontWeight="bold" fontSize="md" color="blue.700">Category</Th>
            <Th fontWeight="bold" fontSize="md" color="blue.700">Status</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>SSL</Td>
            <Td color={sslFound ? 'green.500' : 'red.500'} display="flex" alignItems="center">
              {sslFound ? (
                <>
                  <CheckCircleIcon color="green.500" mr={2} />
                  Found
                </>
              ) : (
                <>
                  <CloseIcon color="red.500" mr={2} />
                  Not Found
                </>
              )}
            </Td>
          </Tr>
          <Tr>
            <Td>Number of Certificates</Td>
            <Td color="blue.500">
              {data.certificates ? data.certificates.length : 0}
            </Td>
          </Tr>
          <Tr>
            <Td>Expired Certificates</Td>
            <Td color="red.500">
              {expiredCertificates.length}
            </Td>
          </Tr>
          <Tr>
            <Td>Non-Expired Certificates</Td>
            <Td color="green.500">
              {nonExpiredCertificates.length}
            </Td>
          </Tr>
        </Tbody>
      </Table>
      <Box textAlign="center" mt={6}>
      <Button
          colorScheme="blue"
          variant="solid"
          size="lg"
          onClick={handleViewMoreClick}
          _hover={{ bg: 'blue.600' }}
          transition="background 0.3s"
        >
          View More
        </Button>
      </Box>
    </Box>
  );
};

export default SSLInfoCard;
