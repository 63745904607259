import React from 'react';
import {
  Box,
  Button,
  Text,
  Divider,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useToast,
  Flex,
  Icon,
  Stack,
} from '@chakra-ui/react';
import { StarIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';

// Function to get user plan from session storage
const getUserPlan = () => {
  return sessionStorage.getItem('userPlan') || 'Free'; // Default to 'Free' if not found
};

const DomainQualityScoreCard = ({ data }) => {
  const userPlan = getUserPlan();
  const toast = useToast();
  const navigate = useNavigate();

  // Get all domains from the data object
  const domainKeys = Object.keys(data);

  // If no domains found, return early
  if (domainKeys.length === 0) {
    return (
      <Box p={6} borderWidth="1px" borderRadius="lg" backgroundColor="gray.50" mx="auto" mt={6}>
        <Text>No domain data available.</Text>
      </Box>
    );
  }

  const handleViewMoreClick = () => {
    if (userPlan === 'Free') {
      toast({
        title: 'Upgrade Required',
        description: 'You need to upgrade your plan to access this feature.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
    } else {
      navigate('/domainqualitydetails', {
        state: { data }, // Pass the full JSON data
      });
    }
  };

  return (
    <Box
      p={6}
      borderWidth="1px"
      borderRadius="lg"
      boxShadow="lg"
      backgroundColor="white"
      width={['95%', '80%', '70%']} // Responsive width
      mx="auto"
      mt={6}
      transition="0.3s"
      _hover={{ boxShadow: 'xl', transform: 'scale(1.02)' }} // Scale on hover
      filter={userPlan === 'Free' ? 'blur(4px)' : 'none'} // Apply blur if user plan is Free
      pointerEvents={userPlan === 'Free' ? 'none' : 'auto'} // Prevent interaction when blurred
    >
      <Flex alignItems="center" justifyContent="center" mb={4}>
        <StarIcon boxSize={8} color="blue.600" mr={2} />
        <Text fontWeight="bold" fontSize="2xl" color="blue.800">
          Domain Quality Scores
        </Text>
      </Flex>
      <Divider mb={4} />

      {/* Stack for responsive layout */}
      <Stack spacing={4} overflowX="auto">
        <Table variant="striped" colorScheme="teal" size="sm">
          <Thead>
            <Tr>
              <Th fontWeight="bold" fontSize="md" color="blue.700">Domain</Th>
              <Th fontWeight="bold" fontSize="md" color="blue.700">IP Address</Th>
              <Th fontWeight="bold" fontSize="md" color="blue.700">Normalized IP Score</Th>
            </Tr>
          </Thead>
          <Tbody>
            {domainKeys.map((domain) => {
              if (domain === 'SubDomains') return null; // Filter out the "SubDomains" key

              const domainData = data[domain]?.data || {};
              const ipScore = domainData?.ip_score || {};

              // Extracting IP Address and Normalized IP Score
              const ipAddress = ipScore?.ip_address || 'N/A';
              const normalizedIpScore = ipScore?.normalized_ip_score?.toString() || 'N/A';

              return (
                <Tr key={domain} _hover={{ bg: 'gray.100' }}>
                  <Td fontWeight="medium">{domain}</Td>
                  <Td>{ipAddress}</Td>
                  <Td color={normalizedIpScore !== 'N/A' && normalizedIpScore > 0 ? 'green.500' : 'red.500'}>
                    {normalizedIpScore}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Stack>

      <Box mt={6} textAlign="center">
        <Button
          colorScheme="blue"
          size="lg"
          onClick={handleViewMoreClick}
          _hover={{ bg: 'blue.600' }} // Darken button on hover
          transition="background 0.3s"
        >
          View More
        </Button>
      </Box>
    </Box>
  );
};

export default DomainQualityScoreCard;
