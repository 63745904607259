import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChakraProvider, Box, Input, Button, VStack, CircularProgress, Text, Container, Grid } from '@chakra-ui/react';

function Subdomains() {
  const [domain, setDomain] = useState(''); // State to hold the input value
  const [output, setOutput] = useState(null); // State to hold the fetched JSON output
  const [loading, setLoading] = useState(false); // State to manage loading status
  const [error, setError] = useState(''); // State to manage errors
  const navigate = useNavigate();

  // Load results from sessionStorage if available
  useEffect(() => {
    const checkAuthentication = () => {
      const accessToken = sessionStorage.getItem('accessToken'); // Access token should be stored in session storage
  
      if (!accessToken) {
        navigate('/signin'); // Redirect to sign-in page if no access token
        return;
      }
    };
  
    checkAuthentication();
  }, [navigate]);
  

  // Function to handle the API request with headers
  const handleSearch = async () => {
    setLoading(true); // Start the loading spinner
    setOutput(null); // Clear previous output
    setError(''); // Clear previous errors
    try {
      const response = await fetch(
        `https://irm5nmibi3tcg6xa4go5yz2i3i0rtbrk.lambda-url.ap-south-1.on.aws/api/domain/subdomains?domain=${domain}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'api-key': 'AQICAHi610eH2JqZkg2KBFmEoZDpxMsSdrGwwWa-T9g-i5VsgQHrW4jbEQirSb0W0T9RWPKjAAAAYzBhBgkqhkiG9w0BBwagVDBSAgEAME0GCSqGSIb3DQEHATAeBglghkgBZQMEAS4wEQQMX8NyNj4SvlwHQTA-AgEQgCArZn5rLd5grOj2fHyqEmlR8J4h2RsvlFxcmUkHJ4w8Qg',
            'access-token': 'ngopi',
          },
        }
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setOutput(data.sub_domains); // Set only the 'sub_domains' data
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Failed to fetch data');
    } finally {
      setLoading(false); // Stop the loading spinner
    }
  };

  const renderRows = () => {
    if (!output) return null;

    const { subdomains = [], wildcard_subdomains = [], found_emails = [], resolvedIps = [] } = output;


    return (
      <Grid templateColumns="repeat(2, 1fr)" gap={6} mt={4}>
        <Box p={4} borderWidth="1px" borderRadius="md" boxShadow="md" backgroundColor="white">
          <Text fontWeight="bold" mb={2}>Subdomains</Text>
          {subdomains.length > 0 ? (
            subdomains.map((item, index) => (
              <Text key={index}>{item}</Text>
            ))
          ) : (
            <Text>No Data Available</Text>
          )}
        </Box>

        <Box p={4} borderWidth="1px" borderRadius="md" boxShadow="md" backgroundColor="white">
          <Text fontWeight="bold" mb={2}>Wildcard Subdomains</Text>
          {wildcard_subdomains.length > 0 ? (
            wildcard_subdomains.map((item, index) => (
              <Text key={index}>{item}</Text>
            ))
          ) : (
            <Text>No Data Available</Text>
          )}
        </Box>

        <Box p={4} borderWidth="1px" borderRadius="md" boxShadow="md" backgroundColor="white">
          <Text fontWeight="bold" mb={2}>Found Emails</Text>
          {found_emails.length > 0 ? (
            found_emails.map((item, index) => (
              <Text key={index}>{item}</Text>
            ))
          ) : (
            <Text>No Data Available</Text>
          )}
        </Box>
        <Box p={4} borderWidth="1px" borderRadius="md" boxShadow="md" backgroundColor="white">
          <Text fontWeight="bold" mb={2}>resolvedIps</Text>
          {resolvedIps.length > 0 ? (
            resolvedIps.map((item, index) => (
              <Text key={index}>{item}</Text>
            ))
          ) : (
            <Text>No Data Available</Text>
          )}
        </Box>
      </Grid>
    );
  };

  return (
    <ChakraProvider>
      <Box p={4}>
        <VStack spacing={4}>
          <Input
            placeholder="Enter Domain"
            value={domain}
            onChange={(e) => setDomain(e.target.value)}
          />
          <Button colorScheme="teal" onClick={handleSearch} isDisabled={loading}>
            Search
          </Button>

          {loading && <CircularProgress isIndeterminate color="teal" />} {/* Show loading spinner */}

          {error && (
            <Text color="red.500" mt={4}>
              {error}
            </Text>
          )}
        </VStack>

        {output && (
          <Container maxW="container.xl" mt={4} p={4} borderWidth="1px" borderRadius="md" borderColor="gray.200">
            
            {renderRows()}
          </Container>
        )}
      </Box>
    </ChakraProvider>
  );
}

export default Subdomains;
