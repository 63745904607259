import React from 'react';
import {
    Box,
    Heading,
    Text,
    SimpleGrid,
    Container,
    Image,
    Flex,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { ChevronRightIcon, InfoOutlineIcon } from '@chakra-ui/icons'; // Importing icons from Chakra UI
import cyber from '../../assets/images/cybersecurity.jpg';
import key7 from '../../assets/images/key7.jpg';

import lock2 from '../../assets/images/lock2.jpg';


const MotionBox = motion(Box);

const KeyServices = () => {
    const animationVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
    };

    return (
        <div>
            <Box bg="white" color="black" p={[5, 0]}>
                <Container maxW="7xl" mt={[5, 5]}>
                    <MotionBox
                        initial="hidden"
                        animate="visible"
                        variants={animationVariants}
                        textAlign="center"
                        py={[10, 20]}
                        backgroundImage={`url(${cyber})`}
                        backgroundPosition="center"
                        backgroundRepeat="no-repeat"
                        backgroundSize="cover"
                        borderRadius="lg"
                        boxShadow="lg"
                        color="white"
                        mb={[10, 20]} // Add margin bottom to create space between sections
                    >
                        <Heading as="h1" size={["xl", "2xl"]} mb={5} color="gray.300">
                            Comprehensive Cybersecurity & Brand Protection Platform
                        </Heading>
                        <Text fontSize={["lg", "xl"]} color="gray.100">
                            Introduce your platform as an all-encompassing solution designed to protect your business from external threats, ensuring your brand, data, and digital assets are secure.
                        </Text>
                    </MotionBox>

                    <Box flex={1} textAlign={['center', 'center', 'left']}>
                        <MotionBox
                            
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.5 }}
                            variants={animationVariants}
                            mb={5}
                            bg="gray.50" // Light background for Key Services
                            p={5} // Padding for better readability
                            borderRadius="lg"
                            boxShadow="md"
                        >
                            <Flex
                                direction={['column', 'column', 'row']}
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Box flex="0 0 25%" mt={[10, 0]}>
                                    <Image
                                        src={lock2}
                                        alt="Cybersecurity illustration"
                                        width="100%"
                                        borderRadius="lg"
                                    />
                                </Box>
                                <Box flex="1" textAlign={['center', 'center', 'left']} pl={[0, 0, 10]}>
                                    <Heading as="h2" size="xl" mb={8}>
                                        Key Services
                                    </Heading>
                                    <SimpleGrid columns={[1, 1, 2]} spacing={10}>
                                        {[
                                            {
                                                icon: <ChevronRightIcon boxSize={5} color="teal.500" />,
                                                heading: 'DNS & Whois History Analysis',
                                                text: 'Track domain changes and detect suspicious contact information alterations.',
                                            },
                                            {
                                                icon: <ChevronRightIcon boxSize={5} color="teal.500" />,
                                                heading: 'Email Security',
                                                text: 'Ensure your domain\'s email configurations are secure to prevent spoofing and phishing attacks.',
                                            },
                                            {
                                                icon: <ChevronRightIcon boxSize={5} color="teal.500" />,
                                                heading: 'Brand Protection',
                                                text: 'Monitor and analyze domain squatting and homograph attacks to safeguard your brand\'s online presence.',
                                            },
                                            {
                                                icon: <ChevronRightIcon boxSize={5} color="teal.500" />,
                                                heading: 'Subdomain & SSL Certificate Enumeration',
                                                text: 'Identify and manage exposed subdomains and SSL certificates to mitigate security risks.',
                                            },
                                            {
                                                icon: <ChevronRightIcon boxSize={5} color="teal.500" />,
                                                heading: 'Domain, URL & IP Leaks Detection',
                                                text: 'Scan for leaks across known bad lists to detect if your assets are exposed.',
                                            },
                                            {
                                                icon: <ChevronRightIcon boxSize={5} color="teal.500" />,
                                                heading: 'Email Leaks',
                                                text: 'Monitor for compromised email addresses linked to your domain.',
                                            },
                                            {
                                                icon: <ChevronRightIcon boxSize={5} color="teal.500" />,
                                                heading: 'Deep & Dark Web Analysis',
                                                text: 'Search for your company’s data on hidden web forums, preventing exploitation from cybercriminals.',
                                            },
                                            {
                                                icon: <ChevronRightIcon boxSize={5} color="teal.500" />,
                                                heading: 'External Attack Surface Reporting',
                                                text: 'Receive a comprehensive report detailing all potential threats and exposures.',
                                            },
                                        ].map((item, index) => (
                                            <Box key={index} mb={5} display="flex" alignItems="flex-start">
                                                {item.icon}
                                                <Box as="span" ml={3}>
                                                    <Heading as="h3" size="md" mb={2}>
                                                        {item.heading}
                                                    </Heading>
                                                    <Text>
                                                        {item.text}
                                                    </Text>
                                                </Box>
                                            </Box>
                                        ))}
                                    </SimpleGrid>
                                </Box>
                            </Flex>
                        </MotionBox>

                        <MotionBox
                            initial="hidden"
                            animate="visible"
                            variants={animationVariants}
                            py={[10, 20]}
                            mt={[10, 20]}
                            borderRadius="lg"
                            boxShadow="lg"
                            width="100%"
                            maxW="100%" // Ensure it takes up full width
                            px={[5, 10]}
                            bg="gray.100" // Light gray background for Importance section
                        >
                            <Heading as="h2" size="xl" mb={8}>
                                        The Importance of Comprehensive Cybersecurity Monitoring
                                    </Heading>
                            <Flex
                                direction={['column', 'column', 'row']}
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Box flex="0 0 65%" textAlign={['center', 'center', 'left']} pr={[0, 0, 10]}>
                                    
                                    <SimpleGrid columns={[1, 1, 2]} spacing={10}>
                                        {[
                                            {
                                                icon: <InfoOutlineIcon boxSize={5} color="teal.500" />,
                                                heading: 'Increasing Cyber Threats',
                                                text: 'Cyber-attacks are growing in sophistication, with threats targeting email systems, brand identity, and data integrity.',
                                            },
                                            {
                                                icon: <InfoOutlineIcon boxSize={5} color="teal.500" />,
                                                heading: 'Brand & Reputation Risks',
                                                text: 'Domain squatting and phishing attacks can harm your reputation, leading to customer loss and legal implications.',
                                            },
                                            {
                                                icon: <InfoOutlineIcon boxSize={5} color="teal.500" />,
                                                heading: 'Security Misconfigurations',
                                                text: 'Misconfigured email DNS records can lead to unauthorized access, email spoofing, and data breaches.',
                                            },
                                            {
                                                icon: <InfoOutlineIcon boxSize={5} color="teal.500" />,
                                                heading: 'Exposure Risks',
                                                text: 'Subdomains and SSL misconfigurations can leave your infrastructure vulnerable to attacks.',
                                            },
                                            {
                                                icon: <InfoOutlineIcon boxSize={5} color="teal.500" />,
                                                heading: 'Data Leak Dangers',
                                                text: 'Compromised emails and credentials found on the web can lead to unauthorized access and data theft.',
                                            },
                                            {
                                                icon: <InfoOutlineIcon boxSize={5} color="teal.500" />,
                                                heading: 'Deep & Dark Web Threats',
                                                text: 'Criminals frequently use these hidden layers of the internet to trade stolen data and exploit company vulnerabilities.',
                                            },
                                        ].map((item, index) => (
                                            <Box key={index} mb={5} display="flex" alignItems="flex-start">
                                                {item.icon}
                                                <Box as="span" ml={3}>
                                                    <Heading as="h3" size="md" mb={2}>
                                                        {item.heading}
                                                    </Heading>
                                                    <Text>
                                                        {item.text}
                                                    </Text>
                                                </Box>
                                            </Box>
                                        ))}
                                    </SimpleGrid>
                                </Box>
                                <Box flex="0 0 25%" mt={[10, 0]}>
                                    <Image
                                        src={key7}
                                        alt="Cybersecurity illustration"
                                        width="100%"
                                        borderRadius="lg"
                                    />
                                </Box>

                            </Flex>
                        </MotionBox>
                    </Box>
                </Container>
            </Box>
        </div>
    );
};

export default KeyServices;
