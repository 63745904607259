// components/Navbar.js
import React from 'react';
import { Box, Flex, Grid, Image, Button, Stack, useDisclosure, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import { CloseIcon, HamburgerIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';
import { FaDesktop, FaCog, FaStar, FaInfoCircle, FaDollarSign, FaEnvelope,FaSignInAlt,FaTools ,FaNetworkWired} from 'react-icons/fa';
import logo from '../assets/images/logo.png';
import { useNavigate } from 'react-router-dom';



function Navbar() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  const headerStyle = {
    fontSize: '16px',
    textAlign: 'center',
    padding: '10px',
    fontFamily:"Arial, sans-serif", 
    fontWeight:"normal"
  };

  const handleNavigation = (sectionId) => {
    navigate('/', { state: { sectionId } });
  };

  return (
    <Box bg="black" color="white" px={4}>
      <Flex h={16} alignItems="center" justifyContent="space-between">
        <Link to="/">
          <Image
            height="60px"
            width="100px"
            src={logo}
            alt="Logo"
            cursor="pointer"
          />
        </Link>
        <Flex alignItems="center">
          <Stack direction="row" spacing={2} display={{ base: 'none', md: 'flex' }}>
            <Menu>
              <MenuButton as={Button} rightIcon={<ChevronDownIcon />} variant="link" color="white" style={headerStyle}>
                <FaDesktop style={{ marginRight: '10px', display: "inline" }} />
                Platform
              </MenuButton>
              <MenuList bg="black" color="white">
                <MenuItem onClick={() => handleNavigation('company')} bg="black" color="white">Domain Risk Analysis</MenuItem>
                <MenuItem onClick={() => handleNavigation('company')} bg="black" color="white">Deep and Dark Analysis</MenuItem>
                {/* <MenuItem onClick={() => handleNavigation('additionalServices')} bg="black" color="white">Additional Services</MenuItem> */}
                {/* <MenuItem onClick={() => handleNavigation('urlRiskDetection')} bg="black" color="white">URL Risk Detection</MenuItem> */}
                {/* <MenuItem onClick={() => handleNavigation('otherServices')} bg="black" color="white">Other Services</MenuItem>*/}
                 <MenuItem onClick={() => handleNavigation('additionalServices')} bg="black" color="white"> Code Analysis <i style={{ color: 'gold' }}>(coming soon)</i></MenuItem>
                <MenuItem onClick={() => handleNavigation('additionalServices')} bg="black" color="white"> Phishing Simulation <i style={{ color: 'gold' }}>(coming soon)</i></MenuItem>
                <MenuItem onClick={() => handleNavigation('additionalServices')} bg="black" color="white">Automated Penetration Testing <i style={{ color: 'gold' }}>(coming soon)</i></MenuItem>
              </MenuList>
            </Menu>
            <Menu>
              <MenuButton as={Button} rightIcon={<ChevronDownIcon />} variant="link" color="white" style={headerStyle}>
                <FaCog style={{ marginRight: '10px', display: "inline" }} />
                Services
              </MenuButton>
              <MenuList bg="black" color="white" >
              
                <MenuItem as={Link} to="/otherServices" bg="black" color="white">Manual Business Logic Testing</MenuItem>
                <MenuItem as={Link} to="/otherServices" bg="black" color="white">Cybersecurity Integration Services</MenuItem>
              </MenuList>
            </Menu>
            <Menu>
            <MenuButton as={Button} rightIcon={<ChevronDownIcon />} variant="link" color="white" style={headerStyle}>
  <FaTools style={{ marginRight: '10px', display: 'inline' }} />
  Tools
</MenuButton>
              <MenuList bg="black" color="white" >
                <MenuItem as={Link} to="/dkim" bg="black" color="white">DKIM</MenuItem>
                <MenuItem as={Link} to="/securityheaders" bg="black" color="white">Security Headers</MenuItem>
                <MenuItem as={Link} to="/sitetechnology" bg="black" color="white">Site Technology</MenuItem>
                <MenuItem as={Link} to="/url" bg="black" color="white">Url Analysis</MenuItem>
              </MenuList>
            </Menu>
            <Button as={Link} to="/apis" variant="link" color="white" style={headerStyle}>
            <FaNetworkWired style={{ marginRight: '10px', display: "flex" }} />API's</Button>

            <Button as={Link} to="/features" variant="link" color="white" style={headerStyle}>
              <FaStar style={{ marginRight: '10px', display: "flex" }} />Features</Button>

            <Button as={Link} to="/about" variant="link" color="white" style={headerStyle}>
              <FaInfoCircle style={{ marginRight: '10px', display: "flex" }} />About Us</Button>

            <Button as={Link} to="/pricing" variant="link" color="white" style={headerStyle}>
              <FaDollarSign style={{ marginRight: '10px', display: "flex" }} />Pricing</Button>

            <Button as={Link} to="/contact" variant="link" color="white" style={headerStyle}>
              <FaEnvelope style={{ marginRight: '10px', display: "flex" }} />Contact</Button>

          </Stack>
          <Button
            display={{ base: 'flex', md: 'none' }}
            onClick={isOpen ? onClose : onOpen}
            variant="outline"
            color="white"
          >
            {isOpen ? <CloseIcon /> : <HamburgerIcon />}
          </Button>
          <Button
  as={Link}
  to="/signin"
  variant="outline"
  color="red" // Set the text color to red
  ml={4} // Left margin
  textDecoration="underline" // Underline the text
  leftIcon={<FaSignInAlt />} // Add the icon to the left of the text
  fontFamily="Arial, sans-serif" // Set the font family
  fontWeight='bold' // Reduce the boldness
  _hover={{ bg: "gray.700" }} // Optional: Add a hover effect
 
>
  Sign In
</Button>

        </Flex>
      </Flex>
      {isOpen ? (

        <Box pb={4} display={{ md: 'none' }}>
          <Flex justify="center" align="center">
            <Grid
              templateColumns="repeat(auto-fit, minmax(150px, 1fr))"
              gap={6}
              w="100%"
              maxW="600px"
              alignItems="center"
            >
              {/* Platform Menu */}
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                  justifyContent="center"
                  variant="solid"
                  color="white"
                  bg="black.600"
                  border="2px solid"
                  borderColor="gray.700"
                  _hover={{ bg: 'gray.600' }}
                  display="flex"
                  alignItems="center"
                  p={4}
                  borderRadius="md"
                >
                  <Flex justifyContent="center" alignItems="center">
                    <FaDesktop style={{ marginRight: '8px' }} />
                    Platform
                  </Flex>
                </MenuButton>
                <MenuList bg="black" color="white">
                <MenuItem onClick={() => handleNavigation('company')} bg="black" color="white">Domain Risk Analysis</MenuItem>
                <MenuItem onClick={() => handleNavigation('company')} bg="black" color="white">Deep and Dark Analysis</MenuItem>
                {/* <MenuItem onClick={() => handleNavigation('additionalServices')} bg="black" color="white">Additional Services</MenuItem> */}
                {/* <MenuItem onClick={() => handleNavigation('urlRiskDetection')} bg="black" color="white">URL Risk Detection</MenuItem> */}
                {/* <MenuItem onClick={() => handleNavigation('otherServices')} bg="black" color="white">Other Services</MenuItem>*/}
                 <MenuItem onClick={() => handleNavigation('additionalServices')} bg="black" color="white"> Code Analysis <i style={{ color: 'gold' }}>(coming soon)</i></MenuItem>
                <MenuItem onClick={() => handleNavigation('additionalServices')} bg="black" color="white"> Phishing Simulation <i style={{ color: 'gold' }}>(coming soon)</i></MenuItem>
                <MenuItem onClick={() => handleNavigation('additionalServices')} bg="black" color="white">Automated Penetration Testing <i style={{ color: 'gold' }}>(coming soon)</i></MenuItem>
              </MenuList>
              </Menu>

              {/* Services Menu */}
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                  variant="solid"
                  color="white"
                  bg="black.600"
                  border="2px solid"
                  borderColor="gray.700"
                  _hover={{ bg: 'gray.600' }}
                  display="flex"
                  alignItems="center"
                  p={4}
                  borderRadius="md"
                >
                  <Flex justifyContent="center" alignItems="center">
                    <FaCog style={{ marginRight: '8px' }} />
                    Services
                  </Flex>
                </MenuButton>
                <MenuList bg="black" color="white" >
              
              <MenuItem as={Link} to="/otherServices" bg="black" color="white">Manual Business Logic Testing</MenuItem>
              <MenuItem as={Link} to="/otherServices" bg="black" color="white">Cybersecurity Integration Services</MenuItem>
            </MenuList>
              </Menu>

{/* Services Menu */}
<Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                  variant="solid"
                  color="white"
                  bg="black.600"
                  border="2px solid"
                  borderColor="gray.700"
                  _hover={{ bg: 'gray.600' }}
                  display="flex"
                  alignItems="center"
                  p={4}
                  borderRadius="md"
                >
                  <Flex justifyContent="center" alignItems="center">
                    <FaTools style={{ marginRight: '8px' }} />
                    Tools
                  </Flex>
                </MenuButton>
                <MenuList bg="black" color="white" >
                <MenuItem as={Link} to="/dkim" bg="black" color="white">DKIM</MenuItem>
                <MenuItem as={Link} to="/securityheaders" bg="black" color="white">Security Headers</MenuItem>
                <MenuItem as={Link} to="/sitetechnology" bg="black" color="white">Site Technology</MenuItem>
                <MenuItem as={Link} to="/url" bg="black" color="white">Url Analysis</MenuItem>
              </MenuList>
              </Menu>

  {/* Other Buttons */}
  <Button
                as={Link}
                to="/apis"
                variant="solid"
                color="white"
                bg="black.600"
                  border="2px solid"
                  borderColor="gray.700"
                  _hover={{ bg: 'gray.600' }}
                display="flex"
                justifyContent="center"
                alignItems="center"
                p={4}
                borderRadius="md"
              >
                <FaNetworkWired style={{ marginRight: '8px' }} /> API's
              </Button>
              {/* Other Buttons */}
              <Button
                as={Link}
                to="/features"
                variant="solid"
                color="white"
                bg="black.600"
                  border="2px solid"
                  borderColor="gray.700"
                  _hover={{ bg: 'gray.600' }}
                display="flex"
                justifyContent="center"
                alignItems="center"
                p={4}
                borderRadius="md"
              >
                <FaStar style={{ marginRight: '8px' }} /> Features
              </Button>

              <Button
                as={Link}
                to="/about"
                variant="solid"
                color="white"
                bg="black.600"
                  border="2px solid"
                  borderColor="gray.700"
                  _hover={{ bg: 'gray.600' }}
                display="flex"
                alignItems="center"
                p={4}
                borderRadius="md"
              >
                <FaInfoCircle style={{ marginRight: '8px' }} /> About Us
              </Button>

              <Button
                as={Link}
                to="/pricing"
                variant="solid"
                color="white"
                bg="black.600"
                  border="2px solid"
                  borderColor="gray.700"
                  _hover={{ bg: 'gray.600' }}
                display="flex"
                alignItems="center"
                p={4}
                borderRadius="md"
              >
                <FaDollarSign style={{ marginRight: '8px' }} /> Pricing
              </Button>

              <Button
                as={Link}
                to="/contact"
                variant="solid"
                color="white"
                bg="black.600"
                  border="2px solid"
                  borderColor="gray.700"
                  _hover={{ bg: 'gray.600' }}
                display="flex"
                alignItems="center"
                p={4}
                borderRadius="md"
              >
                <FaEnvelope style={{ marginRight: '8px' }} /> Contact
              </Button>
            </Grid>
          </Flex>
        </Box>

      ) : null}
    </Box>
  );
};

export default Navbar;
