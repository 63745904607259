
// Dkim.js
import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Input, 
  VStack,  
  Table, 
  Thead, 
  Tbody, 
  Tr, 
  Th, 
  Td, 
  Text, 
  Alert, 
  AlertIcon, 
  AlertTitle, 
  AlertDescription, 
  CloseButton, 
  Button, 
  Center,  
  useBreakpointValue, 
  Menu, 
  MenuButton, 
  MenuItem, 
  MenuList, 
  Stack, 
  HStack, 
  Icon, 
  Spinner,useToast 
} from '@chakra-ui/react';
import { FaBuilding, FaInfoCircle, FaQuestionCircle, FaLink, FaChevronDown ,FaTools} from 'react-icons/fa'; // Added ChevronDown for dropdown icon
import ImageOne from '../../assets/images/Global_network_generated.jpg';
import UserNavbar from '../auth/UserNavbar';
import MainpageFooter from '../MainpageFooter';
import MainComponent from '../Maincomponent';
import { useNavigate } from 'react-router-dom';
import {getToken } from '../auth/loginPage';
import KeyServices from '../subcomponent/KeyServices';

const Dkim = () => {
  const [domain, setDomain] = useState('');
  const [dkimData, setDkimData] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState(null);
  const [message, setMessage] = useState('');
  const toast = useToast();

  // Define responsive values for padding, font size, etc.
const paddingX = useBreakpointValue({ base: '4', md: '6', lg: '8' });
const buttonWidth = useBreakpointValue({ base: '80%', sm: '60%', md: '50%' });
const containerWidth = useBreakpointValue({ base: '90%', md: '70%', lg: '60%' });
const containerHeight = useBreakpointValue({ base: '70%', md: '60%', lg: '50%' });

// Load results from sessionStorage if available
useEffect(() => {
  const checkAuthentication = () => {
    const storedToken = getToken(); // Retrieve token

    setToken(storedToken);
    const accessToken = sessionStorage.getItem('accessToken'); // Access token should be stored in session storage

    if (!accessToken) {
      navigate('/signin'); // Redirect to sign-in page if no access token
      return;
    }
  };

  checkAuthentication();
}, [navigate]);



const fetchDkimData = async () => {
  setError(null);
  setIsLoading(true);
  if (!token) {
    setMessage('Token is missing. Please sign in first.');
    return;
  }
  try {
    const response = await fetch(
      `https://g1o62ob9e6.execute-api.ap-south-1.amazonaws.com/v1/api/domain_tools/dkim_scan?domain=${domain}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    
    
    setDkimData(result);
  } catch (err) {
    setError(err.message);
  } finally {
    setIsLoading(false);
  }
};



  const handleDomainChange = (e) => {
    setDomain(e.target.value);
  };
  

  return (
    <div>
         <div
    style={{
      position: 'fixed',                         // Fix the position of the navbar
      top: 0,                                    // Stick it to the top
      left: 0,                                   // Align it to the left edge
      width: '100%',                             // Full width for the navbar
      zIndex: 1000,                              // Ensure it's above other content
    }}
  >
   <UserNavbar /> 
  </div>
        
     <div>
        <Box
        height={{ base: '60vh', md: '60vh', lg: '60vh' }}
        backgroundImage={`url(${ImageOne})`} 
        backgroundSize="cover"
        backgroundPosition="center"
        position="relative"
      >
        <Center height="100%">
          <Box
            width={containerWidth}
            height={containerHeight}
            backgroundColor="rgba(0, 0, 0, 0.6)"
            color="white"
            borderRadius="20px"
            paddingX={paddingX}
            paddingY={{ base: '4', md: '6', lg: '8' }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            <Stack
              direction={{ base: 'column', md: 'row' }}
              spacing={{ base: '4', md: '6' }}
              align="center"
              justify="center"
              mb={6}
              wrap="wrap" // Ensure that items wrap if needed
              gap={{ base: '4', md: '6' }} // Responsive gap
            >
    
              {/* Normal Button */}
              <Button
                bg="transparent"
                color="white"
                borderRadius="md"
                _hover={{ bg: 'rgba(0, 0, 0, 0.3)' }}
                onClick={() => navigate('/companyinfo')}
              >
                <HStack spacing={2}>
                <Icon as={FaBuilding} />
                  <Text>Domain Risk Analysis</Text>
                </HStack>
              </Button>
        
    
              {/* Normal Button */}
        <Button
                bg="transparent"
                color="white"
                borderRadius="md"
                _hover={{ bg: 'rgba(0, 0, 0, 0.3)' }}
                onClick={() => navigate('/companysearch')}
              >
                <HStack spacing={2}>
                <Icon as={FaInfoCircle} />
                  <Text>Deep and Dark Search</Text>
                </HStack>
              </Button>

              
              {/* Dropdown Button 3 */}
              <Menu>
                <MenuButton
                  as={Button}
                  bg="transparent"
                  color="white"
                  borderRadius="md"
                  _hover={{ bg: 'rgba(0, 0, 0, 0.3)' }}
                  rightIcon={<FaChevronDown />}
                >
                  <HStack spacing={2}>
                    <Icon as={FaTools} />
                    <Text>DKIM</Text>
                  </HStack>
                </MenuButton>
                <MenuList bg="black" color="white">
                
                  <MenuItem
                    bg="black"
                    _hover={{ bg: 'rgba(255, 255, 255, 0.2)' }}
                    onClick={() => navigate('/securityheaders')}
                  >
                    Security Headers
                  </MenuItem>
                  <MenuItem
                    bg="black"
                    _hover={{ bg: 'rgba(255, 255, 255, 0.2)' }}
                    onClick={() => navigate('/sitetechnology')}
                  >
                    Site Technology
                  </MenuItem>
                  <MenuItem
                    bg="black"
                    _hover={{ bg: 'rgba(255, 255, 255, 0.2)' }}
                    onClick={() => navigate('/url')}
                  >
                    URL Analysis
                  </MenuItem>
                </MenuList>
              </Menu>
    
              {/* Normal Button */}
              {/* <Button
                bg="transparent"
                color="white"
                borderRadius="md"
                _hover={{ bg: 'rgba(0, 0, 0, 0.3)' }}
                onClick={() => navigate('/url')}
              >
                <HStack spacing={2}>
                  <Icon as={FaLink} />
                  <Text>URL Analysis</Text>
                </HStack>
              </Button> */}
            </Stack>
    
            {/* Input and Search Button */}
            <VStack spacing={4} width={containerWidth} align="center">
              <Input
                placeholder="Company Name: 'example.com'"
                value={domain}
            onChange={handleDomainChange}
                size="md"
                color="white"
                backgroundColor="rgba(255, 255, 255, 0.2)"
                borderRadius="md"
                _placeholder={{ color: 'white' }}
                border="1px solid white"
              />
      <Button
      onClick={fetchDkimData}
        colorScheme="teal"
        width={buttonWidth}
        borderRadius="20px"
        backgroundColor="rgba(0, 0, 0, 0.5)"
        _hover={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}
      >
        Search
      </Button>
          {/* <MainComponent domain={domain} onSuccess={fetchDkimData} /> */}
                </VStack>
              </Box>
            </Center>
          </Box>
        <br></br>
        <center>
                    {isLoading && <Spinner size="xl" />}
            
                    </center>

     {/* <Box p={5}>
       <Heading mb={6}>DKIM Component</Heading>
       <VStack spacing={6} align="stretch">
         <FormControl id="domain">
           <FormLabel>Domain</FormLabel>
           <Input
            type="text"
            value={domain}
            onChange={handleDomainChange}
            placeholder="Enter domain"
          />
        </FormControl>


        {/* Local API Component: Handles the localhost API call */}
        {/* <MainComponent domain={domain} onSuccess={fetchDkimData} /> */}
        {/* plan Check
        plan check */} 


       {/* DKIM Data Output */}

       <br />
      {/* Render KeyServices only if apiResults is empty */}
      {Object.keys(dkimData).length === 0 && <KeyServices />}
        
      <br />

{dkimData ? (
  <Box mt={8} p={4} borderWidth="1px" borderRadius="lg" boxShadow="lg" bg="gray.50">
    <Text fontSize="2xl" fontWeight="bold" color="teal.600" textAlign="center" mb={6}>
      DKIM Data
    </Text>
    {/* Check if dkimData is an array and has content or it's an object with keys */}
    {(Array.isArray(dkimData) && dkimData.length > 0) || (!Array.isArray(dkimData) && Object.keys(dkimData).length > 0) ? (
      <Table variant="striped" colorScheme="teal" size="md" border="1px solid" borderColor="gray.300" borderRadius="md" overflowX="auto">
        <Thead bg="teal.500">
          <Tr>
            <Th fontWeight="bold" color="white" borderRight="2px solid white" textAlign="center">
              Selector
            </Th>
            <Th fontWeight="bold" color="white" textAlign="center">
              Public Key
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {Array.isArray(dkimData) ? (
            // Filter out duplicates by checking if the selector and public_key combination has appeared before
            dkimData
              .filter(
                (item, index, self) =>
                  index === self.findIndex((t) => t.selector === item.selector && t.public_key === item.public_key)
              )
              .map((item, index) => (
                <Tr key={index}>
                  <Td borderRight="2px solid" borderColor="gray.300" padding="12px" textAlign="center" color="blue.600" fontWeight="bold">
                    {item.selector}
                  </Td>
                  <Td padding="12px" style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }} textAlign="center">
                    {item.public_key}
                  </Td>
                </Tr>
              ))
          ) : (
            // Handle dkimData as an object
            Object.keys(dkimData)
              .filter((key, index, self) => index === self.indexOf(key)) // Filter out duplicate keys
              .map((key, index) => (
                <Tr key={index}>
                  <Td borderRight="2px solid" borderColor="gray.300" padding="12px" textAlign="center" color="blue.600" fontWeight="bold">
                    {key}
                  </Td>
                  <Td padding="12px" style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }} textAlign="center">
                    {dkimData[key]}
                  </Td>
                </Tr>
              ))
          )}
        </Tbody>
      </Table>
    ) : (
      // Show "No Result Found" if there is no content
      <Text fontSize="lg" color="red.500" textAlign="center" mt={4}>
        No Result Found
      </Text>
    )}
  </Box>
) : null}

{/* Error handling for DKIM API */}
{error && (
  <Alert status="error" mt={4} borderRadius="lg">
    <AlertIcon />
    <AlertTitle mr={2}>Error:</AlertTitle>
    <AlertDescription>{error}</AlertDescription>
    <CloseButton position="absolute" right="8px" top="8px" />
  </Alert>
)}

        <br></br>
         <MainpageFooter/>
            </div> 

        </div>
  );
};

export default Dkim;
