import { Box, Button, Text, Divider, Table, Thead, Tbody, Tr, Th, Td, useToast } from '@chakra-ui/react';
import { InfoOutlineIcon, CheckCircleIcon, WarningIcon } from '@chakra-ui/icons'; // Importing icons
import { useNavigate } from 'react-router-dom';

// Function to get user plan from session storage
const getUserPlan = () => {
  return sessionStorage.getItem('userPlan') || 'Free'; // Default to 'Free' if not found
};

const EmailSecurityCard = ({ data }) => {
  const navigate = useNavigate();
  const toast = useToast();
  const userPlan = getUserPlan(); // Retrieve user plan here

  // Check if data is null or empty
  if (!data || !data.scanResult || Object.keys(data.scanResult).length === 0) {
    return <Text>Not Found</Text>; // Display "Not Found" message if data is null or empty
  }

  const { scanResult, overall_score: overallScore } = data;
  const { dkim, bimi, dmarc, mx, spf } = scanResult;

  // Check if fields are found (adjust for dkim to check if it's an object with keys)
  const isDkimFound = dkim && Object.keys(dkim).length > 0; // Check if dkim is not an empty object
  const isBimiFound = bimi && bimi.length > 0; // Check if bimi has values
  const isDmarcFound = dmarc && dmarc.length > 0; // Check if dmarc has values
  const isMxFound = mx && mx.length > 0; // Check if mx has values
  const isSpfFound = spf && spf.length > 0; // Check if spf has values

  let scoreText = 'Bad';
  let scoreColor = 'red.500';

  if (overallScore >= 85) {
    scoreText = 'Good';
    scoreColor = 'green.500';
  } else if (overallScore >= 65) {
    scoreText = 'Average';
    scoreColor = 'orange.500';
  }

  const handleViewMoreClick = () => {
    if (userPlan === 'Free') {
      toast({
        title: 'Upgrade Required',
        description: 'You need to upgrade your plan to access this feature.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
    } else {
      navigate('/email-security', { state: { data } });
    }
  };

  return (
    <Box
      p={6}
      borderWidth="1px"
      borderRadius="lg"
      boxShadow="lg"
      backgroundColor="gray.50"
      width={['95%', '80%', '70%']}
      mx="auto"
      mt={6}
      _hover={{ boxShadow: 'xl', transform: 'scale(1.02)', transition: '0.3s ease-in-out' }}
    >
      <Box display="flex" justifyContent="center" alignItems="center" mb={4}>
        <InfoOutlineIcon boxSize={8} color="blue.600" mr={2} />
        <Text fontWeight="bold" fontSize="2xl" textAlign="center" color="blue.800">
          Email Security Overview
        </Text>
      </Box>
      <Divider mb={4} />
      <Table variant="striped" colorScheme="teal">
        <Thead>
          <Tr>
            <Th fontWeight="bold" fontSize="md" color="blue.700">Category</Th>
            <Th fontWeight="bold" fontSize="md" color="blue.700">Status</Th>
          </Tr>
        </Thead>
        <Tbody>
          {[ 
            { category: 'DKIM', found: isDkimFound },
            { category: 'BIMI', found: isBimiFound },
            { category: 'DMARC', found: isDmarcFound },
            { category: 'MX', found: isMxFound },
            { category: 'SPF', found: isSpfFound }
          ].map(({ category, found }) => (
            <Tr key={category}>
              <Td>{category}</Td>
              <Td color={found ? 'green.500' : 'red.500'}>
                {found ? (
                  <>
                    <CheckCircleIcon color="green.500" boxSize={5} mr={2} />
                    Found
                  </>
                ) : (
                  <>
                    <WarningIcon color="red.500" boxSize={5} mr={2} />
                    Not Found
                  </>
                )}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Box textAlign="center" mt={6}>
        <Text fontWeight="bold" fontSize="lg" color="blue.800" mb={2}>
          Overall Security Score: <Text as="span" color={scoreColor}>{scoreText}</Text>
        </Text>
        <Button
          colorScheme="blue"
          variant="solid"
          size="lg"
          onClick={handleViewMoreClick}
          _hover={{ bg: 'blue.600' }}
          transition="background 0.3s"
        >
          View More
        </Button>
      </Box>
    </Box>
  );
};

export default EmailSecurityCard;
