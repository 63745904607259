import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Text,
  Divider,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Heading,
  Icon,
  Stack,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Flex,
  useToast
} from '@chakra-ui/react';
import { EmailIcon, DownloadIcon } from '@chakra-ui/icons';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import UserNavbar from '../../auth/UserNavbar';
import MainpageFooter from '../../MainpageFooter';

const EmailSecurityDetails = () => {
  const location = useLocation();
  const data = location.state?.data;

  const { bimi, dkim, dmarc, mx, spf } = data?.scanResult || {};
  
  const toast = useToast();

  const renderTable = (entries, headers) => (
    <Table variant="striped" colorScheme="teal" size="sm">
      <Thead>
        <Tr>
          {headers.map((header) => (
            <Th key={header}>{header}</Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {entries.map((entry, index) => (
          <Tr key={index}>
            {Object.values(entry).map((value, idx) => (
              <Td
                key={idx}
                whiteSpace="normal"
                wordBreak="break-word"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {value}
              </Td>
            ))}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );

  

  return (
    <div>
      <UserNavbar />
      <Box maxW="7xl" mx="auto" p={6}>
        <Heading as="h1" size="lg" mb={6} textAlign="center">
          Email Security Details
        </Heading>
        <Divider mb={6} />
        
       

        <Box id="pdf-content" p={4} bg="white" boxShadow="lg" borderRadius="md">
          {bimi && (
            <Card mb={6}>
              <CardHeader>
                <Flex align="center">
                  <Icon as={EmailIcon} w={5} h={5} color="blue.500" />
                  <Text fontSize="lg" fontWeight="bold" ml={2}>BIMI</Text>
                </Flex>
              </CardHeader>
              <CardBody>
                {Array.isArray(bimi) ? (
                  renderTable(bimi.map((item, index) => ({ Property: index, Value: item })), ['Property', 'Value'])
                ) : (
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th>Property</Th>
                        <Th>Value</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {Object.entries(bimi).map(([key, value]) => (
                        <Tr key={key}>
                          <Td>{key}</Td>
                          <Td>{value}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                )}
              </CardBody>
            </Card>
          )}

          {dkim && (
            <Card mb={6}>
              <CardHeader>
                <Flex align="center">
                  <Icon as={EmailIcon} w={5} h={5} color="blue.500" />
                  <Text fontSize="lg" fontWeight="bold" ml={2}>DKIM</Text>
                </Flex>
              </CardHeader>
              <CardBody>
                {Array.isArray(dkim) ? (
                  renderTable(dkim, ['Domain', 'Selector', 'Public Key', 'Last Seen'])
                ) : (
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th>Property</Th>
                        <Th>Value</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {Object.entries(dkim).map(([key, value]) => (
                        <Tr key={key}>
                          <Td>{key}</Td>
                          <Td>{value}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                )}
              </CardBody>
            </Card>
          )}

          {dmarc && (
            <Card mb={6}>
              <CardHeader>
                <Flex align="center">
                  <Icon as={EmailIcon} w={5} h={5} color="blue.500" />
                  <Text fontSize="lg" fontWeight="bold" ml={2}>DMARC</Text>
                </Flex>
              </CardHeader>
              <CardBody>
                <Text>{Array.isArray(dmarc) ? dmarc.join(', ') : Object.entries(dmarc).map(([key, value]) => `${key}: ${value}`).join(', ')}</Text>
              </CardBody>
            </Card>
          )}

          {mx && (
            <Card mb={6}>
              <CardHeader>
                <Flex align="center">
                  <Icon as={EmailIcon} w={5} h={5} color="blue.500" />
                  <Text fontSize="lg" fontWeight="bold" ml={2}>MX</Text>
                </Flex>
              </CardHeader>
              <CardBody>
                <Text>{Array.isArray(mx) ? mx.join(', ') : Object.entries(mx).map(([key, value]) => `${key}: ${value}`).join(', ')}</Text>
              </CardBody>
            </Card>
          )}

          {spf && (
            <Card mb={6}>
              <CardHeader>
                <Flex align="center">
                  <Icon as={EmailIcon} w={5} h={5} color="blue.500" />
                  <Text fontSize="lg" fontWeight="bold" ml={2}>SPF</Text>
                </Flex>
              </CardHeader>
              <CardBody>
                {Array.isArray(spf) ? (
                  renderTable(spf.map((item, index) => ({ Property: index, Value: item })), ['Property', 'Value'])
                ) : (
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th>Property</Th>
                        <Th>Value</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {Object.entries(spf).map(([key, value]) => (
                        <Tr key={key}>
                          <Td>{key}</Td>
                          <Td>{value}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                )}
              </CardBody>
            </Card>
          )}
        </Box>
      </Box>
      <MainpageFooter />
    </div>
  );
};

export default EmailSecurityDetails;
