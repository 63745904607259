import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { CognitoIdentityProviderClient, GetUserCommand } from "@aws-sdk/client-cognito-identity-provider";
import UserNavbar from './UserNavbar';
import MainpageContainer from '../MainpageContainer';
import MainpageSubContainer from '../MainpageSubContainer';
import MainpageFooter from '../MainpageFooter';

// Initialize the CognitoIdentityProviderClient with the correct region
const cognitoClient = new CognitoIdentityProviderClient({
  region: process.env.REACT_APP_AWS_REGION, // Load AWS region from environment variables
});

function UserHomePage() {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); // Loading state for user fetching

  useEffect(() => {
    const fetchUserAttributes = async () => {
      try {
        const accessToken = sessionStorage.getItem('accessToken'); // Access token should be stored in session storage

        if (!accessToken) {
          navigate('/signin'); // Redirect to sign-in page if no access token
          return;
        }

        const params = {
          AccessToken: accessToken,
        };

        const command = new GetUserCommand(params);

        // Fetch user attributes from Cognito
        const data = await cognitoClient.send(command);
        const attributes = data.UserAttributes.reduce((acc, attr) => {
          acc[attr.Name] = attr.Value;
          return acc;
        }, {});

        const email = attributes.email;
        const plan = attributes['custom:plan'];

        if (email) {
          sessionStorage.setItem('userEmail', email); // Store user email in session storage
        }
        if (plan) {
          sessionStorage.setItem('userPlan', plan); // Store user plan in session storage
        }

        // Redirect to sign-in page if not signed in
        const storedEmail = sessionStorage.getItem('userEmail');
        if (!storedEmail) {
          navigate('/signin'); // Redirect to sign-in page if user is not signed in 
        }
      } catch (error) {
        console.error('Error fetching user attributes:', error);
        navigate('/signin'); // Redirect to sign-in page if there's an error
      } finally {
        setLoading(false); // Stop loading after fetch
      }
    };

    fetchUserAttributes();
  }, [location, navigate]);

  if (loading) {
    return <div>Loading...</div>; // Loading state indicator
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <div
    style={{
      position: 'fixed',                         // Fix the position of the navbar
      top: 0,                                    // Stick it to the top
      left: 0,                                   // Align it to the left edge
      width: '100%',                             // Full width for the navbar
      zIndex: 1000,                              // Ensure it's above other content
    }}
  >
   <UserNavbar /> 
  </div>
      <div style={{ flex: 1 }}>
        <Outlet />
        <MainpageContainer />
        <MainpageSubContainer />
      </div>
      <MainpageFooter />
    </div>
  );
}

export default UserHomePage;
