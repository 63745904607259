import React from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  List,
  ListItem,
  Link,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import MainpageFooter from './MainpageFooter';

export default function PrivacyPolicy() {
  return (
    <div>
    <Container maxW="container.md" py={10}>
      <Box bg="gray.50" p={6} borderRadius="md" boxShadow="lg">
        <Heading as="h1" size="xl" textAlign="center" mb={6}>
          Privacy Policy for Pentity
        </Heading>
        <Text textAlign="center" fontStyle="italic">
          <strong>Last Updated: October 15, 2024</strong>
        </Text>

        <VStack spacing={4} align="start" mt={8}>
          <Heading as="h2" size="lg">
            1. Introduction
          </Heading>
          <Text>
            This Privacy Policy ("Policy") outlines how Pentity ("we", "us", "our") collects, uses, and protects the personal information provided by users ("you", "your", "User") while using our platform for cybersecurity risk analysis, deep web, and dark web analysis (the "Services"). This Policy applies to your use of Pentity's website, mobile applications, and related services but does not extend to third-party websites that may be linked to our platform.
          </Text>
          <Text>
            By accessing or using our Services, you acknowledge that you have read, understood, and agree to the terms of this Privacy Policy. If you do not agree to this Policy, please refrain from using our Services.
          </Text>

          <Heading as="h2" size="lg">
            2. Information We Collect and How We Use It
          </Heading>
          <Heading as="h3" size="md">
            2.1 Personal Information
          </Heading>
          <Text>
            We collect personal information that identifies or could be used to identify you, such as:
          </Text>
          <UnorderedList spacing={2}>
            <ListItem>Your name, email address, and any other information related to cybersecurity risk analysis, web activity, or dark web searches.</ListItem>
            <ListItem>Technical data like your IP address, browser type, and session details.</ListItem>
          </UnorderedList>
          <Heading as="h3" size="md">
            2.2 How We Use Your Information
          </Heading>
          <Text>The information is collected for the following purposes:</Text>
          <UnorderedList spacing={2}>
            <ListItem><strong>To Provide and Improve Services:</strong> To personalize your experience, provide relevant insights, and enhance security.</ListItem>
            <ListItem><strong>Communication:</strong> To send notifications, updates, and alerts regarding your account or cybersecurity risks related to your domain.</ListItem>
            <ListItem><strong>Fraud and Risk Prevention:</strong> To monitor suspicious activity and mitigate risks associated with your digital footprint on the web, including the dark web.</ListItem>
            <ListItem><strong>Legal and Regulatory Compliance:</strong> To comply with legal requirements, respond to legal processes, or protect our rights and users’ security.</ListItem>
          </UnorderedList>

          <Heading as="h2" size="lg">
            3. Personal Information You Provide
          </Heading>
          <Text>
            When you register or interact with Pentity, we may collect and store information, including but not limited to:
          </Text>
          <UnorderedList spacing={2}>
            <ListItem><strong>Account Details:</strong> Your name, email address, phone number, and other relevant information provided during sign-up.</ListItem>
            <ListItem><strong>Cybersecurity Analysis Data:</strong> Information related to your cybersecurity profile, web searches, risk analysis, and monitoring reports on deep and dark web activities.</ListItem>
            <ListItem><strong>Communication Preferences:</strong> Your preferences for receiving emails or alerts related to your account or security updates.</ListItem>
          </UnorderedList>

          <Heading as="h2" size="lg">
            4. Third-Party Account Information
          </Heading>
          <Text>
            You may choose to link your account with third-party services (e.g., social media, security platforms). Information transferred from these third parties will be treated in accordance with this Policy. You have the right to disconnect such accounts at any time.
          </Text>

          <Heading as="h2" size="lg">
            5. Cookies and Tracking Technologies
          </Heading>
          <Text>
            We use cookies and similar technologies to enhance your experience on our platform. Cookies help us understand user behavior, secure your account, and personalize content. You can control cookie settings through your browser, but please note that disabling cookies may limit certain functionalities.
          </Text>

          <Heading as="h2" size="lg">
            6. Sharing of Information
          </Heading>
          <Text>
            Pentity does not sell, rent, or share your personal information with third parties, except in the following circumstances:
          </Text>
          <UnorderedList spacing={2}>
            <ListItem><strong>Service Providers:</strong> We may share information with trusted service providers who assist in delivering our services, ensuring security, and improving user experience.</ListItem>
            <ListItem><strong>Legal Obligations:</strong> We may disclose personal information when required by law or in good faith to comply with legal processes, investigations, or court orders.</ListItem>
            <ListItem><strong>Affiliates and Business Transfers:</strong> In case of a merger, acquisition, or sale of assets, personal data may be transferred to the acquiring entity.</ListItem>
          </UnorderedList>

          <Heading as="h2" size="lg">
            7. Data Security
          </Heading>
          <Text>
            We use industry-standard security practices to protect your personal information from unauthorized access, disclosure, or alteration. Despite our efforts, no data transmission over the internet can be guaranteed as 100% secure, and we cannot warrant the absolute security of any information transmitted to our Services.
          </Text>

          <Heading as="h2" size="lg">
            8. User Rights
          </Heading>
          <Text>You have the right to:</Text>
          <UnorderedList spacing={2}>
            <ListItem><strong>Access Your Information:</strong> Review the personal data we hold about you.</ListItem>
            <ListItem><strong>Correct and Update Information:</strong> Request corrections to any inaccurate or incomplete information.</ListItem>
            <ListItem><strong>Erase Personal Data:</strong> Request the deletion of your data in compliance with legal regulations.</ListItem>
          </UnorderedList>
          <Text>
            To exercise your rights, contact us at <Link href="mailto:info@pentity.com" color="teal.500">info@pentity.com</Link>.
          </Text>

          <Heading as="h2" size="lg">
            9. Modifications to Privacy Policy
          </Heading>
          <Text>
            This Privacy Policy may be updated periodically to reflect changes in our practices or services. We will notify users of any significant changes by posting the updated Policy on our website. Continued use of the platform after changes to the Policy constitutes acceptance of the new terms.
          </Text>

          <Heading as="h2" size="lg">
            10. Contact Us
          </Heading>
          <Text>
            For any questions or concerns regarding this Privacy Policy, please contact us at:
          </Text>
          <Text>
            <strong>Pentity Privacy Team</strong>
          </Text>
          <Text>
            Email: <Link href="mailto:info@pentity.com" color="teal.500">info@pentity.com</Link>
          </Text>
          <Text>
            <strong>Address:</strong>
          </Text>
          <Text>
4A, Nehru Street, Gowrivakkam
<Text>
Chennai, India, 600073
          </Text>
          </Text>
        
        </VStack>
      </Box>
    </Container>
    <MainpageFooter />
    </div>
  );
}
