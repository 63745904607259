import React, { useState,useEffect } from 'react';
import {
  Box,
  Textarea,
  Button,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,useToast 
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import {getToken } from './auth/loginPage';

const MainComponent = ({ domain, onSuccess }) => {
  const [searchResponse, setSearchResponse] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure(); // Hook to control the modal state
  const navigate = useNavigate();
  const [token, setToken] = useState(null); 
  const [message, setMessage] = useState('');
  const toast = useToast();


  useEffect(() => {
    const checkAuthentication = () => {
      const storedToken = getToken(); // Retrieve token
  
      setToken(storedToken);
      const accessToken = sessionStorage.getItem('accessToken'); // Access token should be stored in session storage
  
      if (!accessToken) {
        navigate('/signin'); // Redirect to sign-in page if no access token
        return;
      }
    };
  
    checkAuthentication();
  }, [navigate]);

  
  const handleLocalAPICall = async () => {
    setLoading(true);
    setError(null);
    if (!token) {
      setMessage('Token is missing. Please sign in first.');
      return;
    }

    // Set up request body for local API call
    const requestBody = {
      query_request: {
        domain,
        query: 'TCS', // Provide a value for query as needed
      },
      user_info: {
        username: sessionStorage.getItem('userEmail'),
        plan: sessionStorage.getItem('userPlan') ? sessionStorage.getItem('userPlan').toLowerCase() : '',
      },
    };

    try {
      const res = await fetch('https://en5q5sk4yf.execute-api.ap-south-1.amazonaws.com/v1/search', {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (!res.ok) {
        const errorData = await res.json();

        // Check for specific error and show modal if limit is exceeded
        if (errorData.detail === 'Domain or query limit exceeded for this plan') {
          onOpen(); // Open the modal
        } else {
          throw new Error(errorData.detail || 'Failed to fetch data. Please try again later.');
        }
      } else {
        const data = await res.json();
        setSearchResponse(data);
        onSuccess(); // Call the DKIM API when the local call is successful
      }
    } catch (error) {
      setError(error.message || 'Failed to fetch data. Please try again later.');
      setSearchResponse(null);
    } finally {
      setLoading(false);
    }
  };

  // Function to handle the update plan button click
  const handleUpdatePlan = () => {
    navigate('/pricing');
    onClose(); // Close the modal after clicking the update button
  };

  return (
    <Box>
      <Button
        colorScheme="teal"
        borderRadius="20px"
        backgroundColor="rgba(0, 0, 0, 0.5)"
        _hover={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}
        onClick={handleLocalAPICall}
        isLoading={loading} // Added loading state to button
      >
        Search
      </Button>

      {error && (
        <Alert status="error" mt={4}>
          <AlertIcon />
          <AlertTitle mr={2}>Error:</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
          <CloseButton onClick={() => setError(null)} position="absolute" right="8px" top="8px" />
        </Alert>
      )}

      {/* {searchResponse && (
        <Box mt={4}>
          <Text fontWeight="bold">Local API Response:</Text>
          <Textarea value={JSON.stringify(searchResponse, null, 2)} readOnly height="200px" />
        </Box>
      )} */}

      {/* Modal for Limit Exceeded */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Limit Exceeded</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Your Limit is Over. Update the plan to see more.</Text>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="blue" ml={3} onClick={handleUpdatePlan}>
              Update Plan
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default MainComponent;
