import React from 'react';
import {
    Box,
    Heading,
    Text,
    SimpleGrid,
    Button,
    Container,
    Flex,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';

const MotionBox = motion(Box);

const SecureYourBrand = () => {
    const animationVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
    };

    const navigate = useNavigate();
    navigate('/contact')

    const getPricing = () => {
        navigate('/pricing'); 
        
    };

    const getContact = () => {
        navigate('/contact'); 
        
    };

    return (
        <Box bg="gray.50" color="black" p={[5, 10]}>
            <Container maxW="7xl" mt={[5, 10]}>
                {/* Key Benefits Section */}
                <MotionBox
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    variants={animationVariants}
                    py={[10, 20]}
                    borderRadius="lg"
                    boxShadow="lg"
                    width="100%"
                    maxW="100%"
                    px={[5, 10]}
                    bg="white"
                    mb={10}
                >
                    <Heading as="h2" size="xl" mb={8} textAlign="center">
                        Why Our Platform is the Best Choice
                    </Heading>
                    <SimpleGrid columns={[1, 1, 2]} spacing={10}>
                        {[
                            {
                                icon: <ChevronRightIcon boxSize={5} color="teal.500" />,
                                heading: 'Comprehensive Coverage',
                                text: 'Our platform combines all critical external threat monitoring capabilities into a single, easy-to-use solution.',
                            },
                            {
                                icon: <ChevronRightIcon boxSize={5} color="teal.500" />,
                                heading: 'Real-Time Monitoring',
                                text: 'Constant scanning and monitoring provide up-to-date threat intelligence, keeping you ahead of potential attackers.',
                            },
                            {
                                icon: <ChevronRightIcon boxSize={5} color="teal.500" />,
                                heading: 'Expert Analysis',
                                text: 'Reports are not just data; they are actionable insights crafted by cybersecurity experts, enabling you to make informed decisions.',
                            },
                            {
                                icon: <ChevronRightIcon boxSize={5} color="teal.500" />,
                                heading: 'User-Friendly Reports',
                                text: 'We deliver concise, easy-to-understand reports with clear action items, so you know exactly what steps to take next.',
                            },
                            {
                                icon: <ChevronRightIcon boxSize={5} color="teal.500" />,
                                heading: 'Scalable Solutions',
                                text: 'Whether you’re a small business or a large enterprise, our platform scales to meet your needs.',
                            },
                        ].map((item, index) => (
                            <Box key={index} mb={5} display="flex" alignItems="flex-start">
                                {item.icon}
                                <Box as="span" ml={3}>
                                    <Heading as="h3" size="md" mb={2}>
                                        {item.heading}
                                    </Heading>
                                    <Text>
                                        {item.text}
                                    </Text>
                                </Box>
                            </Box>
                        ))}
                    </SimpleGrid>
                </MotionBox>

                {/* Call to Action Section */}
                <MotionBox
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    variants={animationVariants}
                    py={[10, 20]}
                    mt={[10, 20]}
                    borderRadius="lg"
                    boxShadow="lg"
                    width="100%"
                    maxW="100%"
                    px={[5, 10]}
                    bg="teal.50"
                    textAlign="center"
                >
                    <Heading as="h2" size="xl" mb={8}>
                        Secure Your Brand and Data Now
                    </Heading>
                    <Text fontSize={["lg", "xl"]} mb={8}>
                        Get Started Today: Schedule a demo to see how our platform can protect your business.
                        <br />
                        Contact Us: Reach out for more information and let us help you safeguard your brand against today’s evolving cyber threats.
                    </Text>
                    <Flex justifyContent="center" gap={4}>
                        <Button
                            colorScheme="teal"
                            size="lg"
                            variant="solid"
                            mb={4}
                            onClick={getPricing}
                        >
                            Schedule a Demo
                        </Button>
                        <Button
                            colorScheme="blue"
                            size="lg"
                            variant="outline"
                            onClick={getContact}
                        >
                            Contact Us
                        </Button>
                    </Flex>
                </MotionBox>
            </Container>
        </Box>
    );
};

export default SecureYourBrand;
