import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Input, 
  VStack,  
  Table, 
  Thead, 
  Tbody, 
  Tr, 
  Th, 
  Td, 
  Text, 
  Alert, 
  AlertIcon, 
  AlertTitle, 
  AlertDescription, 
  CloseButton, 
  Button, 
  Center,  
  useBreakpointValue, 
  Menu, 
  MenuButton, 
  MenuItem, 
  MenuList, 
  Stack, 
  HStack, 
  Icon, 
  Spinner,useToast 
} from '@chakra-ui/react';
import axios from 'axios';
import { FaBuilding, FaInfoCircle, FaQuestionCircle, FaLink, FaChevronDown ,FaTools} from 'react-icons/fa'; // Added ChevronDown for dropdown icon
import ImageOne from '../../assets/images/Global_network_generated.jpg';
import UserNavbar from '../auth/UserNavbar';
import MainpageFooter from '../MainpageFooter';
import MainComponent from '../Maincomponent';
import { useNavigate } from 'react-router-dom';
import {getToken } from '../auth/loginPage';
import KeyServices from '../subcomponent/KeyServices';


const DkimTool = () => {
  const [domain, setDomain] = useState('');
  const [selector, setSelector] = useState('');
  const [useDkimScan, setUseDkimScan] = useState(false);
  const [dkimData, setDkimData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const [message, setMessage] = useState('');
  const toast = useToast();


    // Define responsive values for padding, font size, etc.
const paddingX = useBreakpointValue({ base: '4', md: '6', lg: '8' });
const buttonWidth = useBreakpointValue({ base: '80%', sm: '60%', md: '50%' });
const containerWidth = useBreakpointValue({ base: '90%', md: '70%', lg: '60%' });
const containerHeight = useBreakpointValue({ base: '70%', md: '60%', lg: '50%' });

// Load results from sessionStorage if available
useEffect(() => {
  const checkAuthentication = () => {
    const storedToken = getToken(); // Retrieve token

    setToken(storedToken);
    const accessToken = sessionStorage.getItem('accessToken'); // Access token should be stored in session storage

    if (!accessToken) {
      navigate('/signin'); // Redirect to sign-in page if no access token
      return;
    }
  };

  checkAuthentication();
}, [navigate]);



  const handleDomainChange = (e) => {
    setDomain(e.target.value);
  };

  const handleSelectorChange = (e) => {
    setSelector(e.target.value);
  };

  const handleCheckboxChange = (e) => {
    setUseDkimScan(e.target.checked);
  };

  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault(); // Prevent default only if an event is passed
    }
    setLoading(true);
    setError(null);
    setDkimData(null);

    try {
      let apiUrl = '';

      // Check which API to call
      if (useDkimScan) {
        apiUrl = `https://g1o62ob9e6.execute-api.ap-south-1.amazonaws.com/v1/api/domain_tools/dkim_scan?domain=${domain}`;
      } else {
        apiUrl = `https://g1o62ob9e6.execute-api.ap-south-1.amazonaws.com/v1/api/domain_tools/dkim_check?domain=${domain}&selector=${selector}`;
      }

      const response = await axios.get(apiUrl);
      setDkimData(response.data);
    } catch (error) {
      setError('Error fetching data. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
    <div
style={{
 position: 'fixed',                         // Fix the position of the navbar
 top: 0,                                    // Stick it to the top
 left: 0,                                   // Align it to the left edge
 width: '100%',                             // Full width for the navbar
 zIndex: 1000,                              // Ensure it's above other content
}}
>
<UserNavbar /> 
</div>
<div>
        <Box
        height={{ base: '60vh', md: '60vh', lg: '80vh' }}
        backgroundImage={`url(${ImageOne})`} 
        backgroundSize="cover"
        backgroundPosition="center"
        position="relative"
      >
        <Center height="100%">
          <Box
            width={containerWidth}
            height={containerHeight}
            backgroundColor="rgba(0, 0, 0, 0.6)"
            color="white"
            borderRadius="20px"
            paddingX={paddingX}
            paddingY={{ base: '4', md: '6', lg: '8' }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            <Stack
              direction={{ base: 'column', md: 'row' }}
              spacing={{ base: '4', md: '6' }}
              align="center"
              justify="center"
              mb={6}
              wrap="wrap" // Ensure that items wrap if needed
              gap={{ base: '4', md: '6' }} // Responsive gap
            >
    
              {/* Normal Button */}
              <Button
                bg="transparent"
                color="white"
                borderRadius="md"
                _hover={{ bg: 'rgba(0, 0, 0, 0.3)' }}
                onClick={() => navigate('/companyinfo')}
              >
                <HStack spacing={2}>
                <Icon as={FaBuilding} />
                  <Text>Domain Risk Analysis</Text>
                </HStack>
              </Button>
        
    
              {/* Normal Button */}
        <Button
                bg="transparent"
                color="white"
                borderRadius="md"
                _hover={{ bg: 'rgba(0, 0, 0, 0.3)' }}
                onClick={() => navigate('/companysearch')}
              >
                <HStack spacing={2}>
                <Icon as={FaInfoCircle} />
                  <Text>Deep and Dark Search</Text>
                </HStack>
              </Button>

              
              {/* Dropdown Button 3 */}
              <Menu>
                <MenuButton
                  as={Button}
                  bg="transparent"
                  color="white"
                  borderRadius="md"
                  _hover={{ bg: 'rgba(0, 0, 0, 0.3)' }}
                  rightIcon={<FaChevronDown />}
                >
                  <HStack spacing={2}>
                    <Icon as={FaTools} />
                    <Text>DKIM</Text>
                  </HStack>
                </MenuButton>
                <MenuList bg="black" color="white">
                
                  <MenuItem
                    bg="black"
                    _hover={{ bg: 'rgba(255, 255, 255, 0.2)' }}
                    onClick={() => navigate('/securityheaders')}
                  >
                    Security Headers
                  </MenuItem>
                  <MenuItem
                    bg="black"
                    _hover={{ bg: 'rgba(255, 255, 255, 0.2)' }}
                    onClick={() => navigate('/sitetechnology')}
                  >
                    Site Technology
                  </MenuItem>
                  <MenuItem
                    bg="black"
                    _hover={{ bg: 'rgba(255, 255, 255, 0.2)' }}
                    onClick={() => navigate('/url')}
                  >
                    URL Analysis
                  </MenuItem>
                </MenuList>
              </Menu>
    
              {/* Normal Button */}
              {/* <Button
                bg="transparent"
                color="white"
                borderRadius="md"
                _hover={{ bg: 'rgba(0, 0, 0, 0.3)' }}
                onClick={() => navigate('/url')}
              >
                <HStack spacing={2}>
                  <Icon as={FaLink} />
                  <Text>URL Analysis</Text>
                </HStack>
              </Button> */}
            </Stack>
    
            {/* Input and Search Button */}
            <VStack spacing={4} width={containerWidth} align="center">
              <Input
                placeholder="Company Name: 'example.com'"
                value={domain}
            onChange={handleDomainChange}
                size="md"
                color="white"
                backgroundColor="rgba(255, 255, 255, 0.2)"
                borderRadius="md"
                _placeholder={{ color: 'white' }}
                border="1px solid white"
              />
               {!useDkimScan && (<Input
                placeholder="selector"
                value={selector}
                onChange={handleSelectorChange}
                required
                size="md"
                color="white"
                backgroundColor="rgba(255, 255, 255, 0.2)"
                borderRadius="md"
                _placeholder={{ color: 'white' }}
                border="1px solid white"
              />)}
               <div>
          <label>
            <input
              type="checkbox"
              checked={useDkimScan}
              onChange={handleCheckboxChange}
            />
            Check all the selectors
          </label>
        </div>
      {/* <Button
       onClick={(e) => handleSubmit(e)} // Pass the event to handleSubmit
        colorScheme="teal"
        width={buttonWidth}
        borderRadius="20px"
        backgroundColor="rgba(0, 0, 0, 0.5)"
        _hover={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}
      >
        Search
      </Button> */}
          <MainComponent domain={domain} onSuccess={handleSubmit} />
                </VStack>
              </Box>
            </Center>
          </Box>
        <br></br>
        
    {/* <div>
      <h2>DKIM Tool</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Domain:</label>
          <input
            type="text"
            value={domain}
            onChange={handleDomainChange}
            required
          />
        </div>

        {!useDkimScan && (
          <div>
            <label>Selector:</label>
            <input
              type="text"
              value={selector}
              onChange={handleSelectorChange}
              required
            />
          </div>
        )}

        <div>
          <label>
            <input
              type="checkbox"
              checked={useDkimScan}
              onChange={handleCheckboxChange}
            />
            Check all the selectors
          </label>
        </div>

        <Button type="submit" isLoading={loading} colorScheme="teal">
          Submit
        </Button>
      </form> */}
        <center>
                    {loading && <Spinner size="xl" />}
            
                    </center>

      {error && <Text color="red.500">{error}</Text>}

      {dkimData ? (
        <Box mt={8} p={4} borderWidth="1px" borderRadius="lg" boxShadow="lg" bg="gray.50">
          <Text fontSize="2xl" fontWeight="bold" color="teal.600" textAlign="center" mb={6}>
            DKIM Data
          </Text>
          {(Array.isArray(dkimData) && dkimData.length > 0) || (!Array.isArray(dkimData) && Object.keys(dkimData).length > 0) ? (
            <Table variant="striped" colorScheme="teal" size="md" border="1px solid" borderColor="gray.300" borderRadius="md" overflowX="auto">
              <Thead bg="teal.500">
                <Tr>
                  <Th fontWeight="bold" color="white" borderRight="2px solid white" textAlign="center">
                    Selector
                  </Th>
                  <Th fontWeight="bold" color="white" textAlign="center">
                    Public Key
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {Array.isArray(dkimData) ? (
                  dkimData
                    .filter(
                      (item, index, self) =>
                        index === self.findIndex((t) => t.selector === item.selector && t.public_key === item.public_key)
                    )
                    .map((item, index) => (
                      <Tr key={index}>
                        <Td borderRight="2px solid" borderColor="gray.300" padding="12px" textAlign="center" color="blue.600" fontWeight="bold">
                          {item.selector}
                        </Td>
                        <Td padding="12px" style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }} textAlign="center">
                          {item.public_key}
                        </Td>
                      </Tr>
                    ))
                ) : (
                  Object.keys(dkimData)
                    .filter((key, index, self) => index === self.indexOf(key))
                    .map((key, index) => (
                      <Tr key={index}>
                        <Td borderRight="2px solid" borderColor="gray.300" padding="12px" textAlign="center" color="blue.600" fontWeight="bold">
                          {key}
                        </Td>
                        <Td padding="12px" style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }} textAlign="center">
                          {dkimData[key]}
                        </Td>
                      </Tr>
                    ))
                )}
              </Tbody>
            </Table>
          ) : (
            <Text fontSize="lg" color="red.500" textAlign="center" mt={4}>
              No Result Found
            </Text>
          )}
        </Box>
      ) : null}
  {/* Error handling for DKIM API */}
{error && (
  <Alert status="error" mt={4} borderRadius="lg">
    <AlertIcon />
    <AlertTitle mr={2}>Error:</AlertTitle>
    <AlertDescription>{error}</AlertDescription>
    <CloseButton position="absolute" right="8px" top="8px" />
  </Alert>
)}

        <br></br>
         <MainpageFooter/>
            </div> 

        </div>
  );
};

export default DkimTool;
