import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Heading,
  Text,
  Button,
  SimpleGrid,
  Stack,
  useColorModeValue,
  Flex,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useToast,
  Switch,
  FormLabel,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import MainpageFooter from './MainpageFooter';
import UserNavbar from './auth/UserNavbar';
import Navbar from './Navbar';
import { updateUserPlan } from '../authService'; // Import your auth service

const Pricing = ({ isAuthenticated }) => {
  const [currentPlan, setCurrentPlan] = useState('');
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [userEmail, setUserEmail] = useState('');
  const [isUSD, setIsUSD] = useState(false); // Set default to INR (isUSD is false)
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();

  useEffect(() => {
    const email = sessionStorage.getItem('userEmail');
    const userPlan = sessionStorage.getItem('userPlan') || '';
    window.scrollTo(0, 0);

    if (email) {
      setCurrentPlan(userPlan);
      setUserEmail(email);
    } else {
      setUserEmail('');
    }
  }, [isAuthenticated]);

  const pricingPlans = [
    {
      title: 'Free',
      price: isUSD ? '0 $/Forever' : '0 ₹/Forever',
      features: [
        'Sign in for access below for one domain',
        'Deep & Dark web Analysis',
        'Domain Info',
        'Report Download',
        'Check DKIM',
        'Detect Technologies',
        'Email Security',
        'Whois history & Contact',
      ],
      buttonText: 'Get Started',
      colorScheme: 'black',
      gradient: 'linear(to-br, teal.300, teal.500)',
      priceColor: 'teal.500',
      buttonHoverColor: 'teal.600',
    },
    {
      title: 'Silver',
      price: isUSD ? '60 $/Month' : '5100 ₹/Month',
      features: [
        '5 Domains',
        'Deep & Dark web Analysis',
        'Domain Info',
        'Report Download',
        'Manual Pentesting',
        'Detect Homoglyphs',
        'Check DKIM',
        'Phishing Simulation',
        'Detect Technologies',
        'Email Security',
        'Whois history & Contact',
      ],
      buttonText: 'Choose Plan',
      colorScheme: 'black',
      gradient: 'linear(to-br, gray.300, gray.500)',
      priceColor: 'gray.600',
      buttonHoverColor: 'gray.700',
    },
    {
      title: 'Gold',
      price: isUSD ? '100 $/Month' : '8500 ₹/Month',
      features: [
        '10 Domains + API',
        'Deep & Dark web Analysis',
        'Domain Info',
        'Report Download',
        'Automated Pentesting',
        'Detect Homoglyphs',
        'Check DKIM',
        'Phishing Simulation',
        'Detect Technologies',
        'Darkweb Monitoring',
        'Email Security',
        'Whois history & Contact',
      ],
      buttonText: 'Choose Plan',
      colorScheme: 'black',
      gradient: 'linear(to-br, yellow.300, yellow.500)',
      priceColor: 'yellow.600',
      buttonHoverColor: 'yellow.700',
    },
    {
      title: 'Platinum',
      price: isUSD ? 'Please Contact Sales' : 'Please Contact Sales',
      features: [
        'Custom Plan',
        'Deep & Dark web Analysis',
        'Domain Info',
        'Report Download',
        'Automated Pentesting',
        'Manual Pentesting',
        'Detect Homoglyphs',
        'Check DKIM',
        'Phishing Simulation',
        'Detect Technologies',
        'Darkweb Monitoring',
        'Email Security',
        'Whois history & Contact',
      ],
      buttonText: 'Contact Us',
      colorScheme: 'black',
      gradient: 'linear(to-br, purple.300, purple.500)',
      priceColor: 'purple.600',
      buttonHoverColor: 'purple.700',
    },
  ];

  const bgColor = useColorModeValue('gray.100', 'gray.700');
  const cardBgColor = 'white';
  const hoverBgColors = {
    Free: 'teal.100',
    Silver: 'gray.200',
    Gold: 'yellow.100',
    Platinum: 'purple.100',
  };

  const headingBgGradient = 'linear(to-r, black, gray.800)';
  const headingTextShadow = '2px 2px 4px rgba(0, 0, 0, 0.6)';

  const handlePlanSelection = (planTitle) => {
    const email = sessionStorage.getItem('userEmail');

    if (email) {
      setSelectedPlan(planTitle);
      onOpen(); // Open confirmation dialog if user is signed in
    } else {
      navigate('/signup', { state: { customPlan: planTitle } }); // Redirect to Sign-Up if not signed in
    }
  };

  const handleConfirmUpgrade = async () => {
    try {
      if (userEmail && selectedPlan) {
        await updateUserPlan(userEmail, selectedPlan);
        sessionStorage.setItem('userPlan', selectedPlan);
        window.location.reload(); // This ensures immediate logout
        toast({
          title: "Plan Updated.",
          description: `You have successfully upgraded to the ${selectedPlan} plan.`,
          status: "success",
          duration: 5000,
          isClosable: true,
          
        });
      }
    } catch (error) {
      toast({
        title: "Update Failed.",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    setSelectedPlan(null);
    onClose();
  };

  return (
    <div>
    <div>
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          zIndex: 1000,
        }}
      >
        {isAuthenticated ? <UserNavbar /> : <Navbar />}
      </div><br /><br />
      <Box bg={bgColor} py={8} px={4}>
        <Box
          textAlign="center"
          mb={8}
          bgGradient={headingBgGradient}
          p={4}
          borderRadius="md"
          boxShadow="md"
          position="relative"
        >
          <Heading
            as="h2"
            color="white"
            fontSize={{ base: '2xl', md: '3xl' }}
            fontFamily="Arial, sans-serif"
            textShadow={headingTextShadow}
            mb={4}
          >
            Pricing Plans
          </Heading>

          <Text
            color="white"
            fontSize="lg"
            fontWeight="medium"
            maxW="lg"
            mx="auto"
            fontFamily="Arial, sans-serif"
          >
            Choose the plan that best fits your needs and get started today!
          </Text>
        </Box>

        {/* Currency Toggle */}
        <Flex justify="flex-end" mb={8} pr={4}>
          <FormLabel htmlFor="currency-toggle" mr={3} color="gray.600" fontFamily="Arial, sans-serif">
            Switch to {isUSD ? 'INR' : 'USD'}:
          </FormLabel>
          <Switch
            id="currency-toggle"
            isChecked={isUSD}
            onChange={() => setIsUSD(!isUSD)}
          />
        </Flex>

        <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={6}>
          {pricingPlans.map((plan) => (
            <Box
              key={plan.title}
              borderWidth="1px"
              borderRadius="md"
              p={6}
              bg={cardBgColor}
              boxShadow="xl"
              _hover={{
                bg: hoverBgColors[plan.title],
                boxShadow: '2xl',
                transform: 'scale(1.02)',
                transition: 'all 0.3s ease',
              }}
              transition="all 0.3s ease"
              position="relative"
              display="flex"
              flexDirection="column"
            >
              <Flex direction="column" flex="1">
                <Heading as="h3" size="lg" mb={4} fontFamily="Arial, sans-serif">
                  {plan.title}
                </Heading>
                <Text
                  fontSize="2xl"
                  fontWeight="bold"
                  mb={4}
                  color={plan.priceColor}
                  fontFamily="Arial, sans-serif"
                >
                  {plan.price}
                </Text>
                <Stack spacing={3} mb={6}>
                  {plan.features.map((feature, index) => (
                    <Text key={index} fontSize="sm" color="gray.500" fontFamily="Arial, sans-serif">
                      {feature}
                    </Text>
                  ))}
                </Stack>
              </Flex>
              <Button
                mt="auto"
                colorScheme={plan.colorScheme}
                bgGradient={plan.gradient}
                color="white"
                onClick={() => handlePlanSelection(plan.title)}
                _hover={{ bg: plan.buttonHoverColor }}
              >
                {plan.buttonText}
              </Button>
            </Box>
          ))}
        </SimpleGrid>
        <Box mt={8} textAlign="center">
      <Flex justify="center">
        <Button
          bg="black"
          color="white"
          borderRadius="md"
          fontFamily="Arial, sans-serif"
          _hover={{ bg: "gray.700" }}
          _active={{ bg: "gray.900" }}
          _focus={{ boxShadow: "outline" }}
          onClick={() => navigate('/termsofServices')}
        >
          Terms of Service
        </Button>
        
        <Button
          bg="black"
          color="white"
          borderRadius="md"
          fontFamily="Arial, sans-serif"
          _hover={{ bg: "gray.700" }}
          _active={{ bg: "gray.900" }}
          _focus={{ boxShadow: "outline" }}
          ml={4}  // Add margin-left for spacing
          onClick={() => navigate('/privacyPolicy')}
        >
          Privacy Policy
        </Button>
      </Flex>
    </Box>
      </Box>
      <br></br>
        <MainpageFooter />
      </div>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirm Plan Upgrade
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to upgrade to the {selectedPlan} plan?
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="teal" onClick={handleConfirmUpgrade} ml={3}>
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </div>
  );
};

export default Pricing;
