import React from 'react';
import { Box, Text, SimpleGrid } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import UserNavbar from '../../auth/UserNavbar';
import MainpageFooter from '../../MainpageFooter';

const HomoglyphsDetails = () => {
  const location = useLocation();
  const { homoglyphs } = location.state;

  // Function to decode the characters if necessary
  const decodeCharacters = (input) => {
    try {
      return decodeURIComponent(escape(input));
    } catch (e) {
      return input; // Return the input unchanged if decoding fails
    }
  };

  return (
    <div>
    <UserNavbar/>
    
 <div>
    <Box p={6}>
      <Text fontWeight="bold" fontSize="2xl" color="blue.900" textAlign="center" mb={4}>
        Homoglyphs Data
      </Text>

      {homoglyphs && homoglyphs.length > 0 ? (
        homoglyphs.map((group, groupIndex) => (
          <Box key={groupIndex} mb={6}>
            <Text fontWeight="bold" fontSize="lg" mb={4}>
              Group {groupIndex + 1}
            </Text>
            <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 4 }} spacing={4}>
              {group.map((item, itemIndex) => (
                <Box
                  key={itemIndex}
                  p={4}
                  borderWidth="1px"
                  borderRadius="md"
                  boxShadow="md"
                  backgroundColor="white"
                  overflow="hidden"
                  textAlign="center"
                >
                  <Text>{decodeCharacters(item)}</Text>
                </Box>
              ))}
            </SimpleGrid>
          </Box>
        ))
      ) : (
        <Text fontSize="lg" textAlign="center">No Homoglyphs Data Available</Text>
      )}
    </Box>
    <br></br>
         <MainpageFooter/>
            </div> 

        </div>
  );
};

export default HomoglyphsDetails;
